import {
  AuthContextData,
  AuthHookData,
} from "@/providers/AuthProvider/index.d";
import { ReactNode, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { useSession } from "@/providers/AuthProvider/SessionHOC";
import { useRouter } from "next/router";
import { ERROR_ROUTE } from "@/constants/app-routes";
import { goTo } from "@/utils/router.utils";
import { TokenService } from "@/services/TokenService";
import { AuthService } from "./AuthService";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { session, setSession, error, loading, logout } = useSession();
  const router = useRouter();
  const errorPage = [ERROR_ROUTE].includes(router.asPath);

  const updateSession = async (token: string | null) => {
    if (!token) return setSession(null);

    const userData = AuthService.decode(token);
    const hash = router?.query?.hash as string;
    await TokenService.save({ hash, token });
    setSession(userData);
  };

  if (!loading && error && !errorPage) {
    goTo(
      `${ERROR_ROUTE}?type=client-error&message=${JSON.stringify(error)})}`,
      router
    );
  }

  return (
    <AuthContext.Provider
      value={{ session, setSession: updateSession, error, loading, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthHookData => {
  const { session, setSession, error, loading, logout } =
    useContext<AuthContextData>(AuthContext);
  return { session, setSession, error, loading, logout };
};
