import { COURSES_LIST_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";

interface GetCoursesProps {
  search?: string;
  published?: boolean;
}

export type Course = {
  id: number;
  course_lms_id: number;
  name: string;
  total_activated_quizzes: number;
  active: boolean;
  account: {
    id: number;
    name: string;
    active: boolean;
  };
  published?: boolean;
  actions?: string;
};

type GetCoursesResponse = {
  courses: Course[];
  total: number;
};

interface UseCoursesProps {
  search?: string;
  published?: boolean;
  enabled?: boolean;
  onSuccess?: (data: GetCoursesResponse) => void;
  onError?: () => void;
}

export function useCourses({
  search,
  published,
  enabled = true,
  onSuccess,
  onError,
}: UseCoursesProps) {
  const { api } = useApiClient();

  const getCourses = async ({
    search,
    published,
  }: GetCoursesProps): Promise<GetCoursesResponse> => {
    const response = await api.get(COURSES_LIST_URL, {
      params: {
        search,
        published,
      },
    });

    return {
      courses: response.data.content.courses || [],
      total: response.data.content.courses.length || 0,
    };
  };

  return useQuery({
    queryKey: ["courses", { search, published }],
    queryFn: () => getCourses({ search, published }),
    enabled,
    onSuccess,
    onError,
  });
}
