import { TIntervalOption } from "@/components/widgets/common/IntervalOptions/IntervalOptions.utils";
import { INCIDENT_LEVEL_ANALYTICS_WIDGET_MOCKED_DATA } from "@/components/widgets/IncidentLevelAnalyticsWidget/IncidentLevelAnalyticsWidget.utils";
import { DASHBOARD_INCIDENT_LEVEL_ANALYTICS_WIDGET_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";

interface UseIncidentLevelAnalyticsWidgetProps {
  intervalOption: TIntervalOption;
  isMock?: boolean;
}

export type TIncidentLevelAnalytics = {
  category: string;
  total: string;
  percentage: string;
};

type GetIncidentLevelAnalyticsWidgetResponse = {
  incidents: TIncidentLevelAnalytics[];
};

export function useIncidentLevelAnalyticsWidget({
  intervalOption,
  isMock,
}: UseIncidentLevelAnalyticsWidgetProps) {
  const { api } = useApiClient();

  const getIncidentLevelAnalyticsWidget =
    async (): Promise<GetIncidentLevelAnalyticsWidgetResponse> => {
      const response = await api.get(
        DASHBOARD_INCIDENT_LEVEL_ANALYTICS_WIDGET_URL,
        {
          params: {
            days: intervalOption,
          },
        }
      );

      return {
        incidents: response.data.content,
      };
    };

  const getIncidentLevelAnalyticsWidgetMockData = async () => {
    return INCIDENT_LEVEL_ANALYTICS_WIDGET_MOCKED_DATA;
  };

  return useQuery({
    queryKey: ["incident_level_analytics_widget", [intervalOption, isMock]],
    queryFn: isMock
      ? getIncidentLevelAnalyticsWidgetMockData
      : getIncidentLevelAnalyticsWidget,
    staleTime: 1000 * 60 * 1,
  });
}
