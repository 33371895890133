import { Quiz } from "@/hooks";

export const LMS_QUIZ_TYPE = "lms_quiz";
export const THIRD_PARTY_QUIZ_TYPE = "third_party";

export const formatQuizzesToSelect = (quizzes: Quiz[] = []) => {
  const formattedQuizzes = [
    {
      id: -1,
      name: "All Exams",
      label: "All Exams",
      value: -1,
    },
    ...quizzes?.map((quiz) => ({
      id: quiz.id,
      name: quiz.name,
      label: quiz.name,
      value: quiz.id,
    })),
  ];

  return formattedQuizzes;
};
