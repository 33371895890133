/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import { useState, useEffect, useMemo } from "react";
import { useTheme, InputBase, IconButton } from "@mui/material";
import { Icon } from "../../Icon";
import { Empty } from "../../Empty";
import * as S from "./Menu.styles";
import useDebounce from "@/hooks/useDebounce";
import { OptionsPlaceholder } from "./Options.placeholder";
import { Typography } from "@edusynch/edusynch-ui";
import { useTranslation } from "next-i18next";

interface IMenuProps {
  options: any[];
  currentFilters?: any[];
  onFilterChange: (filter: any) => void;
  onSelect?: () => void;
  onSearchMouseEnter?: () => void;
  onSearchMouseLeave?: () => void;
  onHeaderMouseEnter?: () => void;
  onHeaderMouseLeave?: () => void;
  isLoading?: boolean;
  minSearchLength?: number;
  onSearchChange?: (term: string) => void;
}

export function Menu({
  options,
  currentFilters = [],
  onFilterChange,
  onSelect,
  onSearchMouseEnter,
  onSearchMouseLeave,
  onHeaderMouseEnter,
  onHeaderMouseLeave,
  isLoading,
  minSearchLength = 3,
  onSearchChange,
}: IMenuProps) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(
    null
  );
  const [tempSearch, setTempSearch] = useState("");
  const [search, setSearch] = useState("");
  const theme = useTheme();
  const { t } = useTranslation("common");

  useEffect(() => {
    return () => {
      setSelectedOptionIndex(null);
    };
  }, []);

  useDebounce(
    () => {
      if (
        !onSearchChange ||
        search === tempSearch ||
        search.length < minSearchLength
      )
        return;

      setTempSearch(search);
      onSearchChange(search);
    },
    [search],
    500
  );

  const computedOptions =
    selectedOptionIndex !== null
      ? options?.[selectedOptionIndex]?.items
          ?.filter((item: any) => {
            const currentTypeFilter = currentFilters?.find(
              (filter) => filter?.type === options?.[selectedOptionIndex]?.type
            );
            return item?.id !== currentTypeFilter?.value;
          })
          ?.filter((item: any) => {
            const label = item.name || item.label || ""; // name/label is coming null
            return label.toLowerCase().includes(search.toLowerCase());
          })
      : options;

  const groupedTitles = useMemo(() => {
    const allTitles = computedOptions?.map((item: any) => item?.groupedBy);
    return allTitles.filter(
      (item: any, index: number) => item && allTitles.indexOf(item) === index
    );
  }, [computedOptions]);

  // eslint-disable-next-line consistent-return
  const handleSelect = (option: any, index: number) => {
    if (onSelect) onSelect();
    if (!option.filterName) return setSelectedOptionIndex(index);

    onFilterChange(option);
    setSelectedOptionIndex(null);
  };

  const currentFilterTitle =
    selectedOptionIndex !== null ? options?.[selectedOptionIndex]?.type : "";

  return (
    <S.Container>
      {selectedOptionIndex !== null && (
        <>
          <S.OptionHeader
            onMouseEnter={() => {
              if (onHeaderMouseEnter) onHeaderMouseEnter();
            }}
            onMouseLeave={() => {
              if (onHeaderMouseLeave) onHeaderMouseLeave();
            }}
          >
            <IconButton onClick={() => setSelectedOptionIndex(null)}>
              <Icon name="chevron-left" width={16} height={16} />
            </IconButton>
            <h6>{currentFilterTitle}</h6>
          </S.OptionHeader>
          <S.SearchContainer>
            <InputBase
              sx={{
                height: "2.5rem",
                padding: 2,
                fontSize: 14,
                color: theme.customColors.common.text,
              }}
              fullWidth
              placeholder="Search"
              inputProps={{ "aria-label": "search filters" }}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
              onMouseEnter={() => {
                if (onSearchMouseEnter) onSearchMouseEnter();
              }}
              onMouseLeave={() => {
                if (onSearchMouseLeave) onSearchMouseLeave();
              }}
            />
          </S.SearchContainer>
        </>
      )}
      {isLoading ? (
        <OptionsPlaceholder />
      ) : (
        <S.Options>
          {computedOptions.length ? (
            groupedTitles?.length ? (
              groupedTitles?.map((groupTitle: string) => (
                <S.GroupedOptions key={groupTitle}>
                  <S.GroupedOptionsTitle>{groupTitle}</S.GroupedOptionsTitle>
                  {computedOptions?.map((option: any, optionIndex: number) => {
                    if (option?.groupedBy !== groupTitle) return null;
                    return (
                      <S.GroupedOptionsItem
                        key={option.id}
                        onClick={() => handleSelect(option, optionIndex)}
                      >
                        {!!option.icon && <Icon name={option.icon} />}
                        {option.render ? (
                          <>{option.render}</>
                        ) : (
                          option.name || option.label || option.type
                        )}
                      </S.GroupedOptionsItem>
                    );
                  })}
                </S.GroupedOptions>
              ))
            ) : (
              computedOptions?.map((option: any, index: number) => (
                <S.Option
                  key={option.id}
                  onClick={() => handleSelect(option, index)}
                >
                  {!!option.icon && <Icon name={option.icon} />}
                  {option.render ? (
                    <>{option.render}</>
                  ) : (
                    option.name || option.label || option.type
                  )}
                </S.Option>
              ))
            )
          ) : (
            <Empty
              message={
                onSearchChange && !tempSearch ? (
                  <Typography color="text">
                    {t("empty.enter_at_least")} {minSearchLength}{" "}
                    {t("empty.characters")}
                  </Typography>
                ) : undefined
              }
            />
          )}
        </S.Options>
      )}
    </S.Container>
  );
}
