import { NextRouter } from "next/router";

export const sortASC = "ASC";
export const sortDESC = "DESC";

type TSortParams = {
  sort: string;
  sortBy: string;
  value: string;
  router?: NextRouter;
};

export const getSortStatus = ({ sort, sortBy, value }: TSortParams) =>
  sortBy === value && sort === sortDESC ? sortDESC : sortASC;

export const onSort = ({ sort, sortBy, value, router }: TSortParams) => {
  router?.replace({
    query: {
      ...router?.query,
      sort: sortBy === value && sort === sortDESC ? sortASC : sortDESC,
      sort_by: value,
    },
  });
};
