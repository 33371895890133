import { css, styled } from "@mui/material";
import { StyledComponent } from "@emotion/styled";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { MOBILE_SIZE } from "@/hooks";

interface ILinkProps extends NextLinkProps {
  menuLink?: boolean;
}

export const Link: StyledComponent<ILinkProps, any> = styled(NextLink, {
  shouldForwardProp: (prop) => prop !== "menuLink",
})<ILinkProps>`
  color: ${(props) => props.theme.customColors.gray[500]};
  text-decoration: none;
  padding: 2rem 0 calc(2rem - 4px);

  &:hover {
    color: ${(props) => props.theme.customColors.primary[500]};
    border-bottom: 4px solid ${(props) => props.theme.customColors.primary[500]};
  }

  &.active {
    color: ${(props) => props.theme.customColors.primary[500]};
    border-bottom: 4px solid ${(props) => props.theme.customColors.primary[500]};
  }

  ${({ menuLink, theme }) =>
    menuLink &&
    css`
      @media ${MOBILE_SIZE} {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 3rem;
        padding: 0 1rem;

        &:hover,
        &:focus,
        &:active {
          border-bottom: none;
        }

        &.active {
          border-bottom: none;
          background-color: ${theme.customColors.primary[200]};
          border-radius: 0.5rem;

          svg {
            path {
              fill: ${theme.customColors.primary[500]};
            }
          }
        }
      }
    `};
`;
