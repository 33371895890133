import { SETTINGS_IMPORT_STUDENTS_URL } from "@/constants/api-routes";
import { useMutation } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";

interface IImportStudentsProps {
  file: any;
  onUploadProgress: (data: any) => void;
}

export function useImportStudents() {
  const { api } = useApiClient();

  const importStudents = useMutation({
    mutationFn: async ({ file, onUploadProgress }: IImportStudentsProps) => {
      const data = new FormData();
      data.set("file", file);

      const response = await api.post(`${SETTINGS_IMPORT_STUDENTS_URL}`, data, {
        onUploadProgress,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response;
    },
  });

  return {
    importStudents,
  };
}
