import { DASHBOARD_SESSIONS_COUNT_WIDGET_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";
import { TIntervalOption } from "@/components/widgets/common/IntervalOptions/IntervalOptions.utils";

interface UseSessionsCountWidgetProps {
  intervalOption: TIntervalOption;
  isMock?: boolean;
  studentId?: number;
}

type GetSessionsCountWidgetResponse = {
  sessions: {
    session_count: number;
    time_spent: number;
    start: string;
    end: string;
  };
  compare: {
    session_count: number;
    time_spent: number;
    start: string;
    end: string;
  };
  days: {
    session_count: number;
    date: string;
    time_spent: number;
  }[];
  compare_days: {
    session_count: number;
    date: string;
    time_spent: number;
  }[];
};

export const MOCKED_DATA = {
  sessions: {
    session_count: 16029,
    time_spent: 58,
    start: "2024-03-08",
    end: "2024-03-14",
  },
  compare: {
    session_count: 0,
    time_spent: 0,
    start: "2024-03-01",
    end: "2024-03-07",
  },
  days: [
    {
      session_count: 81,
      date: "2022-11-11T20:33:06.572+00:00",
      time_spent: 50,
    },
    {
      session_count: 50,
      date: "2022-11-12T20:33:06.554+00:00",
      time_spent: 33,
    },
    {
      session_count: 75,
      date: "2022-11-13T20:33:06.545+00:00",
      time_spent: 95,
    },
    {
      session_count: 50,
      date: "2022-11-14T20:33:06.539+00:00",
      time_spent: 90,
    },
    {
      session_count: 30,
      date: "2022-11-15T20:33:06.530+00:00",
      time_spent: 45,
    },
    {
      session_count: 45,
      date: "2022-11-16T20:33:06.511+00:00",
      time_spent: 120,
    },
    {
      session_count: 25,
      date: "2022-11-17T20:33:06.496+00:00",
      time_spent: 60,
    },
  ],
  compare_days: [
    {
      session_count: 18,
      date: "2022-11-11T20:33:06.572+00:00",
      time_spent: 50,
    },
    {
      session_count: 5,
      date: "2022-11-12T20:33:06.554+00:00",
      time_spent: 33,
    },
    {
      session_count: 57,
      date: "2022-11-13T20:33:06.545+00:00",
      time_spent: 95,
    },
    {
      session_count: 15,
      date: "2022-11-14T20:33:06.539+00:00",
      time_spent: 90,
    },
    {
      session_count: 3,
      date: "2022-11-15T20:33:06.530+00:00",
      time_spent: 45,
    },
    {
      session_count: 54,
      date: "2022-11-16T20:33:06.511+00:00",
      time_spent: 120,
    },
    {
      session_count: 52,
      date: "2022-11-17T20:33:06.496+00:00",
      time_spent: 60,
    },
  ],
};

export function useSessionsCountWidget({
  isMock,
  intervalOption,
  studentId,
}: UseSessionsCountWidgetProps) {
  const { api } = useApiClient();

  const getSessionsCountWidget =
    async (): Promise<GetSessionsCountWidgetResponse> => {
      const response = await api.get(DASHBOARD_SESSIONS_COUNT_WIDGET_URL, {
        params: {
          days: intervalOption,
          student_id: studentId,
        },
      });

      return response.data.content;
    };

  const getSessionsCountWidgetMockData = async () => {
    return MOCKED_DATA;
  };

  return useQuery({
    queryKey: ["sessions_count_widget", isMock, intervalOption, studentId],
    queryFn: isMock ? getSessionsCountWidgetMockData : getSessionsCountWidget,
    staleTime: 1000 * 60 * 1,
  });
}
