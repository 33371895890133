interface IObject {
  [key: string]: string;
}

const isEmpty = (obj: IObject) => !Object.keys(obj).length;

const removeFalsyProps = (obj: IObject) => {
  const newObj = Object.entries(obj).reduce((result, [key, value]) => {
    if (!value) {
      return result;
    }
    return {
      ...result,
      [key]: value,
    };
  }, {});

  return isEmpty(newObj) ? null : newObj;
};

const getObjectKeyByPartName = (obj: IObject, partName: string) =>
  Object.keys(obj).find((key) => key.startsWith(partName));

const isFalsy = (obj: any = {}) => {
  return Object.keys(obj)?.every((key) => !obj[key]);
};

const isObject = (obj: IObject) => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

const formDataFromObj = (obj: object) => {
  const formData = new FormData();
  const entries = Object.entries(obj);

  for (const [key, value] of entries) {
    if (
      value instanceof Array ||
      (value instanceof Object && !(value instanceof File))
    ) {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  }

  return formData;
};

export {
  removeFalsyProps,
  isEmpty,
  getObjectKeyByPartName,
  isFalsy,
  isObject,
  formDataFromObj,
};
