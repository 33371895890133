import { styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

export const Container: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  max-width: 1200px;
  margin: 2.5rem auto;
`;

export const Header: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "header"
)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 3.5rem;

  @media (max-width: 767.98px) {
    gap: 1rem;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const Links: ComponentType<HTMLAttributes<HTMLUListElement>> = styled(
  "ul"
)`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;

  @media (max-width: 767.98px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    li {
      width: 100%;
      & + li {
        border-top: 1px solid
          ${(props) => props.theme.customColors.primary[200]};
        padding-top: 1rem;
      }
    }
  }
`;

export const ShowMenuButton: ComponentType<
  HTMLAttributes<HTMLButtonElement>
> = styled("button")`
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  background-color: ${(props) => props.theme.customColors.primary[200]};
  cursor: pointer;
`;
