import { TIntervalOption } from "@/components/widgets/common/IntervalOptions/IntervalOptions.utils";
import { DAILY_SESSIONS_WIDGET_MOCKED_DATA } from "@/components/widgets/DailySessionsWidget/DailySessionsWidget.utils";
import { DASHBOARD_DAILY_SESSIONS_WIDGET_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";

interface UseDailySessionsWidgetProps {
  intervalOption: TIntervalOption;
  isMock?: boolean;
}

type TAnalysis = {
  date: string;
  total_by_day: number;
};

type GetDailySessionsWidgetResponse = {
  total: number;
  analysis: TAnalysis[];
};

export function useDailySessionsWidget({
  intervalOption,
  isMock,
}: UseDailySessionsWidgetProps) {
  const { api } = useApiClient();

  const getDailySessionsWidget =
    async (): Promise<GetDailySessionsWidgetResponse> => {
      const response = await api.get(DASHBOARD_DAILY_SESSIONS_WIDGET_URL, {
        params: {
          days: intervalOption,
        },
      });

      return {
        total: response.data.content.total,
        analysis: response.data.content.analysis,
      };
    };

  const getDailySessionsWidgetMockData = () => {
    return DAILY_SESSIONS_WIDGET_MOCKED_DATA;
  };

  return useQuery({
    queryKey: ["daily_sessions_widget", [intervalOption, isMock]],
    queryFn: isMock ? getDailySessionsWidgetMockData : getDailySessionsWidget,
    staleTime: 1000 * 60 * 1,
  });
}
