import { toast } from "react-toastify";
import { TemplateAdapter, ToastVariationsEnum } from "./ToastTemplates";

interface IToastAdapterProps {
  message: string;
  submessage?: string;
}
export const ToastAdapter = {
  error: (props: IToastAdapterProps) =>
    toast.error(
      <TemplateAdapter
        message={props.message}
        submessage={props?.submessage}
        variation={ToastVariationsEnum.error}
      />
    ),
  warning: (props: IToastAdapterProps) =>
    toast.warning(
      <TemplateAdapter
        message={props.message}
        submessage={props?.submessage}
        variation={ToastVariationsEnum.warning}
      />
    ),
  success: (props: IToastAdapterProps) =>
    toast.success(
      <TemplateAdapter
        message={props.message}
        submessage={props?.submessage}
        variation={ToastVariationsEnum.success}
      />
    ),
  info: (props: IToastAdapterProps) =>
    toast.info(
      <TemplateAdapter
        message={props.message}
        submessage={props?.submessage}
        variation={ToastVariationsEnum.info}
      />
    ),
  onChange: toast.onChange,
};
