import { Icon } from "@/components/common";
import { Typography } from "@edusynch/edusynch-ui";
import { IconButton, Menu, MenuItem, Stack, useTheme } from "@mui/material";
import React, { useState } from "react";

interface IExtendedRowModalOptionsProps<T> {
  item: T | null;
  options: TExtendedRowModalOptions<T>[];
}

export interface TExtendedRowModalOptions<T> {
  iconName: string;
  title: string;
  handler: (item: T | null) => void;
}

export function ExtendedRowModalOptions<T>({
  item,
  options,
}: IExtendedRowModalOptionsProps<T>) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        aria-label="modal-menu-options"
        id="modal-menu-options"
        aria-controls={open ? "options-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icon name="more" variant="gray" size="small" />
      </IconButton>
      <Menu
        id="options-menu"
        MenuListProps={{
          "aria-labelledby": "modal-menu-options",
        }}
        anchorEl={anchorEl}
        open={open}
        disableScrollLock
        onClose={() => setAnchorEl(null)}
        sx={{
          ".MuiMenu-list": {
            padding: 0,
            fontSize: "1.6rem",
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.title}
            onClick={() => {
              option.handler(item);
              setAnchorEl(null);
            }}
            divider
            sx={{
              borderBottomColor: theme.customColors.gray[100],
            }}
          >
            <Stack direction="row" spacing={1}>
              <Icon name={option.iconName} width={16} height={16} />
              <Typography color="gray">{option.title}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
