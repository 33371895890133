import { SxProps, Theme } from "@mui/material";

export const componentStyles: Record<string, SxProps> = {
  container: {
    backgroundColor: (theme: Partial<Theme>) =>
      theme?.customColors?.orange[100],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: {
      xs: "8px 16px",
      sm: "8px 24px",
    },
  },
  content: {
    width: "100%",
    maxWidth: "1216px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  message: {
    display: "flex",
    alignItems: "center",
    paddingLeft: {
      md: "32px",
    },
    svg: {
      minWidth: "24px",
      marginRight: "8px",
    },
    span: {
      fontSize: "12px",
      lineHeight: "14px",
      color: (theme: Partial<Theme>) => theme?.customColors?.gray[600],
    },
  },
  close: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: {
      md: "32px",
    },
    svg: {
      color: (theme: Partial<Theme>) => theme?.customColors?.gray[500],
      fontSize: "12px",
    },
  },
};
