import { Card } from "@edusynch/edusynch-ui";
import { Grid, useTheme } from "@mui/material";
import React, { ReactNode } from "react";
import { HoverIconButton } from "../../HoverIconButton";
import { Icon } from "../../Icon";

interface IListItemProps {
  children: ReactNode;
  onItemClick?: () => void;
}

export function ListItem({ children, onItemClick }: IListItemProps) {
  const theme = useTheme();

  return (
    <Card
      padding="1rem"
      style={{
        border: `1px solid ${theme.customColors.gray[300]}`,
      }}
    >
      <Grid container>
        <Grid item xs={11}>
          {children}
        </Grid>
        <Grid item xs={1} alignSelf="center">
          <HoverIconButton
            onClick={() => {
              if (onItemClick) onItemClick();
            }}
          >
            <Icon name="arrow-right" size="small" />
          </HoverIconButton>
        </Grid>
      </Grid>
    </Card>
  );
}
