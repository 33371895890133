import Image from "next/image";
import { useTranslation } from "next-i18next";
import { Typography } from "@edusynch/edusynch-ui";
import { Box } from "@mui/material";

export function ErrorList() {
  const { t } = useTranslation("common");

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="1rem"
    >
      <Image
        src="/assets/svg/500.svg"
        width={200}
        height={200}
        alt="Server error icon"
      />
      <Typography variant="h4">{t("errors.server-error")} 500</Typography>
    </Box>
  );
}
