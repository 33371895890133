import { TVariant, Typography } from "@edusynch/edusynch-ui";
import { IconButton, Stack, tooltipClasses, useTheme } from "@mui/material";
import { Icon } from "../Icon";
import useCopyToClipboard from "@/hooks/useCopyToClipboard";
import { Tooltip } from "../Tooltip";
import { useTranslation } from "next-i18next";

interface CopyTextProps {
  text: string;
  textVariant?: TVariant;
  fontWeight?: "normal" | "bold";
  tooltipTitle?: string;
}

export function CopyText({
  text,
  textVariant = "text",
  fontWeight = "normal",
  tooltipTitle,
}: CopyTextProps) {
  const { copyToClipboard } = useCopyToClipboard();
  const { t } = useTranslation("common");
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center">
      <Typography color={textVariant} fontWeight={fontWeight}>
        {text || "-"}
      </Typography>
      <Tooltip
        arrow
        title={`${t("common_words.copy")} ${tooltipTitle || ""}`}
        sx={{
          [`& .${tooltipClasses.arrow}`]: {
            color: theme.customColors.primary[500],
          },
          [`& .${tooltipClasses.tooltip}`]: {
            padding: "1rem",
            fontSize: theme.typography.pxToRem(14),
          },
        }}
      >
        <IconButton size="small" onClick={() => copyToClipboard(text)}>
          <Icon variant="primary" name="copy" size="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
