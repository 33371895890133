type Ellipsis = {
  side?: "start" | "end";
  type: string;
};

interface TextEllipsis {
  text: string;
  maxLength: number;
  ellipsis?: Ellipsis;
}

export function textEllipsis({
  text,
  maxLength,
  ellipsis = { side: "end", type: "..." },
}: TextEllipsis) {
  if (text.length > maxLength) {
    switch (ellipsis.side) {
      case "start":
        return (
          ellipsis.type + text.slice(-(maxLength - ellipsis?.type?.length))
        );
      case "end":
      default:
        return text.slice(0, maxLength - ellipsis.type.length) + ellipsis.type;
    }
  }
  return text;
}
