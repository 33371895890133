export const formatChartLabels = (data: any) => {
  return data?.incidents?.map((item: any) => item.category) || [];
};

export const formatChartValues = (data: any) => {
  return data?.incidents?.map((item: any) => item.total) || [];
};

export const formatChartPercentages = (data: any) => {
  return (
    data?.incidents?.map((item: any) =>
      Number(item.percentage?.replace("%", "") || "")
    ) || []
  );
};

export const formatChartData = (
  formattedChartLabels: string[],
  formattedChartData: number[],
  backgroundColor: string[],
  label: string
) => {
  return {
    labels: formattedChartLabels,
    datasets: [
      {
        label,
        data: formattedChartData,
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        borderWidth: 2,
      },
    ],
  };
};

interface IChartOptions {
  [key: string]: string | boolean | object;
}

export const formatChartOptions = () => {
  const chartOptions: IChartOptions = {
    maintainAspectRatio: true,
    layout: {
      padding: 10,
    },
    plugins: {
      legend: {
        display: false,
        position: "right" as const,
      },
      datalabels: {
        anchor: "start",
        backgroundColor: function (context: any) {
          const index = context.dataIndex;
          const hasValue = context.dataset.data[index];
          return hasValue ? context.dataset.backgroundColor : "transparent";
        },
        borderColor: "white",
        borderRadius: 25,
        borderWidth: function (context: any) {
          const index = context.dataIndex;
          const hasValue = context.dataset.data[index];
          return hasValue ? 1 : 0;
        },
        color: "white",
        font: {
          size: 10,
        },
        formatter: function (value: any) {
          return !!value ? `${value}%` : "";
        },
      },
    },
  };

  return chartOptions;
};

export const INCIDENT_LEVEL_ANALYTICS_WIDGET_MOCKED_DATA = {
  incidents: [
    {
      category: "Invalid",
      total: "19",
      percentage: "15%",
    },
    {
      category: "Low",
      total: "35",
      percentage: "35%",
    },
    {
      category: "Medium",
      total: "48",
      percentage: "30%",
    },
    {
      category: "High",
      total: "25",
      percentage: "20%",
    },
  ],
};
