import { QUIZZES_LIST_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";
import { toast } from "@/components/common/Toast";
import { Course } from "./useCourses";

interface GetQuizzesProps {
  page?: number;
  perPage?: number;
  search?: string;
  courseId?: number | null;
  sortBy?: string;
  sort?: string;
}

type GetQuizzesResponse = {
  courses: Course[];
  total: number;
};

interface UseQuizzesGroupedByCoursesProps {
  page?: number;
  perPage?: number;
  search?: string;
  courseId?: number | null;
  sortBy?: string;
  sort?: string;
  enabled?: boolean;
  onSuccess?: (data: GetQuizzesResponse) => void;
}

export function useQuizzesGroupedByCourses({
  page,
  perPage,
  search,
  courseId,
  sortBy,
  sort,
  enabled = true,
  onSuccess,
}: UseQuizzesGroupedByCoursesProps) {
  const { api } = useApiClient();

  const getQuizzes = async ({
    page,
    perPage,
    search,
    courseId,
    sortBy,
    sort,
  }: GetQuizzesProps): Promise<GetQuizzesResponse> => {
    const response = await api.get(QUIZZES_LIST_URL, {
      params: {
        course_lms_id: courseId || undefined,
        course_id: courseId || undefined,
        search: search || undefined,
        page: page || undefined,
        paginates_per: perPage || undefined,
        sort: sort || undefined,
        sort_by: sortBy || undefined,
        group_by_courses: true,
      },
    });

    return {
      courses: response.data.content.courses,
      total: response.data.content.total_items,
    };
  };

  return useQuery({
    queryKey: [
      "quizzes_grouped_by_courses",
      {
        page,
        perPage,
        search,
        courseId,
        sortBy,
        sort,
      },
    ],
    queryFn: () =>
      getQuizzes({
        page,
        perPage,
        search,
        courseId,
        sortBy,
        sort,
      }),
    staleTime: 1000 * 60 * 1,
    enabled,
    onError: (error: any) => {
      toast.error({ message: error?.message });
    },
    onSuccess,
  });
}
