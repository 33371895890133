// import { SUBMISSION_BY_ID_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { toast } from "@/components/common/Toast";
import { TEvaluatorSubmission, TStudentSubmission } from "./useSubmissions";
// import { useApiClient } from "../useApiClient";
import { TTracking } from "./useSubmissionAnswers";
import { useApiClient } from "../useApiClient";
import { SUBMISSION_DETECTOR_BY_ID_URL } from "@/constants/api-routes";
import { TSubmissionStatus } from "@/components/Submissions/utils";

interface GetSubmissionAnswerProps {
  submissionAnswerId: string;
}

export type TAiDetector = {
  sentence: string;
  perplexity: number;
  generated_prob: number;
};

export type SubmissionStudentAnswer = {
  id: number;
  student: TStudentSubmission;
  evaluator: TEvaluatorSubmission;
  tracking: TTracking;
  question_id: number;
  question_title: string;
  question_content: string;
  ai_score: number;
  answer: string;
  answer_score: string;
  answer_comments: string;
  average_generated_prob: number;
  average_perplexity_score: number;
  overall_burstiness: number;
  status: TSubmissionStatus;
  response_date: string;
  ai_detector: TAiDetector[];
};

interface UseSubmissionAnswerProps {
  submissionAnswerId: string;
  enabled?: boolean;
}

export function useSubmissionAnswer({
  submissionAnswerId,
  enabled = true,
}: UseSubmissionAnswerProps) {
  const { api } = useApiClient();

  const getSubmissionAnswer = async ({
    submissionAnswerId,
  }: GetSubmissionAnswerProps): Promise<SubmissionStudentAnswer> => {
    const response = await api.get(SUBMISSION_DETECTOR_BY_ID_URL, {
      params: {
        submission_detector_id: submissionAnswerId,
      },
    });

    return response.data.content;
  };

  return useQuery({
    queryKey: [
      "submissionAnswer",
      {
        submissionAnswerId,
      },
    ],
    queryFn: () =>
      getSubmissionAnswer({
        submissionAnswerId,
      }),
    staleTime: 1000 * 60 * 1,
    enabled,
    onError: (error: any) => {
      toast.error({ message: error?.message });
    },
  });
}
