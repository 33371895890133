import { useMemo } from "react";
import * as S from "./Status.styles";
import { useTranslation } from "next-i18next";

export enum StatusValue {
  Pending = 0,
  InProgress = 1,
  Completed = 2,
}

interface IStatus {
  status?: StatusValue;
  statusMap?: {
    [key: string]: {
      label: string;
      type: string;
    };
  };
  secondary?: boolean;
}

const getStatuses = (t: any) => ({
  [StatusValue.Pending]: {
    label: t("components.status.pending"),
    type: "pending",
  },
  [StatusValue.InProgress]: {
    label: t("components.status.in_progress"),
    type: "in_progress",
  },
  [StatusValue.Completed]: {
    label: t("components.status.completed"),
    type: "completed",
  },
});

export function Status({ status, statusMap }: IStatus) {
  const { t } = useTranslation("common");
  const statuses = useMemo(() => getStatuses(t), [t]);

  const { type, label } = useMemo(() => {
    return (
      statusMap?.[status as keyof typeof statusMap] ??
      statuses[status as keyof typeof statuses] ??
      statuses[0]
    );
  }, [status, statusMap, statuses]);

  return <S.StatusLabel status={type}>{label}</S.StatusLabel>;
}
