import Image from "next/image";
import { useTranslation } from "next-i18next";
import * as S from "./Empty.styles";
import { ReactNode } from "react";

const TRANSLATION_PREFIX = "empty";

export interface EmptyProps {
  message?: ReactNode;
}

export function Empty({ message }: EmptyProps) {
  const { t } = useTranslation("common");

  return (
    <S.Empty>
      <Image
        width={80}
        height={80}
        src="/assets/svg/oops-icon.svg"
        alt="Empty data icon"
      />
      {message ? (
        message
      ) : (
        <S.EmptyDescription>
          <span>{t(`${TRANSLATION_PREFIX}.title`)}</span>{" "}
          {t(`${TRANSLATION_PREFIX}.description`)}
        </S.EmptyDescription>
      )}
    </S.Empty>
  );
}
