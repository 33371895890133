import { useMemo } from "react";
import { LabelWithBackground } from "@edusynch/edusynch-ui";

import * as S from "./IncidentLevel.styles";
import { useTranslation } from "next-i18next";

interface IIncidentLevelProps {
  incidentLevel: string;
  secondary?: boolean;
}

const customStyle: React.CSSProperties = {
  padding: "0.5rem 1rem",
  textAlign: "center",
  display: "block",
  width: "fit-content",
  lineHeight: "1.2rem",
};

export function IncidentLevel({
  incidentLevel,
  secondary = false,
}: IIncidentLevelProps) {
  const { t } = useTranslation("common");

  const backgroundVariant = useMemo(() => {
    switch (incidentLevel) {
      case "High":
        return "tertiary";
      case "Medium":
        return "orange";
      case "Low":
        return "green";
      default:
        return "gray";
    }
  }, [incidentLevel]);

  if (secondary) {
    return (
      <S.SecondaryIncidentLevel incidentLevel={incidentLevel}>
        {t(
          `components.incident_level.incidents.${incidentLevel.toLowerCase()}`
        )}
      </S.SecondaryIncidentLevel>
    );
  }

  return (
    <LabelWithBackground
      variant={backgroundVariant}
      style={customStyle}
      data-testid="incident-level"
    >
      {t(`components.incident_level.incidents.${incidentLevel.toLowerCase()}`)}
    </LabelWithBackground>
  );
}
