import { ReactElement } from "react";
import { styled } from "@mui/material";
import MuiTooltip, {
  TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.customColors.primary[500],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "1rem",
    backgroundColor: theme.customColors.primary[500],
    color: theme.customColors.common.white,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "normal",
  },
}));

interface ITooltipProps extends TooltipProps {
  children: ReactElement<any, any>;
}

export function Tooltip({ children, ...props }: ITooltipProps) {
  return <HtmlTooltip {...props}>{children}</HtmlTooltip>;
}
