import { useState } from "react";
import { useTranslation } from "next-i18next";
import { Button, Menu, MenuItem, Stack, useTheme } from "@mui/material";
import { Typography } from "@edusynch/edusynch-ui";
import { Icon } from "../Icon";
import { useSessionProvider } from "@/hooks/useSessionProvider";
import { paginationButtonSx } from "./Pagination.styles";

const paginationItems = [25, 50, 100];

interface PaginationProps {
  page: number;
  perPage: number;
  prevPage?: number;
  nextPage?: number;
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
  onPrevPageChange?: (page: number) => void;
  onNextPageChange?: (page: number) => void;
}

export function Pagination({
  page,
  perPage,
  prevPage,
  nextPage,
  onPageChange,
  onPrevPageChange,
  onNextPageChange,
  onPerPageChange,
}: PaginationProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const { t } = useTranslation("common");

  const { isD2LBrightspaceProvider } = useSessionProvider();

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handlePerPageChange = (value: number) => {
    onPerPageChange(value);
    handleCloseMenu();
  };

  const handlePrevPageChange = () => {
    if (isD2LBrightspaceProvider) {
      onPrevPageChange && onPrevPageChange(prevPage!);
    } else {
      onPageChange(+page - 1);
    }
  };

  const handleNextPageChange = () => {
    if (isD2LBrightspaceProvider) {
      onNextPageChange && onNextPageChange(nextPage!);
    } else {
      onPageChange(+page + 1);
    }
  };

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 2, sm: 0 }}
      alignItems="center"
      justifyContent={isD2LBrightspaceProvider ? "flex-end" : "space-between"}
    >
      {!isD2LBrightspaceProvider && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color="text">{t("pagination.showing")}</Typography>
          <Button
            onClick={handleOpenMenu}
            sx={{
              width: "5rem",
              height: "2rem",
              border: `1px solid ${theme.customColors.gray[100]}`,
              color: theme.customColors.common.text,
              fontSize: "0.875rem",
              borderRadius: "2rem",
              fontWeight: 400,
              backgroundColor: theme.customColors.common.white,
              "&:hover": {
                backgroundColor: theme.customColors.gray[200],
              },
            }}
            endIcon={<Icon size="small" name="arrow-down" />}
          >
            {perPage}
          </Button>
        </Stack>
      )}
      <Stack direction="row" alignItems="center">
        <Button
          onClick={handlePrevPageChange}
          disabled={!prevPage}
          sx={{
            ...paginationButtonSx(theme),
            borderRadius: "2rem 0 0 2rem",
          }}
        >
          <Icon size="small" name="arrow-left" />
        </Button>
        {!isD2LBrightspaceProvider && (
          <Stack
            direction="column"
            justifyContent="center"
            px={2}
            height="2.5rem"
            border={`1px solid ${theme.customColors.gray[100]}`}
            bgcolor={theme.customColors.common.white}
          >
            <Typography color="text">
              {t("pagination.page")} {page}
            </Typography>
          </Stack>
        )}
        <Button
          onClick={handleNextPageChange}
          disabled={!nextPage}
          sx={{
            ...paginationButtonSx(theme),
            borderRadius: "0 2rem 2rem 0",
          }}
        >
          <Icon size="small" name="arrow-right" />
        </Button>
      </Stack>
      {!isD2LBrightspaceProvider && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {paginationItems.map((item) => (
            <MenuItem key={item} onClick={() => handlePerPageChange(item)}>
              {item}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Stack>
  );
}
