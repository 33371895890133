import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";
import { toast } from "@/components/common/Toast";
import {
  QUIZZES_LIST_URL,
  THIRD_PARTY_QUIZZES_LIST_URL,
} from "@/constants/api-routes";
import { THIRD_PARTY_QUIZ_TYPE } from "./useThirdPartyQuiz";
import { Course } from "./useCourses";
import { decode } from "@/utils";

interface GetThirdPartyQuizzesProps {
  page?: number;
  perPage?: number;
  search?: string;
  courseId?: number | null;
  sortBy?: string;
  sort?: string;
}

export type ThirdPartyQuiz = {
  id: number;
  name: string;
  active: boolean;
  created_at: string;
  date_start: string;
  end_date: string;
  password: string;
  platform: string;
  course: Course;
  url: string;
  enable_simulation: boolean;
  enable_photo_of_user: boolean;
  enable_photo_of_id: boolean;
  enable_room_scan: boolean;
  enable_photo_of_user_during_test: boolean;
  enable_photo_of_screen_during_test: boolean;
  enable_user_screen_recording: boolean;
  enable_user_video_recording: boolean;
  enable_user_audio_recording: boolean;
  enable_random_room_scan: boolean;
  disable_screenshot: boolean;
  enable_basic_calculator: boolean;
  enable_scientific_calculator: boolean;
  disable_copy_paste: boolean;
  student_instructions: string;
  proctor_guidelines: string;
  restrict_on_dates: number;
  onDelete: (quizId: number) => void;
  deletingThirdPartyQuizId: number;
  isDeleteLoading: boolean;
};

type GetThirdPartyQuizzesResponse = {
  quizzes: ThirdPartyQuiz[];
  total: number;
};

interface UseThirdPartyQuizzesProps {
  userType: "admin" | "student";
  page?: number;
  perPage?: number;
  search?: string;
  courseId?: number | null;
  sortBy?: string;
  sort?: string;
  enabled?: boolean;
}

export function useThirdPartyQuizzes({
  page,
  perPage,
  search,
  courseId,
  sortBy,
  sort,
  enabled = true,
  userType = "admin",
}: UseThirdPartyQuizzesProps) {
  const { api } = useApiClient();

  const getThirdPartyQuizzes = async ({
    page,
    perPage,
    search,
    courseId,
    sortBy,
    sort,
  }: GetThirdPartyQuizzesProps): Promise<GetThirdPartyQuizzesResponse> => {
    const quizzesURL =
      userType === "admin" ? QUIZZES_LIST_URL : THIRD_PARTY_QUIZZES_LIST_URL;

    const response = await api.get(quizzesURL, {
      params: {
        course_id: courseId || undefined,
        search: search || undefined,
        page: page || undefined,
        paginates_per: perPage || undefined,
        sort: sort || undefined,
        sort_by: sortBy || undefined,
        quiz_type: userType === "admin" ? THIRD_PARTY_QUIZ_TYPE : undefined,
      },
    });

    const quizzes = response.data.content.quizzes;

    const parseQuizzes =
      quizzes?.map((quiz: ThirdPartyQuiz) => ({
        ...quiz,
        password: quiz?.password ? decode(quiz.password)?.password || "" : "",
      })) || [];

    return {
      quizzes: parseQuizzes,
      total: response?.data?.content?.total_items || 0,
    };
  };

  return useQuery({
    queryKey: [
      "third_party_quizzes",
      {
        page,
        perPage,
        search,
        courseId,
        sortBy,
        sort,
      },
    ],
    queryFn: () =>
      getThirdPartyQuizzes({
        page,
        perPage,
        search,
        courseId,
        sortBy,
        sort,
      }),
    staleTime: 1000 * 60 * 1,
    enabled,
    onError: (error: any) => {
      toast.error({ message: error?.message });
    },
  });
}
