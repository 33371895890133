import { styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";
import { getColorByStatusLabel } from "@/components/common/Status/Status.utils";

interface IStatusLabelProps {
  status: string;
}

export const StatusLabel: ComponentType<
  HTMLAttributes<HTMLDivElement> & IStatusLabelProps
> = styled("div")<IStatusLabelProps>`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: ${(props) =>
    getColorByStatusLabel({
      status: props.status,
      theme: props.theme,
    })};

  &::before {
    content: "";
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    background-color: ${(props) =>
      getColorByStatusLabel({
        status: props.status,
        theme: props.theme,
      })};
  }
`;
