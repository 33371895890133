import { TIntervalOption } from "@/components/widgets/common/IntervalOptions/IntervalOptions.utils";
import { useState } from "react";

interface IUseIntervalOptionProps {
  defaultOption: TIntervalOption;
}

export const useIntervalOption = ({
  defaultOption = "7",
}: IUseIntervalOptionProps) => {
  const [intervalOption, setIntervalOption] = useState(defaultOption);

  return {
    intervalOption,
    setIntervalOption,
  };
};
