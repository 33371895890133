import { STUDENTS_LIST_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";
import { useSessionProvider } from "./useSessionProvider";

interface GetStudentsProps {
  page?: string | number;
  perPage?: string | number;
  prevPage?: string | number;
  search?: string;
  courseId?: number | null;
  quizId?: number | null;
  accountId?: number;
  sortBy?: string;
  sort?: string;
  startDate?: string;
  endDate?: string;
}

export type TQuiz = {
  id: number;
  name: string;
};

export type TCourse = {
  id: number;
  name: string;
  quizzes: TQuiz[];
};

// TODO: change name later
export type TXStudent = {
  id: number;
  name: string;
  email: string;
  avatar_url: string;
  courses: TCourse[];
  quizzes: TQuiz[];
};

type GetStudentsResponse = {
  students: TXStudent[];
  total: number;
  nextPage: number | null;
  prevPage: number | null;
  currentPage: number | null;
};

interface UseStudentsProps {
  page?: string | number;
  perPage?: string | number;
  prevPage?: string | number;
  search?: string;
  courseId?: number | null;
  quizId?: number | null;
  accountId?: number;
  sortBy?: string;
  sort?: string;
  startDate?: string;
  endDate?: string;
  enabled?: boolean;
}

export function useStudents({
  page,
  perPage,
  prevPage,
  search,
  courseId,
  quizId,
  accountId,
  sortBy,
  sort,
  startDate,
  endDate,
  enabled = true,
}: UseStudentsProps) {
  const { api } = useApiClient();
  const { isD2LBrightspaceProvider } = useSessionProvider();

  const getStudents = async ({
    page,
    perPage,
    prevPage,
    search,
    courseId,
    quizId,
    accountId,
    sortBy,
    sort,
    startDate,
    endDate,
  }: GetStudentsProps): Promise<GetStudentsResponse> => {
    const params = {
      search,
      course_id: courseId,
      quiz_id: quizId,
      account_id: accountId,
      page: page === 0 ? "" : page,
      prev_page: undefined as string | undefined,
      paginates_per: perPage,
      sort: sort || undefined,
      sort_by: sortBy || undefined,
      start_date: startDate,
      end_date: endDate,
    };

    if (isD2LBrightspaceProvider) {
      params.prev_page = prevPage == 0 ? "" : String(prevPage || "");
    }

    const response = await api.get(STUDENTS_LIST_URL, {
      params,
    });

    const students = response.data.content.students.map((student: any) => ({
      ...student,
      avatar_url: student.avatar || "",
    }));

    const parsePrevPage = isD2LBrightspaceProvider
      ? response.data.content.current_page
        ? response.data.content.prev_page || "0"
        : response.data.content.prev_page
      : response.data.content.prev_page;

    return {
      students,
      prevPage: parsePrevPage,
      nextPage: response.data.content.next_page,
      currentPage: response.data.content.current_page,
      total: response.data.content.total_items || students.length,
    };
  };

  return useQuery({
    queryKey: [
      "students",
      {
        page,
        perPage,
        prevPage,
        search,
        courseId,
        quizId,
        accountId,
        sortBy,
        sort,
        startDate,
        endDate,
      },
    ],
    queryFn: () =>
      getStudents({
        page,
        perPage,
        prevPage,
        search,
        courseId,
        quizId,
        accountId,
        sortBy,
        sort,
        startDate,
        endDate,
      }),
    enabled,
    refetchOnWindowFocus: false,
  });
}
