import { useUserLocation } from "@/hooks/useUserLocation";
import { useRouter } from "next/router";

export function useSubmissionsUtils() {
  const router = useRouter();
  const { baseRoute } = useUserLocation();

  const isShowAnswersPage = router.asPath.includes("show-answers");
  const submissionsRoute = `${baseRoute}/submissions`;
  const submissionAnswersRoute = "/submission-answers";
  const submissionShowAnswersRoute = "/show-answers";

  return {
    isShowAnswersPage,
    submissionsRoute,
    submissionAnswersRoute,
    submissionShowAnswersRoute,
  };
}
