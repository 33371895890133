import { Skeleton } from "@edusynch/edusynch-ui";

export const subaccountsTitleStyle = {
  display: "block",
  fontSize: "0.75rem",
  marginBottom: "0.3125rem",
  lineHeight: "0.75rem",
};

export const selectedAccountStyle = {
  height: "auto",
  padding: 0,
  background: "none",
  fontSize: "0.875rem",
  lineHeight: "0.875rem",
  fontWeight: "bold",
  gap: "0.25rem",
};

export const HeaderAccountsSelectPlaceholder = () => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "0.8rem" }}>
      <Skeleton
        key={1}
        width="8.5rem"
        height="2rem"
        secondary
        data-testid="placeholder"
      />
    </div>
  );
};
