import { ReactNode } from "react";
import { Grid, IconButton, useTheme } from "@mui/material";

import * as S from "./WidgetCardHeader.styles";
import { IconRoundBox } from "@edusynch/edusynch-ui";
import { Icon } from "@/components";

interface IWidgetCardProps {
  size: "small" | "large";
  icon?: string;
  title: string;
  subtitle?: string;
  showSettingsButton?: boolean;
  showFiltersButton?: boolean;
  onOpenSettings?: () => void;
  onOpenFilters?: () => void;
  hasBorderBottom?: boolean;
  hasFiltersActive?: boolean;
  children?: ReactNode;
}

export const WidgetCardHeader = ({
  size,
  icon,
  title,
  subtitle,
  showSettingsButton,
  showFiltersButton,
  onOpenSettings,
  onOpenFilters,
  hasBorderBottom = false,
  hasFiltersActive = false,
  children,
}: IWidgetCardProps) => {
  const theme = useTheme();

  return (
    <Grid container data-testid="widget-card-header">
      <Grid item xs={12} md={size === "small" ? 12 : 6}>
        <S.Header size={size} hasBorderBottom={hasBorderBottom}>
          {icon && (
            <S.Icon>
              <IconRoundBox
                icon={<Icon name={icon} variant="primary" size="small" />}
                variant="primary"
              />
            </S.Icon>
          )}
          <div>
            {subtitle && <h6>{subtitle}</h6>}
            <p>{title}</p>
          </div>
          {showSettingsButton && size === "small" && (
            <IconButton
              aria-label="settings"
              id="settings"
              onClick={onOpenSettings}
              sx={{ marginLeft: "auto", alignSelf: "flex-start" }}
            >
              <Icon name="settings" />
            </IconButton>
          )}
          {showFiltersButton && size === "small" && (
            <IconButton
              aria-label="users-filter"
              id="users-filter"
              onClick={onOpenFilters}
              sx={
                hasFiltersActive
                  ? {
                      position: "relative",
                      marginLeft: "auto",

                      "::before": {
                        content: "''",
                        position: "absolute",
                        width: "0.5rem",
                        height: "0.5rem",
                        borderRadius: "50%",
                        top: "0.5rem",
                        right: "0.5rem",
                        bgcolor: theme.customColors.green[500],
                      },
                    }
                  : {
                      marginLeft: "auto",
                    }
              }
            >
              <Icon name="filter" />
            </IconButton>
          )}
        </S.Header>
      </Grid>
      {children ? children : null}
    </Grid>
  );
};
