import { Switch as EdusynchUISwitch } from "@edusynch/edusynch-ui";
import { Box, CircularProgress } from "@mui/material";

export interface ISwitchProps {
  active: boolean;
  disabled: boolean;
  loading?: boolean;
  onChange: (checked: boolean) => void;
}

export function Switch({ active, disabled, loading, onChange }: ISwitchProps) {
  if (loading) {
    return (
      <Box display="flex" width={32} height={32} alignItems="center">
        <CircularProgress size={18} />
      </Box>
    );
  }

  return (
    <EdusynchUISwitch
      active={active}
      disabled={disabled}
      onChange={({ target }) => onChange(target.checked)}
      data-testid="switch"
    />
  );
}
