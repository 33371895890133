import { TStudent } from "@/hooks";
import { UserAvatar } from "@edusynch/edusynch-ui";
import { Icon } from "../Icon";

export type StudentAvatarProps = {
  hideName?: boolean;
} & TStudent;

export function StudentAvatar({
  name,
  email,
  avatar_url,
  hideName,
}: StudentAvatarProps) {
  return (
    <UserAvatar
      name={name}
      email={email}
      url={avatar_url}
      emailIcon={<Icon name="email" width={16} height={16} variant="primary" />}
      userCardInfo
      hideName={hideName}
    />
  );
}
