import { Grid } from "@mui/material";
import { ReactNode } from "react";

import * as S from "./WidgetCardSubHeader.styles";

interface IWidgetCardSubHeaderProps {
  size: "small" | "large";
  justifyContent?: "space-between" | "flex-end";
  children: ReactNode;
}

export const WidgetCardSubHeader = ({
  size = "small",
  justifyContent,
  children,
}: IWidgetCardSubHeaderProps) => {
  return (
    <Grid
      item
      xs={12}
      md={size === "small" ? 12 : 6}
      data-testid="widget-card-subheader"
    >
      <S.SubHeader justifyContent={justifyContent}>{children}</S.SubHeader>
    </Grid>
  );
};
