import { Box, Fade, Stack, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { Icon, AccountsSelect } from "@/components/common";
import { Account } from "@/hooks/accounts";

import { ClickAwayListener } from "@mui/material";
import { Button, IconRoundBox, Typography } from "@edusynch/edusynch-ui";
import { useTranslation } from "next-i18next";
import flattenMap from "@/utils/flatmap.utils";
import {
  HeaderAccountsSelectPlaceholder,
  selectedAccountStyle,
  subaccountsTitleStyle,
} from "./utils";
import { MOBILE_SIZE, useMediaQuery } from "@/hooks";

export interface HeaderAccountsSelectProps {
  currentAccount?: Account;
  rawAccounts: Account[];
  onAccountSelect: (account: Account) => void;
  isLoading: boolean;
  isError: boolean;
}

const HeaderAccountsSelect = ({
  currentAccount,
  rawAccounts,
  onAccountSelect,
  isLoading,
  isError,
}: HeaderAccountsSelectProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { t } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(MOBILE_SIZE);

  const accounts = useMemo(
    () =>
      flattenMap<Account, "sub_account">({
        arrayToFlatmap: rawAccounts,
        propName: "sub_account",
        identifier: "id",
      }),
    [rawAccounts]
  );

  const handleOpen = () => {
    if (isMobile) return;

    setShowMenu((prev) => !prev);
  };

  if (isLoading) {
    return <HeaderAccountsSelectPlaceholder />;
  }

  if (isError || accounts?.length === 0) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => setShowMenu(false)}>
      <Stack
        direction="row"
        spacing={1}
        border={
          isMobile ? "none" : `1px solid ${theme.customColors.primary[200]}`
        }
        borderRadius="2rem"
        p={isMobile ? 0 : 1}
        sx={{
          "&:hover": {
            bgcolor: isMobile ? "" : theme.customColors.primary[200],
            "> div > div": {
              bgcolor: theme.customColors.primary[100],
            },
          },
        }}
      >
        {!isMobile && (
          <IconRoundBox
            icon={<Icon name="user" size="small" variant="primary" />}
            size="extra-small"
            variant="primary.dark"
          />
        )}
        <Box sx={{ position: "relative" }}>
          <Typography color="gray" style={subaccountsTitleStyle}>
            {t("accounts.subaccounts")}
          </Typography>
          <Button
            variant="text"
            style={selectedAccountStyle}
            onClick={handleOpen}
          >
            {currentAccount?.name}{" "}
            {!isMobile && (
              <Icon
                width={12}
                height={12}
                name={showMenu ? "arrow-up" : "arrow-down"}
                variant="primary"
              />
            )}
          </Button>

          <Fade in={showMenu}>
            <div>
              <AccountsSelect
                accounts={rawAccounts}
                onSelect={onAccountSelect}
              />
            </div>
          </Fade>
        </Box>
      </Stack>
    </ClickAwayListener>
  );
};

export default HeaderAccountsSelect;
