import { LinkProps } from "next/link";
import { usePathname } from "next/navigation";
import { ReactElement, ReactNode } from "react";

import * as S from "./Link.styles";

interface ILinkProps extends LinkProps {
  href: string;
  children: ReactElement | ReactNode;
  shouldMatchExactHref?: boolean;
  menuLink?: boolean;
}

export function Link({
  href,
  children,
  shouldMatchExactHref = false,
  menuLink,
  ...rest
}: ILinkProps) {
  const asPath = usePathname();

  let isActive = false;

  if (shouldMatchExactHref && (asPath?.includes(href) || asPath === rest.as)) {
    isActive = true;
  }

  if (
    !shouldMatchExactHref &&
    (asPath?.includes(String(href)) || asPath?.includes(String(rest.as)))
  ) {
    isActive = true;
  }

  return (
    <S.Link
      href={href}
      className={isActive ? "active" : ""}
      menuLink={!!menuLink}
    >
      <>{children}</>
    </S.Link>
  );
}
