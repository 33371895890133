import { Typography } from "@edusynch/edusynch-ui";
import { Box } from "@mui/material";
import Image from "next/image";

export function EmptyList() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        src="/assets/svg/no-results.svg"
        width={200}
        height={200}
        alt="Empty data icon"
      />
      <Typography
        variant="h3"
        color="secondary"
        style={{ marginBottom: "0.5rem" }}
      >
        No results.
      </Typography>
      <Typography color="text">Nothing here yet...</Typography>
    </Box>
  );
}
