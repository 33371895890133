import { useRouter } from "next/router";
import { InvalidTokenError } from "./InvalidTokenError";
import { ServerError } from "./ServerError";
import { PermissionDeniedError } from "./PermissionDeniedError";
import { UnexpectedError } from "./UnexpectedError";

export function ErrorPage() {
  const router = useRouter();

  const error = router.query["type"] || "unexpected";

  if (error === "server-error") {
    return <ServerError />;
  } else if (error === "invalid-token") {
    return <InvalidTokenError />;
  } else if (error === "permission-denied") {
    return <PermissionDeniedError />;
  }

  return <UnexpectedError />;
}
