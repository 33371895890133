import { IPaginationProps } from "@edusynch/edusynch-ui/dist/lib/components/Pagination/Pagination";
import { ColumnDefinitionType } from "@edusynch/edusynch-ui/dist/lib/components/Table/table.utils";
import { Pagination } from "@edusynch/edusynch-ui";
import { Stack } from "@mui/material";
import { ReactNode } from "react";
import { ListItem } from "./ListItem/ListItem";

interface IResponsiveListProps<T> {
  data: T[];
  columns: ColumnDefinitionType<T>[];
  pagination: IPaginationProps;
  actions: object;
}

function getValue<T>(
  obj: T,
  column: ColumnDefinitionType<T>,
  additionalData: any = {},
  index: number
) {
  if (typeof column.render === "function") {
    return (
      <ListItem
        key={`list-item-${index}`}
        onItemClick={() => additionalData.onItemClick({ ...obj })}
      >
        {column.render({ ...obj, ...additionalData })}
      </ListItem>
    );
  }

  return null as ReactNode;
}

export function ResponsiveList<T>({
  data,
  columns,
  pagination,
  actions,
}: IResponsiveListProps<T>) {
  const renderList = data.map((listItem, index) => {
    return columns.map((column) => {
      return getValue(listItem, column, actions, index);
    });
  });

  return (
    <>
      <Stack
        spacing={0.5}
        data-testid="responsive-list"
        sx={{
          paddingRight: 1,
        }}
      >
        {renderList}
      </Stack>
      {!!pagination && <Pagination {...pagination} />}
    </>
  );
}
