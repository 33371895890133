import {
  STUDENT_GUIDELINES_UPDATE_URL,
  STUDENT_GUIDELINES_URL,
} from "@/constants/api-routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";

export interface IStudentGuideline {
  id: number;
  name: string;
  external_application_id: number;
  whitelabel_id: number | null;
  created_at: string;
  enable_open_book: boolean;
  enable_notes: boolean;
  enable_scratch_paper: boolean;
  enable_restroom_breaks: boolean;
  enable_handheld_calculator: boolean;
}

export interface IUpdateStudentGuideline {
  id: number;
  enable_open_book: boolean;
  enable_notes: boolean;
  enable_scratch_paper: boolean;
  enable_restroom_breaks: boolean;
  enable_handheld_calculator: boolean;
}

type GetStudentGuidelinesResponse = {
  studentGuidelines: IStudentGuideline;
};

export function useStudentGuideline() {
  const { api } = useApiClient();
  const queryClient = useQueryClient();

  const getStudentGuidelines = async (
    studentGuidelineId: number
  ): Promise<GetStudentGuidelinesResponse> => {
    const response = await api.get(STUDENT_GUIDELINES_URL, {
      params: {
        id: studentGuidelineId,
      },
    });

    return {
      studentGuidelines: response.data.content,
    };
  };

  const updateStudentGuideline = useMutation({
    mutationFn: async (studentGuidelineData: IUpdateStudentGuideline) => {
      const response = await api.put(STUDENT_GUIDELINES_UPDATE_URL, {
        ...studentGuidelineData,
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["student_guidelines"]);
    },
  });

  return {
    getStudentGuidelines,
    updateStudentGuideline,
  };
}
