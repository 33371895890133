import Script from "next/script";

declare global {
  interface Window {
    zE: any;
  }
}

export function ZendeskChat() {
  return (
    <Script
      type="text/javascript"
      id="ze-snippet"
      src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_KEY}`}
      onLoad={() => window?.zE("messenger", "show")}
    />
  );
}
