import { useAuth } from "@/providers/AuthProvider";
import { getDefaultStartDate, getDefaultEndDate } from "@/utils";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export function useQuizzesSessionsFilters() {
  const [tempIncidentLevel, setTempIncidentLevel] = useState<
    string | undefined
  >(undefined);

  const router = useRouter();
  const { session } = useAuth();

  const {
    search = "",
    incident_level = undefined,
    start_date = getDefaultStartDate({ timezone: session?.timezone }),
    end_date = getDefaultEndDate({ timezone: session?.timezone }),
    sort = "DESC",
    sort_by = "created_at",
    page = 1,
    paginates_per = 10,
  } = router.query;

  useEffect(() => {
    setTempIncidentLevel(incident_level ? String(incident_level) : undefined);
  }, [incident_level]);

  const handlePageChange = (value: number) => {
    router.replace({
      query: {
        ...router.query,
        page: value,
      },
    });
  };

  const handlePerPageChange = (value: number) => {
    router.replace({
      query: {
        ...router.query,
        paginates_per: value,
      },
    });
  };

  const handleFilter = (filters: any) => {
    router.replace({
      query: {
        ...router.query,
        page: 1,
        search: filters.search,
        incident_level: filters.incident_level || undefined,
        start_date: filters.start_date || start_date,
        end_date: filters.end_date || end_date,
        sort,
        sort_by,
        paginates_per,
      },
    });
  };

  const handleUpdateFilter = (filter: any) => {
    const { filterName } = filter;

    router.replace({
      query: {
        ...router.query,
        [filterName]: filter.id,
      },
    });
  };

  const handleClearFilter = (filter: any) => {
    const { filterName } = filter;

    setTempIncidentLevel(undefined);

    router.replace({
      query: {
        ...router.query,
        [filterName]: null,
      },
    });
  };

  const handleClearAllFilters = () => {
    setTempIncidentLevel(undefined);
    router.replace({
      query: {
        hash: router.query.hash,
        quiz_type: router.query.quiz_type,
        quiz_id: router.query.quiz_id,
        course_id: router.query.course_id,
      },
    });
  };

  return {
    search: String(search),
    incidentLevel: incident_level ? String(incident_level) : undefined,
    startDate: String(start_date) || "",
    endDate: String(end_date) || "",
    sort: String(sort),
    sortBy: String(sort_by),
    page: Number(page),
    perPage: Number(paginates_per),
    handlePageChange,
    handlePerPageChange,
    handleFilter,
    handleClearFilter,
    handleClearAllFilters,
    handleUpdateFilter,
    tempIncidentLevel,
    setTempIncidentLevel,
  };
}
