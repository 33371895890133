export function MaintenanceWarningIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9316_4671)">
        <path
          d="M19.7675 11.9999C19.7675 11.4358 19.7127 10.8826 19.6031 10.3514L21.6734 8.5549C21.9637 8.30296 22.0349 7.87576 21.8432 7.54166L20.5178 5.24681C20.3261 4.91271 19.9208 4.75935 19.5539 4.88532L16.9632 5.77807C16.1417 5.04963 15.1723 4.4855 14.1043 4.12402L13.5839 1.43483C13.5127 1.05691 13.1786 0.777588 12.7898 0.777588H10.1444C9.75553 0.777588 9.42691 1.05144 9.35023 1.43483L8.82992 4.12402C7.76739 4.48003 6.79796 5.04963 5.97094 5.77807L3.38032 4.88532C3.01337 4.75935 2.60807 4.90723 2.41638 5.24681L1.09095 7.54166C0.899252 7.87576 0.970452 8.30296 1.26073 8.5549L3.33103 10.3514C3.22149 10.8826 3.16672 11.4358 3.16672 11.9999C3.16672 12.5641 3.22149 13.1172 3.33103 13.6485L1.26073 15.4449C0.970452 15.6969 0.899252 16.1241 1.09095 16.4582L2.41638 18.753C2.60807 19.0871 3.01337 19.2405 3.38032 19.1145L5.97094 18.2218C6.79248 18.9502 7.76191 19.5143 8.82992 19.8758L9.35023 22.565C9.42144 22.9429 9.75553 23.2223 10.1444 23.2223H12.7898C13.1786 23.2223 13.5073 22.9484 13.5839 22.565L14.1043 19.8758C15.1668 19.5198 16.1362 18.9502 16.9632 18.2218L19.5539 19.1145C19.9208 19.2405 20.3261 19.0926 20.5178 18.753L21.8432 16.4582C22.0349 16.1241 21.9637 15.6969 21.6734 15.4449L19.6031 13.6485C19.7127 13.1172 19.7675 12.5641 19.7675 11.9999ZM11.4698 16.3541C9.06543 16.3541 7.11563 14.4043 7.11563 11.9999C7.11563 9.59553 9.06543 7.64572 11.4698 7.64572C13.8742 7.64572 15.824 9.59553 15.824 11.9999C15.824 14.4043 13.8742 16.3541 11.4698 16.3541Z"
          fill="#5D6670"
        />
        <path
          d="M15.576 11.405L9.16634 20.7581C8.73961 21.3765 9.18376 22.2125 9.93271 22.2125H22.752C23.5009 22.2125 23.9451 21.3765 23.5183 20.7581L17.1087 11.405C16.7429 10.865 15.9504 10.865 15.576 11.405Z"
          fill="#FBAB34"
        />
        <path
          d="M16.3421 20.7755C16.107 20.7755 15.9154 20.6971 15.7587 20.5317C15.6106 20.3662 15.5322 20.1746 15.5322 19.9482C15.5322 19.7218 15.6106 19.5215 15.7587 19.3734C15.9067 19.2167 16.107 19.1383 16.3421 19.1383C16.586 19.1383 16.7776 19.2167 16.9256 19.3734C17.0737 19.5302 17.1433 19.7218 17.1433 19.9482C17.1433 20.1746 17.0737 20.3662 16.9256 20.5317C16.7776 20.6971 16.586 20.7755 16.3421 20.7755ZM15.7848 17.9278L15.6019 14.9755C15.5758 14.6272 15.837 14.3311 16.1593 14.3311H16.525C16.8472 14.3311 17.0998 14.6272 17.0824 14.9755L16.8995 17.9278C16.8821 18.2413 16.6382 18.4938 16.3421 18.4938C16.0547 18.4851 15.8109 18.2413 15.7848 17.9278Z"
          fill="#FFF6E8"
        />
      </g>
      <defs>
        <clipPath id="clip0_9316_4671">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
