import { formatDate } from "@/utils";
import { TIntervalOption } from "../common/IntervalOptions/IntervalOptions.utils";
import { TWidgetSize } from "../interfaces";

const SMALL_WIDGET_MAX_LABELS = 3;
const MINIMUM_Y_STEP_SIZE = 25;
const DEFAULT_Y_SCALE = 100;
const MAX_Y_ROWS = 4;

export const getChartLabelsByWidgetSize = (
  data: any,
  widgetSize: TWidgetSize
) => {
  if (!data || !data?.analysis?.length) return [];
  if (widgetSize === "large") return data.analysis;

  const labels = data.analysis;

  const labelsInHalf = Math.floor(labels.length / 2);
  const lastLabelIndex = labels.length - 1;

  if (labels <= SMALL_WIDGET_MAX_LABELS) return data.analysis;

  return data.analysis.filter((_: string, index: number) => {
    return index === 0 || index === labelsInHalf || index === lastLabelIndex;
  });
};

export const formatChartLabels = (
  labels: any[],
  intervalOption: TIntervalOption
) => {
  if (!labels.length) {
    return [...Array(5).keys()].map(() => "");
  }

  return (
    labels?.map((item) =>
      intervalOption === "12"
        ? formatDate({ date: item?.date, dateFormat: "MMM" })
        : formatDate({ date: item?.date, dateFormat: "dd MMM" })
    ) || []
  );
};

export const formatChartValues = (data: any) => {
  return data?.analysis?.map((item: any) => item.total_by_day) || [];
};

export const formatChartScaleYStepSize = (formattedChartData: any) => {
  if (!formattedChartData?.length) return MINIMUM_Y_STEP_SIZE;

  const stepSize = Math.floor(Math.max(...formattedChartData) / MAX_Y_ROWS);

  return stepSize >= MINIMUM_Y_STEP_SIZE ? stepSize : MINIMUM_Y_STEP_SIZE;
};

export const formatChartData = (
  formattedChartLabels: string[],
  formattedChartData: number[],
  backgroundColor: string,
  label: string
) => {
  return {
    labels: formattedChartLabels,
    datasets: [
      {
        label,
        data: formattedChartData,
        backgroundColor: [backgroundColor],
        borderColor: [backgroundColor],
        borderWidth: 2,
        lineTension: 0.5,
      },
    ],
  };
};

export const formatChartOptions = (chartScaleYStepSize: number) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: chartScaleYStepSize,
        },
        min: 0,
        max:
          chartScaleYStepSize > MINIMUM_Y_STEP_SIZE
            ? undefined
            : DEFAULT_Y_SCALE,
      },
    },
  };
};

export const DAILY_SESSIONS_WIDGET_MOCKED_DATA = {
  total: 231,
  analysis: [
    {
      total_by_day: 81,
      date: "2022-11-11T20:33:06.572+00:00",
    },
    {
      total_by_day: 50,
      date: "2022-11-12T20:33:06.554+00:00",
    },
    {
      total_by_day: 75,
      date: "2022-11-13T20:33:06.545+00:00",
    },
    {
      total_by_day: 50,
      date: "2022-11-14T20:33:06.539+00:00",
    },
    {
      total_by_day: 30,
      date: "2022-11-15T20:33:06.530+00:00",
    },
    {
      total_by_day: 45,
      date: "2022-11-16T20:33:06.511+00:00",
    },
    {
      total_by_day: 25,
      date: "2022-11-17T20:33:06.496+00:00",
    },
  ],
};
