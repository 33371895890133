import axios from "axios";

import { MAINTENANCE_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";

type UseMaintenanceProps = {
  enabled?: boolean;
};

type UseMaintenanceResponse = {
  maintenanceDescription: string;
  scheduledMaintenance: boolean;
};

export function useMaintenance({ enabled = true }: UseMaintenanceProps = {}) {
  const getMaintenance = async (): Promise<UseMaintenanceResponse> => {
    const response = await axios.get(MAINTENANCE_URL);

    const data = response.data;

    return {
      maintenanceDescription: data?.maintenance_description,
      scheduledMaintenance: data?.scheduled_maintenance,
    };
  };

  return useQuery({
    queryKey: ["maintenance"],
    queryFn: getMaintenance,
    staleTime: 1000 * 60 * 1,
    enabled,
    refetchOnWindowFocus: false,
  });
}
