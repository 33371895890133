import { useQuery } from "@tanstack/react-query";
import { useGetQuiz } from "./useGetQuiz";
import { useRouter } from "next/router";
import { useStudentGuideline } from "./useStudentGuideline";

export function useStudentGuidelines() {
  const router = useRouter();

  const { data } = useGetQuiz({
    quizId: router.query.quiz_id,
    courseId: router.query.course_id,
    quizType: router.query.quiz_type,
  });
  const { getStudentGuidelines } = useStudentGuideline();

  return useQuery({
    queryKey: ["student_guidelines", data?.quiz.student_guideline_id],
    queryFn: () =>
      getStudentGuidelines(data?.quiz.student_guideline_id as number),
    enabled: !!data?.quiz.student_guideline_id,
    staleTime: 1000 * 60 * 1,
    refetchOnWindowFocus: false,
  });
}
