import { ReactNode } from "react";

import * as S from "./WidgetCardContent.styles";

interface IWidgetCardContentProps {
  size: "small" | "large";
  hasBorderTop?: boolean;
  children: ReactNode;
}

export const WidgetCardContent = ({
  size = "small",
  hasBorderTop,
  children,
}: IWidgetCardContentProps) => {
  return (
    <S.Content
      size={size}
      hasBorderTop={hasBorderTop}
      data-testid="widget-card-content"
    >
      {children}
    </S.Content>
  );
};
