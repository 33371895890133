import { QUIZZES_LIST_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";
import { toast } from "@/components/common/Toast";
import { Course } from "./useCourses";
import { decode } from "@/utils";
import { useSessionProvider } from "./useSessionProvider";
import { THIRD_PARTY_QUIZ_TYPE } from "@/components/Quizzes/utils";

interface GetQuizzesProps {
  page?: string | number;
  perPage?: string | number;
  prevPage?: string | number;
  search?: string;
  courseId?: number | null;
  sortBy?: string;
  sort?: string;
  quizType?: string;
}

export type Quiz = {
  id: number;
  quiz_id: number;
  name: string;
  course: Course;
  course_lms_id: number;
  active: boolean;
  proctored?: boolean;
  proctoring_settings_id: number | null;
  student_guideline_id: number | null;
  instructions: string;
  sessions_count: number;
  password: string;
  quiz_type: string;
};

type GetQuizzesResponse = {
  quizzes: Quiz[];
  total: number;
  nextPage: number | null;
  prevPage: number | null;
  currentPage: number | null;
  isAllQuizzesEnabled: boolean;
  quizJob: boolean;
};

interface UseQuizzesProps {
  page?: string | number;
  perPage?: string | number;
  prevPage?: string | number;
  search?: string;
  courseId?: number | null;
  sortBy?: string;
  sort?: string;
  enabled?: boolean;
  quizType?: string;
  onSuccess?: (data: GetQuizzesResponse) => void;
}

export function useQuizzes({
  page,
  perPage,
  prevPage,
  search,
  courseId,
  sortBy,
  sort,
  enabled = true,
  quizType,
  onSuccess,
}: UseQuizzesProps) {
  const { api } = useApiClient();
  const { isD2LBrightspaceProvider } = useSessionProvider();

  const getQuizzes = async ({
    page,
    perPage,
    prevPage,
    search,
    courseId,
    quizType,
    sortBy,
    sort,
  }: GetQuizzesProps): Promise<GetQuizzesResponse> => {
    const params = {
      course_id: courseId || undefined,
      search: search || undefined,
      page: page === 0 ? "" : page,
      prev_page: undefined as string | undefined,
      paginates_per: perPage || undefined,
      sort: sort || undefined,
      sort_by: sortBy || undefined,
      quiz_type: quizType,
    };

    if (isD2LBrightspaceProvider) {
      params.prev_page = prevPage == 0 ? "" : String(prevPage || "");
    }

    const response = await api.get(QUIZZES_LIST_URL, {
      params,
    });

    const quizzes = response.data.content.quizzes;

    const parseQuizzes =
      quizzes?.map((quiz: Quiz) => ({
        ...quiz,
        password: quiz?.password
          ? decode(quiz.password)?.password || ""
          : undefined,
      })) || [];

    const parseTotal =
      response.data.content.total_items || response.data.content.quizzes.length;

    const parsePrevPage = isD2LBrightspaceProvider
      ? quizType === THIRD_PARTY_QUIZ_TYPE
        ? response.data.content.prev_page
        : response.data.content.current_page
        ? response.data.content.prev_page || "0"
        : response.data.content.prev_page
      : response.data.content.prev_page;

    const parseQuizJob =
      !!response.data.content.quiz_job &&
      !response.data.content.quiz_job.completed;

    return {
      quizzes: parseQuizzes,
      total: parseTotal,
      prevPage: parsePrevPage,
      nextPage: response.data.content.next_page,
      currentPage: response.data.content.current_page,
      isAllQuizzesEnabled: response.data.content.all_quizzes_enabled,
      quizJob: parseQuizJob,
    };
  };

  return useQuery({
    queryKey: [
      "quizzes",
      {
        page,
        perPage,
        prevPage,
        search,
        courseId,
        quizType,
        sortBy,
        sort,
      },
    ],
    queryFn: () =>
      getQuizzes({
        page,
        perPage,
        prevPage,
        search,
        courseId,
        quizType,
        sortBy,
        sort,
      }),
    enabled,
    onError: (error: any) => {
      toast.error({ message: error?.message });
    },
    onSuccess,
  });
}
