import { useTranslation } from "next-i18next";
import { Button } from "@edusynch/edusynch-ui";
import { Icon } from "../Icon";
import { EmptyData } from "../EmptyData";

import invalidTokenSVG from "@/public/assets/svg/invalid-token.svg";
import { Container, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import { DASHBOARD_ROUTE } from "@/constants/app-routes";

export function InvalidTokenError() {
  const router = useRouter();
  const { t } = useTranslation("common");
  const theme = useTheme();

  return (
    <Container maxWidth="sm" sx={{ mt: 20 }}>
      <EmptyData
        imageURL={invalidTokenSVG}
        imageWidth={219}
        imageHeight={214}
        title={t(`errors.invalid-token`) as string}
        titleStyle={{
          marginBottom: "0.5rem",
          fontSize: "2.5rem",
          fontWeight: "bold",
          color: theme.customColors.secondary[500],
        }}
        subtitleStyle={{ color: theme.customColors.common.text }}
        subtitle={t(`errors.please_try_again`) as string}
      />
      <Button
        size="large"
        startIcon={<Icon name="chevron-left" variant="white" />}
        style={{ margin: "2.5rem auto 0 auto" }}
        onClick={() => router.push(DASHBOARD_ROUTE)}
      >
        {t("errors.back_to_dashboard")}
      </Button>
    </Container>
  );
}
