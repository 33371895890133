import { TSize } from "@edusynch/edusynch-ui";

export function convertIconNameToPascalCase(iconName: string) {
  if (!iconName) return;

  const words = iconName.split(/-|\s/);

  const transformedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return `${transformedWords.join("")}Icon`;
}

export function convertIconSizeToPixels(iconSize: TSize) {
  switch (iconSize) {
    case "large":
      return 48;
    case "medium":
      return 32;
    case "small":
      return 16;
    case "extra-small":
      return 14;
    case "tiny":
      return 12;
    default:
      return 24;
  }
}
