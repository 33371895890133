import { QUIZZES_LMS_LIST_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";
import { toast } from "@/components/common/Toast";

interface GetQuizzesLmsProps {
  page?: number;
  perPage?: number;
  search?: string;
  courseLmsId?: number | null;
  sortBy?: string;
  sort?: string;
}

export type QuizLms = {
  id: number;
  name: string;
  proctored: boolean;
};

type GetQuizzesLmsResponse = {
  quizzes: QuizLms[];
  nextPage: number | null;
  prevPage: number | null;
  total: number;
};

interface UseQuizzesLmsProps {
  page?: number;
  perPage?: number;
  search?: string;
  courseLmsId?: number | null;
  sortBy?: string;
  sort?: string;
  enabled?: boolean;
  onSuccess?: (data: GetQuizzesLmsResponse) => void;
}

export function useQuizzesLms({
  page,
  perPage,
  search,
  courseLmsId,
  sortBy,
  sort,
  enabled = true,
  onSuccess,
}: UseQuizzesLmsProps) {
  const { api } = useApiClient();

  const getQuizzes = async ({
    page,
    perPage,
    search,
    courseLmsId,
    sortBy,
    sort,
  }: GetQuizzesLmsProps): Promise<GetQuizzesLmsResponse> => {
    const response = await api.get(QUIZZES_LMS_LIST_URL, {
      params: {
        course_lms_id: courseLmsId || undefined,
        search: search || undefined,
        page: page || undefined,
        paginates_per: perPage || undefined,
        sort: sort || undefined,
        sort_by: sortBy || undefined,
      },
    });

    const quizzes = response.data.content.quizzes;

    return {
      quizzes: quizzes,
      prevPage: response.data.content.prev_page,
      nextPage: response.data.content.next_page,
      total: response.data.content.total_items || quizzes.length,
    };
  };

  return useQuery({
    queryKey: [
      "quizzes_lms",
      {
        page,
        perPage,
        search,
        courseLmsId,
        sortBy,
        sort,
      },
    ],
    queryFn: () =>
      getQuizzes({
        page,
        perPage,
        search,
        courseLmsId,
        sortBy,
        sort,
      }),
    enabled,
    onError: (error: any) => {
      toast.error({ message: error?.message });
    },
    onSuccess,
  });
}
