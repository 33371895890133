import { useAuth } from "@/providers/AuthProvider";
import { SessionProviderEnum } from "@/utils/session-provider.enum";

export function useSessionProvider() {
  const { session } = useAuth();
  const isCanvasProvider = session?.provider === SessionProviderEnum.CANVAS;
  const isMoodleProvider = session?.provider === SessionProviderEnum.MOODLE;
  const isD2LBrightspaceProvider =
    session?.provider === SessionProviderEnum.D2L_BRIGHTSPACE;

  return {
    isCanvasProvider,
    isMoodleProvider,
    isD2LBrightspaceProvider,
  };
}
