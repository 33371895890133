import { styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

export const Container: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  background-color: ${(props) => props.theme.customColors.common.white};
  border-radius: 0.375rem;
  box-shadow: 0 0.375rem 0.75rem rgba(49, 49, 49, 0.15);
`;

export const Options: ComponentType<HTMLAttributes<HTMLUListElement>> = styled(
  "ul"
)`
  border-radius: 0.375rem;
  max-height: 21.875rem;
  overflow-y: auto;

  scrollbar-color: ${(props) => props.theme.customColors.gray[200]};

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.customColors.gray[100]};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.customColors.gray[300]};
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.customColors.gray[500]};
  }
`;

export const GroupedOptions: ComponentType<
  HTMLAttributes<HTMLLIElement>
> = styled("li")`
  padding: 1rem 0 0;
`;

export const GroupedOptionsTitle: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  ${(props) => props.theme.customTypography.default};
  color: ${(props) => props.theme.customColors.gray[400]};
  text-transform: uppercase;
  padding-left: 1rem;
  padding-bottom: 0.375rem;
`;

export const GroupedOptionsItem: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  display: flex;
  align-items: center;
  ${(props) => props.theme.customTypography.default};
  color: ${(props) => props.theme.customColors.common.text};
  padding: 0.375rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.customColors.gray[200]};
  }

  svg {
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
    margin-right: 0.5rem;

    path {
      fill: ${(props) => props.theme.customColors.primary[400]};
    }
  }
`;

export const Option: ComponentType<HTMLAttributes<HTMLLIElement>> = styled(
  "li"
)`
  display: flex;
  align-items: center;
  ${(props) => props.theme.customTypography.default};
  color: ${(props) => props.theme.customColors.common.text};
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.customColors.gray[200]};
  }

  & + li {
    border-top: 1px solid ${(props) => props.theme.customColors.gray[200]};
  }

  svg {
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
    margin-right: 0.5rem;

    path {
      fill: ${(props) => props.theme.customColors.primary[400]};
    }
  }
`;

export const OptionHeader: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  display: flex;
  align-items: center;
  padding: 1rem;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.customColors.gray[200]};

  button {
    position: absolute;
  }

  h6 {
    text-align: center;
    margin: 0 auto;
    ${(props) => props.theme.customTypography.default};
    color: ${(props) => props.theme.customColors.common.text};
    font-weight: bold;
  }
`;

export const SearchContainer: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  border-bottom: 1px solid ${(props) => props.theme.customColors.gray[200]};

  input {
    width: 100%;
    border: none;
  }
`;
