import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiClient } from "../useApiClient";
import {
  ACCOUNTS_ENABLED_URL,
  SAVE_CURRENT_ACCOUNT_URL,
} from "@/constants/api-routes";
import { toast } from "@/components/common/Toast";
import { useTranslation } from "next-i18next";
import { AccountsFormData } from "@/components/Accounts/Common/AccountsForm";
import { Account } from "@/hooks/accounts/useAccounts";

type SaveCoursesProps = AccountsFormData;

export function useAccountsActions() {
  const { api } = useApiClient();
  const { t } = useTranslation("common");
  const queryClient = useQueryClient();

  const saveAccounts = async ({ accounts }: SaveCoursesProps) => {
    const response = await api.put(ACCOUNTS_ENABLED_URL, {
      accounts_enabled: accounts,
    });
    return response;
  };

  const saveAccount = async (account: Account) => {
    const response = await api.put(SAVE_CURRENT_ACCOUNT_URL, {
      id: account.id,
    });
    return response?.data?.content;
  };

  const saveAccountsMutation = useMutation({
    mutationFn: (data: SaveCoursesProps) => saveAccounts(data),
    mutationKey: ["save-account"],
    onSuccess: () => {
      queryClient.invalidateQueries(["accounts"]);
      queryClient.invalidateQueries(["courses_lms"]);
      toast.success({
        message: t("toasts.success.save-item", { item: t("accounts.title") }),
      });
    },
  });

  const saveCurrentAccountMutation = useMutation({
    mutationFn: (data: Account) => saveAccount(data),
    mutationKey: ["save-current-account"],
    onSuccess: () => {
      queryClient.clear();
      toast.success({
        message: t("toasts.success.save-item", { item: t("accounts.title") }),
      });
    },
  });

  return {
    saveAccountsMutation,
    saveCurrentAccountMutation,
  };
}
