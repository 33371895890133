import { useQuery } from "@tanstack/react-query";
import { useQuiz } from "./useQuiz";

interface IUseGetQuizProps {
  quizId: any;
  courseId: any;
  quizType: any;
}

export function useGetQuiz({ quizId, courseId, quizType }: IUseGetQuizProps) {
  const { getQuiz } = useQuiz();

  return useQuery({
    queryKey: ["quizzes", { quizId, courseId, quizType }],
    queryFn: () => getQuiz({ quizId, courseId, quizType }),
    staleTime: 1000 * 60 * 1,
    enabled: !!quizId,
    refetchOnWindowFocus: false,
  });
}
