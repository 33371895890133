import { css, Theme } from "@mui/material";

interface IGetBackgroundColorByIncidentLevelProps {
  incidentLevel: string;
  theme: Theme;
}

type GetIncidentLevels = (t: any) => string[];

export const getIncidentLevels: GetIncidentLevels = (t) => [
  t("components.incident_level.incidents.invalid"),
  t("components.incident_level.incidents.processing"),
  t("components.incident_level.incidents.low"),
  t("components.incident_level.incidents.medium"),
  t("components.incident_level.incidents.high"),
];

export const getBackgroundColorByIncidentLevel = ({
  incidentLevel,
  theme,
}: IGetBackgroundColorByIncidentLevelProps) => {
  switch (incidentLevel) {
    case "High":
      return css`
        background-color: ${theme.customColors.tertiary[500]};
      `;
    case "Medium":
      return css`
        background-color: ${theme.customColors.orange[500]};
      `;
    case "Low":
      return css`
        background-color: ${theme.customColors.green[500]};
      `;
    default:
      return css`
        background-color: ${theme.customColors.gray[500]};
      `;
  }
};
