import { styled, css } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

interface ButtonProps {
  side: "left" | "right";
  active: boolean;
}

export const Button: ComponentType<
  HTMLAttributes<HTMLButtonElement> & ButtonProps
> = styled("button", {
  shouldForwardProp: (prop) => prop !== "side" && prop !== "active",
})<ButtonProps>`
  width: 4.875rem;
  height: 1.625rem;
  font-size: 0.75rem;
  outline: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.customColors.common.white};
  border: 1px solid ${(props) => props.theme.customColors.primary[200]};
  border-radius: ${(props) =>
    props.side === "right" ? "0 1.25rem 1.25rem 0" : "1.25rem 0 0 1.25rem"};

  ${({ side, active, theme }) => css`
    border-left: ${side === "right" ? "none" : ""};
    border-right: ${side === "left" ? "none" : ""};
    background-color: ${active
      ? theme.customColors.primary[100]
      : theme.customColors.common.white};
  `}
`;

export const Separator: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  width: 0.0625rem;
  height: 1.625rem;
  background-color: ${(props) => props.theme.customColors.primary[200]};
`;
