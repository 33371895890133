import { Skeleton } from "@edusynch/edusynch-ui";
import { Stack } from "@mui/material";

export function OptionsPlaceholder() {
  return (
    <Stack spacing={2} p={2}>
      <Skeleton width="100%" height="2rem" />
      <Skeleton width="100%" height="2rem" />
      <Skeleton width="100%" height="2rem" />
    </Stack>
  );
}
