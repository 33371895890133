import React, { useCallback, useEffect, useState } from "react";
import { AuthService } from "./AuthService";
import { NextRouter, useRouter } from "next/router";
import { IUserData } from "@/types/IUserData";
import { TokenService } from "@/services/TokenService";
import { ERROR_ROUTE } from "@/constants/app-routes";
import {
  INDEX_ROUTE,
  DASHBOARD_ROUTE,
  SETUP_ROUTE,
} from "@/constants/app-routes";

export function withSession(fn: (arg0: any) => any) {
  return async (ctx: any) => {
    try {
      const session = await AuthService.getSession(ctx, null);
      const modifiedCtx = {
        ...ctx,
        req: {
          ...ctx.req,
          session,
        },
      };
      return fn(modifiedCtx);
    } catch (err) {
      return {
        redirect: {
          permanent: false,
          destination: `${ERROR_ROUTE}?type=server-error&message=${JSON.stringify(
            err
          )}`,
        },
      };
    }
  };
}

const sanatizeUrl = (url: string) => url.replace("//", "/");

export const getPathToRedirect = (
  newUser: boolean,
  route: string,
  hash: string
) => {
  const mainPathRedirect = newUser ? SETUP_ROUTE : DASHBOARD_ROUTE;

  // index
  if (INDEX_ROUTE === route) {
    return sanatizeUrl(`/${hash}/${mainPathRedirect}`);
    // settings
  } else if (![SETUP_ROUTE].includes(route) && newUser) {
    return sanatizeUrl(`/${hash}/${SETUP_ROUTE}`);
  }
  return null;
};

export const redirectAfterGetSession = (
  newUser: boolean,
  hash: string,
  router: NextRouter
) => {
  const route = getPathToRedirect(newUser, router.asPath, hash);
  if (route && route !== router.asPath) {
    router.push(route);
  }
};

export function useSession() {
  const [session, setSession] = useState<IUserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const router = useRouter();

  const logout = useCallback(() => {
    const hash = router.query.hash as string;
    // Clear the token from state
    setSession(null);
    if (hash) TokenService.remove(hash);
  }, [router]);

  useEffect(() => {
    if (!router.query.hash || router.asPath.includes(ERROR_ROUTE)) return;

    AuthService.getSession(null, router)
      .then((session: IUserData | null) => {
        if (!session) return;
        setSession(session);
        redirectAfterGetSession(
          !session?.setup_completed,
          router.query.hash as string,
          router
        );
      })
      .catch((error: React.SetStateAction<null>) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [router]);

  return {
    logout,
    session,
    setSession,
    error,
    loading,
  };
}
