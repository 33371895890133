import { Account } from "@/hooks/accounts";
import { Input, Typography } from "@edusynch/edusynch-ui";
import { IconButton, InputBase, Stack, useTheme } from "@mui/material";
import { Empty } from "../Empty";
import { Tooltip } from "../Tooltip";
import { textEllipsis } from "@/utils";
import { Badge } from "../Badge";
import { HoverIconButton } from "../HoverIconButton";
import { Icon } from "../Icon";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "next-i18next";
import flattenMap from "@/utils/flatmap.utils";
import * as S from "./AccountsSelect.styles";
import { MOBILE_SIZE, useMediaQuery } from "@/hooks";

const TRANSLATION_PREFIX = "accounts_select";

export interface AccountsSelectListProps {
  accounts: Account[];
  onAccountSelect: (account: Account) => void;
  onCloseMenu?: () => void;
}

export function AccountsSelectList({
  accounts,
  onAccountSelect,
  onCloseMenu,
}: AccountsSelectListProps) {
  const [hoveringAccountId, setHoveringAccountId] = useState<number | null>(
    null
  );
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);
  const [search, setSearch] = useState<string>("");
  const theme = useTheme();
  const { t } = useTranslation("common");
  const isMobile = useMediaQuery(MOBILE_SIZE);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  const currentAccounts = useMemo(() => {
    const lastAccount = selectedAccounts[selectedAccounts.length - 1];

    if (lastAccount?.sub_account?.length) return lastAccount.sub_account;

    return accounts;
  }, [accounts, selectedAccounts]);

  const currentTitle = useMemo(() => {
    const lastAccount = selectedAccounts[selectedAccounts.length - 1];

    return lastAccount?.name || t(`${TRANSLATION_PREFIX}.title`);
  }, [selectedAccounts]);

  const handleGoBack = () => {
    if (!selectedAccounts.length) {
      return onCloseMenu && onCloseMenu();
    }

    const indexToRemove = selectedAccounts.length - 1;
    setSelectedAccounts((prev) => [...prev.slice(0, indexToRemove)]);
  };

  const handleSeeSubAccounts = (account: Account) => {
    setSelectedAccounts((prev) => prev.concat(account));
  };

  const parsedAccounts = useMemo(
    () =>
      flattenMap<Account, "sub_account">({
        arrayToFlatmap: accounts,
        propName: "sub_account",
        identifier: "id",
      }),
    [accounts]
  );

  const searchSubAccounts = (account: Account) => {
    const searchLower = search.toLowerCase().trim();
    let searchSubAccounts: Account[] = [];

    if (account?.sub_account?.length) {
      searchSubAccounts = account.sub_account.filter((item) =>
        item.name.toLowerCase().trim().includes(searchLower)
      );
    }

    return searchSubAccounts;
  };

  const searchAccounts = parsedAccounts
    .map((account) => {
      const subAccounts = searchSubAccounts(account);
      const searchLower = search.toLowerCase().trim();

      if (account.name.includes(searchLower) || subAccounts.length) {
        return {
          id: account.id,
          name: account.name,
          sub_account: subAccounts,
        };
      }

      return;
    })
    .filter((item) => Boolean(item));

  return (
    <>
      <S.OptionHeader>
        {(isMobile || !!selectedAccounts.length) && (
          <IconButton onClick={handleGoBack}>
            <Icon
              name="chevron-left"
              width={16}
              height={16}
              variant="primary"
            />
          </IconButton>
        )}
        <Typography color={isMobile ? "primary" : "text"} as="h6">
          {currentTitle}
        </Typography>
      </S.OptionHeader>
      {isMobile ? (
        <Stack mt={2}>
          <Input
            startIcon={<Icon name="search" />}
            size="small"
            fullWidth
            placeholder={t(`common_words.search`)}
            value={search}
            onChange={({ target }) => setSearch(target.value)}
            sx={{
              backgroundColor: "white",
            }}
          />
        </Stack>
      ) : (
        <S.SearchContainer>
          <Icon name="search" />
          <InputBase
            sx={{
              height: "2.5rem",
              padding: 2,
              fontSize: 14,
              color: theme.customColors.common.text,
            }}
            fullWidth
            placeholder={t(`common_words.search`)}
            inputProps={{ "aria-label": "search filters" }}
            value={search}
            onChange={handleSearchChange}
          />
        </S.SearchContainer>
      )}
      {!!search ? (
        <Stack
          p={2}
          spacing={3}
          maxHeight={200}
          overflow="auto"
          sx={{
            scrollbarColor: theme.customColors.gray[200],
            "&::-webkit-scrollbar": {
              width: "0.25rem",
            },
            "&::-webkit-scrollbar-track": {
              background: theme.customColors.gray[100],
            },
            "&::-webkit-scrollbar-thumb": {
              background: theme.customColors.gray[300],
              borderRadius: "0.5rem",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: theme.customColors.gray[500],
            },
          }}
        >
          {!!searchAccounts?.length ? (
            searchAccounts?.map((account: any) => (
              <Stack key={account.id} spacing={1}>
                <span onClick={() => onAccountSelect(account)}>
                  <Typography style={{ cursor: "pointer" }}>
                    {account?.name || ""}
                  </Typography>
                </span>
                {account?.sub_account?.map((subAccount: any) => (
                  <span
                    key={subAccount.id}
                    onClick={() => onAccountSelect(subAccount)}
                  >
                    <Typography color="text" style={{ cursor: "pointer" }}>
                      {subAccount?.name || ""}
                    </Typography>
                  </span>
                ))}
              </Stack>
            ))
          ) : (
            <Empty />
          )}
        </Stack>
      ) : (
        <S.Options>
          {!!currentAccounts.length ? (
            currentAccounts.map((account) => (
              <S.Option
                key={account.id}
                onMouseEnter={() => setHoveringAccountId(account.id)}
                onMouseLeave={() => setHoveringAccountId(null)}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Tooltip
                    disableHoverListener={account.name.length < 25}
                    arrow
                    title={
                      <Typography color="white">{account.name}</Typography>
                    }
                  >
                    <span>
                      <Typography color="text">
                        {textEllipsis({ text: account.name, maxLength: 25 })}
                      </Typography>
                    </span>
                  </Tooltip>
                  {!!account.sub_account?.length && (
                    <Badge
                      label={account.sub_account?.length}
                      secondary={isMobile}
                    />
                  )}
                </Stack>
                {(isMobile || hoveringAccountId === account.id) && (
                  <Stack direction="row" spacing={1}>
                    <Tooltip
                      arrow
                      title={
                        <Typography color="white">
                          {t(`${TRANSLATION_PREFIX}.select_subaccount`)}
                        </Typography>
                      }
                    >
                      <span>
                        <HoverIconButton
                          data-testid="select-account-btn"
                          onClick={() => onAccountSelect(account)}
                        >
                          <Icon name="check" width={16} height={16} />
                        </HoverIconButton>
                      </span>
                    </Tooltip>
                    {!!account?.sub_account?.length && (
                      <Tooltip
                        arrow
                        title={
                          <Typography color="white">
                            {t(`${TRANSLATION_PREFIX}.see_subaccounts`)}
                          </Typography>
                        }
                      >
                        <span>
                          <HoverIconButton
                            data-testid="see-subaccounts-btn"
                            onClick={() => handleSeeSubAccounts(account)}
                          >
                            <Icon name="chevron-right" width={16} height={16} />
                          </HoverIconButton>
                        </span>
                      </Tooltip>
                    )}
                  </Stack>
                )}
              </S.Option>
            ))
          ) : (
            <Empty />
          )}
        </S.Options>
      )}
    </>
  );
}
