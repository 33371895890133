import { useState, useEffect } from "react";

export const MOBILE_SIZE = "(max-width: 969.98px)";
export const MOBILE_SMALL_SIZE = "(max-width: 599.98px)";
export const TABLET_SIZE = "(max-width: 1023.98px)";
export const DESKTOP_SMALL_SIZE = "(max-width: 1200px)";
export const DESKTOP_SIZE = "(min-width: 1024px)";

const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") {
      return setMatches(false);
    }

    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setMatches(media.matches);
    };

    media.addListener(listener);

    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
};

export { useMediaQuery };
