import { changesGlobalClientToken } from "@/api/client";
import { ServerStorageService } from "@/services/Storages";

type SaveProps = {
  token: string;
  hash: string;
};

export const TokenService = {
  get: async (hash: string) => {
    const token = (await ServerStorageService.get(hash)) as string;
    return token;
  },

  save: async ({ hash, token }: SaveProps) => {
    const saved = await ServerStorageService.save({ hash, token });
    changesGlobalClientToken(token);
    return saved;
  },

  remove: async (hash: string) => {
    const remove = await ServerStorageService.remove(hash);
    return remove;
  },
};
