import { ConfirmTooltip as EduSynchConfirmTooltip } from "@edusynch/edusynch-ui";
import { ReactNode } from "react";
import { Icon } from "../Icon";

interface IConfirmTooltipProps {
  subtitle: string;
  onConfirm: () => void;
  children: ReactNode;
}

export function ConfirmTooltip({
  subtitle,
  onConfirm,
  children,
}: IConfirmTooltipProps) {
  return (
    <EduSynchConfirmTooltip
      exclamationIcon={<Icon name="exclamation" variant="primary" />}
      subtitle={subtitle}
      onConfirm={onConfirm}
    >
      {children}
    </EduSynchConfirmTooltip>
  );
}
