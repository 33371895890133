import { styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

export const Container: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  width: 100%;

  .MuiSlider-root {
    height: 0.4375rem;
  }

  .MuiSlider-rail {
    background-color: ${(props) => props.theme.customColors.secondary[400]};
    opacity: 1;
  }

  .MuiSlider-track {
    background-color: ${(props) => props.theme.customColors.gray[200]};
  }

  .MuiSlider-thumb {
    display: none;
  }
`;
