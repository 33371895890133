export type IncidentType = keyof ReturnType<typeof getIncidents>;

export const getIncidents = (t: any): Record<string, string> => {
  return {
    MOVE_FOCUS: t("quizzes.sessions.incidents.moved_focus"),
    MULTIPLE_CAMS: t("quizzes.sessions.incidents.multiple_cameras"),
    MULTIPLE_DISPLAYS: t("quizzes.sessions.incidents.multiple_screens"),
    MULTIPLE_DISPLAYS_DETECTED: t(
      "quizzes.sessions.incidents.multiple_screens"
    ),
    MULTIPLE_FACES: t("quizzes.sessions.incidents.multiple_people"),
    NEW_TAB: t("quizzes.sessions.incidents.new_tab_window"),
    MULTIPLE_FACES_DETECTED: t("quizzes.sessions.incidents.multiple_people"),
    NO_FACE_DETECTED: t("quizzes.sessions.incidents.no_user"),
    NEW_TAB_WINDOW: t("quizzes.sessions.incidents.new_tab_window"),
    START_STREAMING: t("quizzes.sessions.incidents.video_streaming_started"),
    STOP_STREAMING: t("quizzes.sessions.incidents.video_streaming_stopped"),
    MORE_THAN_ONE_CAM: t("quizzes.sessions.incidents.multiple_cameras"),
    CLOSED_WINDOW: t("quizzes.sessions.incidents.closed_browser_via_browser"),
    CLOSED_WINDOW_OR_TAB: t(
      "quizzes.sessions.incidents.closed_browser_via_keyboard"
    ),
    FINISH_SIMULATION: t("quizzes.sessions.incidents.exam_completed"),
    START_SIMULATION: t("quizzes.sessions.incidents.exam_started"),
    TEST_PAUSED: t("quizzes.sessions.incidents.exam_paused"),
    TEST_RESUMED: t("quizzes.sessions.incidents.exam_resumed"),
    OFFLINE: t("quizzes.sessions.incidents.lost_connection"),
    ONLINE: t("quizzes.sessions.incidents.connection_established"),
    ONLINE_SERVER: t(
      "quizzes.sessions.incidents.connection_established_server"
    ),
    OFFLINE_SERVER: t("quizzes.sessions.incidents.connection_lost_server"),
    SIMULATION_TIMEOUT: t("quizzes.sessions.incidents.simulation_timed_out"),
    SIMULATION_EXPIRED: t("quizzes.sessions.incidents.simulation_expired"),
    REFRESH_PAGE: t("quizzes.sessions.incidents.refreshed_page_via_browser"),
    ROOMSCAN_RESUMED: t("quizzes.sessions.incidents.room_scan_restarted"),
    ROOMSCAN_FAILED_MORE_THAN_TWICE_AND_RESUMED: t(
      "quizzes.sessions.incidents.2_or_more_errors_room_scan"
    ),
    ROOMSCAN_STARTED: t("quizzes.sessions.incidents.random_room_scan_started"),
    ROOMSCAN_LOCKED: t("quizzes.sessions.incidents.user_offiline_roomscan"),
    ROOMSCAN_UNLOCKED: t(
      "quizzes.sessions.incidents.user_reconnected_roomscan"
    ),
    SUSPENDED_CAMERA: t("quizzes.sessions.incidents.user_suspended_camera"),
    GAZE_DETECTION: t("quizzes.sessions.incidents.user_looked_away"),
    SESSION_FAILED_INFO: t("quizzes.sessions.incidents.session_not_saved"),
    OPEN_DEVTOOLS: t("quizzes.sessions.incidents.opened_the_dev_tools"),
    ERROR_CHOOSE_WINDOW: t(
      "quizzes.sessions.incidents.error_with_share_screen"
    ),
    ERROR_FINISH_SESSION: t(
      "quizzes.sessions.incidents.error_finalizing_session"
    ),
    ERROR_APPLY_PHOTOS: t("quizzes.sessions.incidents.error_with_photos"),
    ERROR_APPLY_VIDEO: t("quizzes.sessions.incidents.error_with_video"),
    NEW_ANON_TAB_WINDOW: t(
      "quizzes.sessions.incidents.user_open_tab_incognito"
    ),
  };
};
