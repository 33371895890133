import { styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

export const Empty: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  text-align: center;
  padding: 1rem;

  svg {
    padding-bottom: 1rem;
  }

  span {
    display: block;
  }
`;

export const EmptyDescription: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  text-align: left;
  ${(props) => props.theme.customTypography.small};
  color: ${(props) => props.theme.customColors.common.text};

  span {
    text-align: left;
    display: inline;
    ${(props) => props.theme.customTypography.default};
    color: ${(props) => props.theme.customColors.secondary[500]};
    font-weight: bold;
  }
`;
