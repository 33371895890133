import { Typography } from "@edusynch/edusynch-ui";
import { Stack } from "@mui/material";

export function Footer() {
  return (
    <Stack mt={{ xs: 3, sm: 10 }} mb={{ xs: 3, sm: 4 }} textAlign="center">
      <Typography color="gray">
        Copyright © Dokimi, Inc. All rights reserved
      </Typography>
    </Stack>
  );
}
