import { TSize, TVariant, getVariantColor } from "@edusynch/edusynch-ui";
import * as IconsList from "@edusynch/edusynch-svg-icons";
import { convertIconNameToPascalCase } from "./Icon.utils";
import { useTheme } from "@mui/material";

interface IIConProps {
  name: string;
  size?: TSize;
  variant?: TVariant;
  width?: number;
  height?: number;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export function Icon({ name, size, variant, ...props }: IIConProps) {
  const iconName = convertIconNameToPascalCase(name);
  const IconComponent = IconsList[iconName as keyof typeof IconsList];
  const theme = useTheme();

  if (!iconName || !IconComponent) {
    return null;
  }

  return (
    <IconComponent
      {...props}
      type={size as any}
      color={getVariantColor(variant, theme)}
      className={`edu-icon-${name}`}
      data-testid="icon"
    />
  );
}
