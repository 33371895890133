import { useMemo } from "react";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";

const ADMIN_PATH = "admin";
const TEACHER_PATH = "teacher";

export function useUserLocation() {
  const pathname = usePathname();
  const router = useRouter();

  const baseRoute = useMemo(() => {
    const hash = router?.query?.hash as string;
    const route = pathname?.includes("teacher") ? TEACHER_PATH : ADMIN_PATH;
    return `/${hash}/${route}`;
  }, [pathname, router?.query?.hash]);

  const isAdmin = pathname?.includes("admin");

  return {
    baseRoute,
    isAdmin,
  };
}
