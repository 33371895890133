import { SUBMISSIONS_LIST_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "../useApiClient";
import { toast } from "@/components/common/Toast";
import { Quiz } from "@/hooks/useQuizzes";
import { Course } from "@/hooks/useCourses";
import { TDeviceInfo } from "./useSubmissionAnswers";

export type TStudentSubmission = {
  id: number;
  name: string;
  email: string;
  avatar_url: string;
  avatar: string;
};

export type TEvaluatorSubmission = {
  id: number;
  name: string;
  email: string;
  avatar_url: string;
  avatar: string;
};

interface GetSubmissionsProps {
  page?: number;
  perPage?: number;
  search?: string;
  courseId?: number | null;
  quizId?: number | null;
  aiScore?: number | null;
  startDate: string;
  endDate: string;
  sortBy?: string;
  sort?: string;
}

type TTracking = {
  city: string;
  country: string;
};

export type Submission = {
  id: number;
  quiz: Quiz;
  course: Course;
  student: TStudentSubmission;
  tracking: TTracking;
  device_info: TDeviceInfo;
  submitted_at: string;
  active: boolean;
  ai_score: string;
  created_at: string;
};

type GetSubmissionsResponse = {
  submissions: Submission[];
  total: number;
};

interface UseSubmissionsProps {
  page?: number;
  perPage?: number;
  search?: string;
  courseId?: number | null;
  quizId?: number | null;
  aiScore?: number | null;
  sortBy?: string;
  sort?: string;
  enabled?: boolean;
  startDate: string;
  endDate: string;
}

export function useSubmissions({
  page,
  perPage,
  search,
  courseId,
  quizId,
  aiScore,
  sortBy,
  sort,
  startDate,
  endDate,
  enabled = true,
}: UseSubmissionsProps) {
  const { api } = useApiClient();

  const getSubmissions = async ({
    page,
    perPage,
    search,
    courseId,
    quizId,
    aiScore,
    sortBy,
    sort,
    startDate,
    endDate,
  }: GetSubmissionsProps): Promise<GetSubmissionsResponse> => {
    const aiScoreFilter =
      aiScore === 3 ? "true" : aiScore === 2 ? "false" : null;
    const response = await api.get(SUBMISSIONS_LIST_URL, {
      params: {
        course_lms_id: courseId || undefined,
        quiz_id: quizId || undefined,
        is_ai_score: aiScoreFilter || undefined,
        search: search || undefined,
        page: page || undefined,
        paginates_per: perPage || undefined,
        sort: sort || undefined,
        sort_by: sortBy || undefined,
        start_date: startDate,
        end_date: endDate,
      },
    });

    return {
      submissions: response.data.content.submissions,
      total: response.data.content.total_items,
    };
  };

  return useQuery({
    queryKey: [
      "submissions",
      {
        page,
        perPage,
        search,
        courseId,
        quizId,
        aiScore,
        sortBy,
        sort,
        startDate,
        endDate,
      },
    ],
    queryFn: () =>
      getSubmissions({
        page,
        perPage,
        search,
        courseId,
        quizId,
        aiScore,
        sortBy,
        sort,
        startDate,
        endDate,
      }),
    staleTime: 1000 * 60 * 1,
    enabled,
    onError: (error: any) => {
      toast.error({ message: error?.message });
    },
  });
}
