import axios from "axios";
import {
  INVALID_TOKEN_CODE,
  EXPIRED_TOKEN_CODE,
} from "@/constants/error-codes";
import { TokenService } from "@/services/TokenService";
import { ERROR_ROUTE } from "@/constants/app-routes";

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "json",
  },
});

const cancelInterceptor = api.interceptors.request.use((config) => {
  const controller = new AbortController();
  const cfg = {
    ...config,
    signal: controller.signal,
  };

  if (!config.headers.authorization) {
    controller.abort();
  }

  return cfg;
});

let globalToken: string | null = null;

export const changesGlobalClientToken = (token: string) => {
  globalToken = token;
};

export const setupInterceptors = (
  hash: string,
  routerPushFn: (path: string) => void
) => {
  api.interceptors.request.eject(cancelInterceptor);

  api.interceptors.request.use(async (config) => {
    const controller = new AbortController();

    if (!hash) {
      controller.abort();
      return {
        ...config,
        signal: controller.signal,
      };
    }

    globalToken = globalToken ?? (await TokenService.get(hash));

    if (globalToken) {
      config.headers.authorization = `Bearer ${globalToken}`;
    } else {
      controller.abort();
    }

    return {
      ...config,
      signal: controller.signal,
    };
  });

  api.interceptors.response.use(
    (result) => result,
    (error) => {
      if (error?.response?.status === 500) {
        return Promise.reject(error);
      }
      if (error.response?.status === INVALID_TOKEN_CODE) {
        routerPushFn(`${ERROR_ROUTE}?type=invalid-token`);
      }
      if (error.response?.status === EXPIRED_TOKEN_CODE) {
        routerPushFn(`${ERROR_ROUTE}?type=expired-token`);
      }
      return Promise.reject(error);
    }
  );
};
