import {
  QUIZZES_CREATE_URL,
  QUIZZES_DELETE_URL,
  QUIZZES_UPDATE_URL,
  QUIZ_BY_ID_URL,
  QUIZ_UPDATE_ACTIVE_URL,
  SIGN_THIRD_PARTY_QUIZ_TOKEN_URL,
} from "@/constants/api-routes";
import { useAuth } from "@/providers/AuthProvider";
import { TokenService } from "@/services/TokenService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useRouter } from "next/router";
import { useApiClient } from "./useApiClient";
import { ThirdPartyQuiz } from "./useThirdPartyQuizzes";
import { decode, encode } from "@/utils";

export const THIRD_PARTY_QUIZ_TYPE = "third_party";

interface GetThirdPartyQuizProps {
  quizId: number;
}

type GetThirdPartyQuizResponse = {
  quiz: ThirdPartyQuiz;
};

export interface ICreateThirdPartyQuiz {
  name: string;
  date_start: Date;
  end_date: Date;
  password: string;
  platform: string;
  enable_simulation: boolean;
  enable_photo_of_user: boolean;
  enable_photo_of_id: boolean;
  enable_room_scan: boolean;
  enable_photo_of_user_during_test: boolean;
  enable_photo_of_screen_during_test: boolean;
  enable_user_screen_recording: boolean;
  enable_user_video_recording: boolean;
  enable_user_audio_recording: boolean;
  enable_random_room_scan: boolean;
  disable_screenshot: boolean;
  enable_basic_calculator: boolean;
  enable_scientific_calculator: boolean;
  disable_copy_paste: boolean;
  student_instructions: string;
  proctor_guidelines: string;
  restrict_on_dates: number;
  course_id: number;
}

export interface IUpdateThirdPartyQuiz {
  id: string;
  name: string;
  date_start: string;
  end_date: string;
  password: string;
  platform: string;
  enable_simulation: boolean;
  enable_photo_of_user: boolean;
  enable_photo_of_id: boolean;
  enable_room_scan: boolean;
  enable_photo_of_user_during_test: boolean;
  enable_photo_of_screen_during_test: boolean;
  enable_user_screen_recording: boolean;
  enable_user_video_recording: boolean;
  enable_user_audio_recording: boolean;
  enable_random_room_scan: boolean;
  disable_screenshot: boolean;
  enable_basic_calculator: boolean;
  enable_scientific_calculator: boolean;
  disable_copy_paste: boolean;
  student_instructions: string;
  proctor_guidelines: string;
  restrict_on_dates: number;
  course_id: number;
}

export function useThirdPartyQuiz() {
  const { api } = useApiClient();
  const queryClient = useQueryClient();
  const router = useRouter();
  const { session } = useAuth();

  const courseId = router?.query?.course_id;

  const getThirdPartyQuiz = async ({
    quizId,
  }: GetThirdPartyQuizProps): Promise<GetThirdPartyQuizResponse> => {
    const response = await api.get(QUIZ_BY_ID_URL, {
      params: {
        id: quizId,
        course_id: router.query.course_id,
        quiz_type: router.query.quiz_type,
      },
    });

    const quiz = response.data.content;
    const { id, ...proctoring_settings } = quiz.proctoring_settings;

    return {
      quiz: {
        ...quiz,
        ...proctoring_settings,
        password: quiz?.password ? decode(quiz.password)?.password || "" : "",
        restrict_on_dates: quiz.restrict_on_dates ? 1 : 0,
      },
    };
  };

  const createThirdPartyQuiz = useMutation({
    mutationFn: async (quiz: ICreateThirdPartyQuiz) => {
      try {
        const { course_id, restrict_on_dates } = quiz;
        const payload = {
          ...quiz,
          password: encode({ password: quiz.password }),
          active: true,
          course_id: courseId || course_id,
          quiz_type: THIRD_PARTY_QUIZ_TYPE,
          restrict_on_dates: !!Number(restrict_on_dates),
        };

        const response = await api.post(QUIZZES_CREATE_URL, payload);

        return response;
      } catch (error) {
        // Handle any error that occurs during the API calls
        console.error("Error creating third party quiz:", error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["third_party_quizzes"]);
      queryClient.invalidateQueries(["quizzes"]);
    },
  });

  const updateThirdPartyQuiz = useMutation({
    mutationFn: async (quiz: IUpdateThirdPartyQuiz) => {
      const response = await api.put(QUIZZES_UPDATE_URL, {
        ...quiz,
        password: encode({ password: quiz.password }),
        course_id: courseId || quiz.course_id,
        quiz_type: THIRD_PARTY_QUIZ_TYPE,
        restrict_on_dates: !!Number(quiz.restrict_on_dates),
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["third_party_quizzes"]);
      queryClient.invalidateQueries(["third_party_quiz"]);
      queryClient.invalidateQueries(["quizzes"]);
      queryClient.invalidateQueries(["quiz"]);
    },
  });

  const updateThirdPartyQuizActive = useMutation({
    mutationFn: async (quiz: ThirdPartyQuiz) => {
      const response = await api.post(QUIZ_UPDATE_ACTIVE_URL, {
        quiz_id: quiz.id,
        active: quiz.active,
        course_id: quiz.course.id,
        quiz_type: THIRD_PARTY_QUIZ_TYPE,
        all: false,
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["third_party_quizzes"]);
      queryClient.invalidateQueries(["third_party_quiz"]);
      queryClient.invalidateQueries(["quizzes"]);
      queryClient.invalidateQueries(["quiz"]);
    },
  });

  const deleteThirdPartyQuiz = useMutation({
    mutationFn: async (quizId: number) => {
      const response = await api.delete(QUIZZES_DELETE_URL, {
        params: {
          id: quizId,
        },
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["third_party_quizzes"]);
      queryClient.invalidateQueries(["third_party_quiz"]);
      queryClient.invalidateQueries(["quizzes"]);
      queryClient.invalidateQueries(["quiz"]);
    },
  });

  const launchThirdPartyQuiz = useMutation({
    mutationFn: async (quiz: ThirdPartyQuiz) => {
      const hash = router.query.hash as string;
      const userToken = await TokenService.get(hash);

      const response = await axios.post(SIGN_THIRD_PARTY_QUIZ_TOKEN_URL, {
        student_token: userToken,
        provider: THIRD_PARTY_QUIZ_TYPE,
        exam_id: quiz.id,
        student_id: session?.user_id,
        course_id: quiz.course.id,
        url: quiz.url,
      });

      return response;
    },
  });

  return {
    getThirdPartyQuiz,
    createThirdPartyQuiz,
    updateThirdPartyQuiz,
    deleteThirdPartyQuiz,
    updateThirdPartyQuizActive,
    launchThirdPartyQuiz,
  };
}
