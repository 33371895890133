import { CloseButtonProps } from "react-toastify";
import { ToastContainerProps } from "react-toastify/dist/types";
import { Box, IconButton } from "@mui/material";
import { IconRoundBox, TVariant } from "@edusynch/edusynch-ui";
import { Icon } from "@/components";
import * as S from "./styles";

interface IToastTemplateAdapterProps {
  message?: string;
  submessage?: string;
  variation: ToastVariationsEnum;
}

type TMessages = {
  [key: string]: string;
};

export enum ToastVariationsEnum {
  error = "error",
  warning = "warning",
  success = "success",
  info = "info",
}

const defaultMessageByVariation: TMessages = {
  error: "Something went wrong",
  warning: "Something went wrong",
  success: "Completed successfully",
  info: "Info",
};

const getIconName = (name: string) => {
  return (
    {
      error: "close",
      warning: "warning",
      success: "check",
      info: "info",
    }[name] || "info"
  );
};

const getIconVariation = (name: string): TVariant => {
  const variation =
    {
      error: "tertiary",
      warning: "orange",
      success: "green",
      info: "primary",
    }[name] || "primary";

  return variation as TVariant;
};

export function ToastCloseButton({ closeToast }: CloseButtonProps) {
  return (
    <IconButton
      size="small"
      sx={{ width: 32, height: 32 }}
      onClick={(e) => closeToast(e)}
    >
      <Icon name="close" variant="text" size="small" />
    </IconButton>
  );
}

function ToastIcon({ name }: { name: string }) {
  const icon = getIconName(name);
  const iconVariation = getIconVariation(name);

  return (
    <IconRoundBox
      icon={<Icon name={icon} variant={iconVariation} size="small" />}
      variant={iconVariation}
      size="medium"
    />
  );
}

export const ToastTemplate = ({
  message,
  submessage,
  variation,
}: ToastContainerProps & {
  message: string;
  submessage: string;
  variation: ToastVariationsEnum;
}) => {
  return (
    <Box display="flex" alignItems="center">
      <ToastIcon name={variation} />
      <Box ml={2}>
        <b>{message}</b>
        <S.Body>{submessage}</S.Body>
      </Box>
    </Box>
  );
};

export const TemplateAdapter = ({
  message,
  submessage,
  variation,
}: IToastTemplateAdapterProps) => {
  const finalMessage = message ?? defaultMessageByVariation[variation];
  const finalSubmessage = submessage ?? "";

  return (
    <ToastTemplate
      message={finalMessage as string}
      submessage={finalSubmessage}
      variation={variation}
    />
  );
};
