import { ToastContainer } from "react-toastify";
import { ToastCloseButton } from "./ToastTemplates";
import { styled } from "@mui/material";
import { cssTransition } from "react-toastify";
import "animate.css";

const fadeInOut = cssTransition({
  enter: "animate__animated animate__fadeInUpBig",
  exit: "animate__animated animate__fadeOut",
});

// ToastContainer has a theme prop, so props.theme will not work here
const WrappedToastContainer = (props: any) => (
  <div {...props}>
    <ToastContainer
      autoClose={5000}
      position="bottom-right"
      newestOnTop
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      draggable={false}
      icon={false}
      closeButton={ToastCloseButton}
      transition={fadeInOut}
      hideProgressBar={true}
      {...props}
    />
  </div>
);

export const ToastContainerStyled: any = styled(WrappedToastContainer)`
  --toastify-toast-width: 400px;

  .Toastify__toast {
    align-items: center;
    border-left: 3px solid ${(props) => props.theme.customColors.primary[500]};
    font-family: inherit;
    box-shadow: 0 4px 8px 0 rgba(49, 49, 49, 0.15);
    min-height: 5rem;

    b {
      display: block;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    &.Toastify__toast--error {
      border-left-color: ${(props) => props.theme.customColors.tertiary[500]};
      b {
        color: ${(props) => props.theme.customColors.tertiary[500]};
      }
    }
    &.Toastify__toast--warning {
      border-left-color: ${(props) => props.theme.customColors.orange[500]};
      b {
        color: ${(props) => props.theme.customColors.orange[500]};
      }
    }
    &.Toastify__toast--success {
      border-left-color: ${(props) => props.theme.customColors.green[500]};
      b {
        color: ${(props) => props.theme.customColors.green[500]};
      }
    }
    &.Toastify__toast--info {
      border-left-color: ${(props) => props.theme.customColors.primary[500]};
      b {
        color: ${(props) => props.theme.customColors.primary[500]};
      }
    }
  }
`;
