import { MOBILE_SIZE } from "@/hooks";
import { css, styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

export const Container: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  max-width: 1200px;
  margin: 2.5rem auto;
`;

export const Header: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "header"
)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 3.5rem;

  @media ${MOBILE_SIZE} {
    gap: 1.5rem;
  }
`;

interface LinksProps {
  hasAccounts: boolean;
}

export const Links: ComponentType<
  HTMLAttributes<HTMLUListElement> & LinksProps
> = styled("ul", {
  shouldForwardProp: (prop) => prop !== "hasAccounts",
})<LinksProps>`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;

  @media ${MOBILE_SIZE} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    order: 2;

    li {
      width: 100%;
      border-bottom: 1px solid
        ${(props) => props.theme.customColors.primary[200]};
      padding-bottom: 1rem;

      ${({ hasAccounts }) =>
        !hasAccounts &&
        css`
          &:last-of-type {
            padding-bottom: 0;
            border-bottom: none;
          }
        `};
    }
  }
`;

export const RightContent: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;

  @media ${MOBILE_SIZE} {
    order: 1;
  }
`;

export const ShowMenuButton: ComponentType<
  HTMLAttributes<HTMLButtonElement>
> = styled("button")`
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  background-color: ${(props) => props.theme.customColors.primary[200]};
  cursor: pointer;
`;
