import Head from "next/head";
import type { AppProps } from "next/app";

import { AuthProvider } from "@/providers/AuthProvider";
import "@/styles/globals.css";
import { MainThemeProvider, createTheme } from "@edusynch/edusynch-ui";
import { CacheProvider, EmotionCache } from "@emotion/react";
import createEmotionCache from "@/config/createEmotionCache";
import { Container, ThemeProvider } from "@mui/material";
import { appWithTranslation } from "next-i18next";
import nextI18NextConfig from "../../next-i18next.config";
import { ToastContainer } from "@/components/common/Toast";
import "react-toastify/dist/ReactToastify.css";
import "@/styles/maintenance.css";

import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import { useEffect, useState } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useRouter } from "next/router";
import { Footer } from "@/components/core/common";
import { ZendeskChat } from "@/components/zendesk-chat";
import { setupInterceptors } from "@/api/client";
import { IS_MAINTENANCE } from "@/utils/maintenance";
import { MAINTENANCE_ROUTE } from "@/constants/app-routes";
import { MaintenanceTopBar } from "@/components/Maintenance/MaintenanceTopBar";

const clientSideEmotionCache = createEmotionCache();
const theme = createTheme({});

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function App({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) {
  const router = useRouter();

  const isMaintenanceRoute = router.asPath.includes(MAINTENANCE_ROUTE);

  useEffect(() => {
    const hash = router?.query?.hash || "";
    if (hash) {
      setupInterceptors(hash as string, router.push);
    }

    if (IS_MAINTENANCE && !isMaintenanceRoute) {
      router.push(MAINTENANCE_ROUTE);
    }
  }, [isMaintenanceRoute, router]);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
        <title>EduSynch LTI</title>
      </Head>
      {!IS_MAINTENANCE && <ZendeskChat />}
      <AuthProvider>
        <CacheProvider value={emotionCache}>
          <MainThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <Hydrate state={pageProps.dehydratedState}>
                  <MaintenanceTopBar />
                  <Container
                    maxWidth={isMaintenanceRoute ? false : "lg"}
                    disableGutters={isMaintenanceRoute}
                  >
                    <Component {...pageProps} />
                    {!isMaintenanceRoute && <Footer />}
                  </Container>
                </Hydrate>
                <ToastContainer />
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
              </QueryClientProvider>
            </ThemeProvider>
          </MainThemeProvider>
        </CacheProvider>
      </AuthProvider>
    </>
  );
}

export default appWithTranslation(App, nextI18NextConfig);
