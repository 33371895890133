import {
  QUIZZES_UPDATE_URL,
  QUIZ_BY_ID_URL,
  QUIZ_DELETE_URL,
  QUIZ_UPDATE_ACTIVE_URL,
} from "@/constants/api-routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";
import { Quiz } from "./useQuizzes";
import { useRouter } from "next/router";
import { encode } from "@/utils";

export const LMS_QUIZ_TYPE = "lms_quiz";

interface GetQuizProps {
  quizId: number;
  courseId: number;
  quizType: string;
}

type GetQuizResponse = {
  quiz: Quiz;
};

export interface UpdateQuizData {
  id: number;
  name?: string;
  instructions?: string;
  date_start?: string;
  end_date?: string;
  password?: string;
  platform?: string;
  enable_simulation?: boolean;
  enable_photo_of_user?: boolean;
  enable_photo_of_id?: boolean;
  enable_room_scan?: boolean;
  enable_photo_of_user_during_test?: boolean;
  enable_photo_of_screen_during_test?: boolean;
  enable_user_screen_recording?: boolean;
  enable_user_video_recording?: boolean;
  enable_user_audio_recording?: boolean;
  enable_random_room_scan?: boolean;
  disable_screenshot?: boolean;
  enable_basic_calculator?: boolean;
  enable_scientific_calculator?: boolean;
  disable_copy_paste?: boolean;
  student_instructions?: string;
  proctor_guidelines?: string;
  restrict_on_dates?: number;
  course_id: number;
  quiz_type?: string;
}

export function useQuiz() {
  const { api } = useApiClient();
  const queryClient = useQueryClient();
  const router = useRouter();

  const getQuiz = async ({
    quizId,
    courseId,
    quizType,
  }: GetQuizProps): Promise<GetQuizResponse> => {
    const response = await api.get(QUIZ_BY_ID_URL, {
      params: {
        id: quizId,
        course_id: courseId,
        quiz_type: quizType,
      },
    });

    return {
      quiz: response.data.content,
    };
  };

  const updateQuizMutation = useMutation({
    mutationFn: async (quiz: UpdateQuizData) => {
      const response = await api.put(QUIZZES_UPDATE_URL, {
        ...quiz,
        password: quiz.password
          ? encode({ password: quiz.password })
          : undefined,
        course_id: quiz.course_id,
        quiz_type: quiz.quiz_type,
        restrict_on_dates: !!Number(quiz.restrict_on_dates),
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["third_party_quizzes"]);
      queryClient.invalidateQueries(["third_party_quiz"]);
      queryClient.invalidateQueries(["quizzes"]);
      queryClient.invalidateQueries(["quiz"]);
    },
  });

  const updateQuizActiveMutation = useMutation({
    mutationFn: async (quiz: Quiz) => {
      const response = await api.post(`${QUIZ_UPDATE_ACTIVE_URL}`, {
        quiz_id: quiz.id,
        active: quiz.active,
        course_id: router.query.course_id,
        quiz_type: quiz.quiz_type,
        all: false,
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["quizzes"]);
      queryClient.invalidateQueries(["quiz"]);
    },
  });

  const enableAllQuizzesMutation = useMutation({
    mutationFn: async (active: boolean) => {
      const response = await api.post(`${QUIZ_UPDATE_ACTIVE_URL}`, {
        active: active,
        course_id: router.query.course_id,
        quiz_type: router.query.quiz_type,
        all: true,
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["quizzes"]);
      queryClient.invalidateQueries(["quiz"]);
    },
  });

  const deleteQuizMutation = useMutation({
    mutationFn: async (quizId: number) => {
      const response = await api.delete(`${QUIZ_DELETE_URL}`, {
        params: {
          id: quizId,
        },
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["quizzes"]);
      queryClient.invalidateQueries(["third_party_quizzes"]);
      queryClient.invalidateQueries(["quiz"]);
    },
  });

  return {
    getQuiz,
    updateQuizActiveMutation,
    updateQuizMutation,
    enableAllQuizzesMutation,
    deleteQuizMutation,
  };
}
