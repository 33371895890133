"use client";

import NextLink from "next/link";
import Image from "next/image";
import { Card } from "@edusynch/edusynch-ui";
import { Link } from "../Link";
import { useTranslation } from "next-i18next";

import { useAuth } from "@/providers/AuthProvider";
import { HoverIconButton, Icon, Tooltip } from "@/components/common";
import { MOBILE_SIZE, useMediaQuery } from "@/hooks";
import HeaderAccountsSelect from "./HeaderAccountsSelect";

import * as S from "./Header.styles";
import { useEffect, useMemo, useState } from "react";
import {
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  Stack,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import { AccountsSelectList } from "@/components/common/AccountsSelect/AccountsSelectList";
import { Account, useAccounts, useAccountsActions } from "@/hooks/accounts";
import flattenMap from "@/utils/flatmap.utils";

type TMenuItem = {
  name: string;
  icon: string;
  href?: string;
};

const MENU_ITEMS = {
  setupDone: [
    { name: "dashboard", icon: "dashboard-second" },
    { name: "students", icon: "user" },
    { name: "courses", icon: "content" },
    { name: "settings", icon: "settings" },
    { name: "widgets", icon: "dashboard-second", href: "dashboard/widgets" },
  ],
  default: [{ name: "setup-instructions", icon: "settings " }],
};

const MenuList = ({
  items,
  hasAccounts = false,
}: {
  items: TMenuItem[];
  hasAccounts?: boolean;
}) => (
  <S.Links hasAccounts={hasAccounts}>
    {items.map((item) => (
      <MenuItem key={item.name} item={item} />
    ))}
  </S.Links>
);

const MenuItem = ({ item }: { item: TMenuItem }) => {
  const { t } = useTranslation("common");
  const isMobile = useMediaQuery(MOBILE_SIZE);
  const router = useRouter();
  const basePath = `/${router.query.hash}`;

  return (
    <li key={item.name}>
      <Link
        href={
          item.name === "settings"
            ? `${basePath}/${item.name}`
            : `${basePath}/admin/${item.href || item.name}`
        }
        menuLink
      >
        {isMobile && <Icon name={item.icon} style={{ marginRight: "1rem" }} />}
        {t(`menu.${item.name}`)}
      </Link>
    </li>
  );
};

function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const [showOtherMenu, setShowOtherMenu] = useState(false);
  const [showAccountsMenu, setShowAccountsMenu] = useState(false);
  const [currentAccount, setCurrentAccount] = useState<any>(null);
  const router = useRouter();
  const { session, setSession } = useAuth();
  const isMobile = useMediaQuery(MOBILE_SIZE);
  const theme = useTheme();
  const { t } = useTranslation("common");

  const accountsQuery = useAccounts({});
  const { saveCurrentAccountMutation } = useAccountsActions();
  const rawAccounts = useMemo(
    () => accountsQuery?.data?.accounts || [],
    [accountsQuery?.data?.accounts]
  );

  const accounts = useMemo(
    () =>
      flattenMap<Account, "sub_account">({
        arrayToFlatmap: rawAccounts,
        propName: "sub_account",
        identifier: "id",
      }),
    [rawAccounts]
  );

  useEffect(() => {
    if (!showMenu) setShowAccountsMenu(false);
  }, [showMenu]);

  useEffect(() => {
    if (currentAccount) return;
    const hasOptions = accounts?.length > 0;
    const hasAccountId = session?.account_id;

    if (hasOptions && hasAccountId) {
      const account = accounts?.find(
        (account) => account.id === session?.account_id
      );
      setCurrentAccount(account);
    } else if (hasOptions) {
      setCurrentAccount(accounts[0]);
    }
  }, [accounts, session]);

  const handleChangeAccount = async (value: any) => {
    const account = accounts?.find((account) => account.id === value.id);

    if (account) {
      const prevAccount = currentAccount;
      setCurrentAccount(account);
      setShowMenu(false);

      try {
        const { token } = await saveCurrentAccountMutation.mutateAsync(account);
        await setSession(token);
        setShowAccountsMenu(false);
      } catch (e: any) {
        setCurrentAccount(prevAccount);
      }
    }
  };

  return (
    <S.Container>
      <Card
        padding={!!accounts?.length || isMobile ? "1rem" : "1.5rem"}
        borderRadius="0.5rem"
        variant="primary"
      >
        <S.Header>
          <NextLink href={`/${router?.query?.hash}/admin/dashboard`}>
            <Image
              src="/logo-eproctoring.svg"
              width={130.56}
              height={32}
              alt="logo eproctoring"
            />
          </NextLink>

          {!isMobile ? (
            <MenuList
              hasAccounts={!!accounts?.length}
              items={
                session?.setup_completed
                  ? MENU_ITEMS.setupDone.filter(
                      (item) => item.name !== "widgets"
                    )
                  : MENU_ITEMS.default
              }
            />
          ) : (
            showMenu && (
              <>
                {showAccountsMenu ? (
                  <Stack width="100%" order={3}>
                    <Stack>
                      <AccountsSelectList
                        accounts={rawAccounts}
                        onAccountSelect={handleChangeAccount}
                        onCloseMenu={() => setShowAccountsMenu(false)}
                      />
                    </Stack>
                  </Stack>
                ) : (
                  <>
                    <MenuList
                      hasAccounts={!!accounts?.length}
                      items={
                        session?.setup_completed
                          ? MENU_ITEMS.setupDone
                          : MENU_ITEMS.default
                      }
                    />
                    {!!accounts?.length && (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        width="100%"
                        order={3}
                        px={2}
                      >
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Icon name="users" />
                          <HeaderAccountsSelect
                            currentAccount={currentAccount}
                            rawAccounts={rawAccounts}
                            onAccountSelect={handleChangeAccount}
                            isLoading={
                              accountsQuery.isLoading ||
                              saveCurrentAccountMutation.isLoading
                            }
                            isError={accountsQuery.isError}
                          />
                        </Stack>
                        {!accountsQuery?.isLoading && (
                          <HoverIconButton
                            onClick={() => setShowAccountsMenu(true)}
                          >
                            <Icon name="chevron-right" />
                          </HoverIconButton>
                        )}
                      </Stack>
                    )}
                  </>
                )}
              </>
            )
          )}

          <S.RightContent>
            {isMobile ? (
              <S.ShowMenuButton onClick={() => setShowMenu(!showMenu)}>
                <Icon
                  name={showMenu ? "arrow-up" : "arrow-down"}
                  size="small"
                  variant="primary"
                />
              </S.ShowMenuButton>
            ) : (
              <>
                {!!rawAccounts?.length && (
                  <Stack direction="row" alignItems="center" spacing={3}>
                    <HeaderAccountsSelect
                      currentAccount={currentAccount}
                      rawAccounts={rawAccounts}
                      onAccountSelect={handleChangeAccount}
                      isLoading={
                        accountsQuery.isLoading ||
                        saveCurrentAccountMutation.isLoading
                      }
                      isError={accountsQuery.isError}
                    />
                    <Divider
                      orientation="vertical"
                      sx={{ height: 30, bgcolor: theme.customColors.gray[200] }}
                    />
                  </Stack>
                )}
                <ClickAwayListener onClickAway={() => setShowOtherMenu(false)}>
                  <div>
                    <Tooltip
                      arrow
                      sx={{
                        [`& .${tooltipClasses.arrow}`]: {
                          color: theme.customColors.common.white,
                        },
                        [`& .${tooltipClasses.tooltip}`]: {
                          padding: "0",
                          backgroundColor: theme.customColors.common.white,
                          color: theme.customColors.common.text,
                          maxWidth: 220,
                          fontSize: theme.typography.pxToRem(16),
                        },
                      }}
                      PopperProps={{
                        disablePortal: true,
                      }}
                      open={showOtherMenu}
                      onClose={() => setShowOtherMenu(false)}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={
                        <List disablePadding>
                          <ListItemButton
                            LinkComponent={NextLink}
                            href={`/${router?.query?.hash}/admin/dashboard/widgets`}
                            onClick={() => setShowOtherMenu(false)}
                            sx={{ p: 2, fontWeight: 400, "& svg": { mr: 1 } }}
                          >
                            <Icon name="dashboard-second" />
                            {t("menu.widgets")}
                          </ListItemButton>
                        </List>
                      }
                    >
                      <S.ShowMenuButton
                        onClick={() => setShowOtherMenu(!showOtherMenu)}
                      >
                        <Icon
                          name="arrow-down"
                          size="small"
                          variant="primary"
                        />
                      </S.ShowMenuButton>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </>
            )}
          </S.RightContent>
        </S.Header>
      </Card>
    </S.Container>
  );
}

export default Header;
