import { Box, Stack, StackProps, useTheme } from "@mui/material";
import { rotate } from "./Loading.styles";
import { TSize } from "@edusynch/edusynch-ui";
import { getLoadingSize } from "./Loading.utils";

type LoadingProps = {
  size?: TSize;
  containerProps?: StackProps;
};

export function Loading({ size, containerProps }: LoadingProps) {
  const theme = useTheme();

  return (
    <Box {...containerProps}>
      <Stack
        sx={{
          width: getLoadingSize(size),
          height: getLoadingSize(size),
          animation: `${rotate} 1s linear infinite`,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
          viewBox="0 0 80 80"
        >
          <defs>
            <linearGradient
              id="circular-granident"
              x1="40"
              x2="40"
              y1="4"
              y2="80.5"
              gradientUnits="userSpaceOnUse"
              spreadMethod="pad"
            >
              <stop
                offset="0%"
                stopColor={theme.customColors.primary[500]}
              ></stop>
              <stop
                offset="99.99%"
                stopColor={theme.customColors.tertiary[500]}
              ></stop>
              <stop offset="100%" stopColor="rgba(0,0,0,0)"></stop>
            </linearGradient>
          </defs>
          <g opacity="0.8">
            <path
              fill="none"
              stroke="#E1E0E7"
              strokeLinecap="round"
              strokeWidth="8"
              d="M76 40a36.001 36.001 0 11-72.002 0A36.001 36.001 0 0176 40h0z"
            ></path>
            <g transform="translate(40 40)">
              <path
                fill="none"
                stroke="url(#circular-granident)"
                strokeLinecap="round"
                strokeWidth="8"
                d="M76 40A36 36 0 1140 4"
                transform="translate(-40 -40)"
              ></path>
            </g>
          </g>
        </svg>
      </Stack>
    </Box>
  );
}
