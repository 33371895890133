interface CalculatePercentageProps {
  number: number;
  total: number;
}

const PERCENTAGE = 100;

export function calculatePercentage({
  number,
  total,
}: CalculatePercentageProps) {
  return (number / total) * PERCENTAGE;
}
