import { Account } from "@/hooks/accounts";
import { AccountsSelectList } from "./AccountsSelectList";
import * as S from "./AccountsSelect.styles";

interface IAccountsSelectProps {
  accounts: Account[];
  onSelect: (option: Account) => void;
}

export function AccountsSelect({ accounts, onSelect }: IAccountsSelectProps) {
  return (
    <S.Container>
      <AccountsSelectList accounts={accounts} onAccountSelect={onSelect} />
    </S.Container>
  );
}
