export const secondsToMinutes = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);

  let minutesOutput = `${minutes}`;
  let secondsOutput = `${seconds}`;

  if (time === Infinity) {
    minutesOutput = "--";
    secondsOutput = "--";
  }

  return `${("0" + minutesOutput).slice(-2)}:${("0" + secondsOutput).slice(
    -2
  )}`;
};
