import {
  Box,
  CircularProgress,
  IconButton,
  IconButtonProps,
  useTheme,
} from "@mui/material";

interface IHoverIconButtonProps extends IconButtonProps {
  loading?: boolean;
}

export function HoverIconButton({ loading, ...props }: IHoverIconButtonProps) {
  const theme = useTheme();

  if (loading) {
    return (
      <Box display="flex" width={32} height={32} alignItems="center">
        <CircularProgress size={18} />
      </Box>
    );
  }

  return (
    <IconButton
      size="small"
      sx={{
        width: 32,
        height: 32,
        "&:hover": {
          color: theme.customColors.primary[500],
          backgroundColor: theme.customColors.primary[100],

          svg: {
            path: {
              fill: theme.customColors.primary[500],
            },
          },
        },
      }}
      {...props}
    >
      {props.children}
    </IconButton>
  );
}
