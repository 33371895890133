import { Slider as MUISlider, SliderProps } from "@mui/material";

import * as S from "./Slider.styles";

export function Slider(props: SliderProps) {
  return (
    <S.Container data-testid="slider">
      <MUISlider {...props} />
    </S.Container>
  );
}
