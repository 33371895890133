import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { getDefaultStartDate, getDefaultEndDate, onSort } from "@/utils";
import { TOptionItem } from "@/components/common/Filters/Filters.utils";
import { useAuth } from "@/providers/AuthProvider";
import { useSessionProvider } from "./useSessionProvider";

export function useStudentsFilters() {
  const [tempCourseId, setTempCourseId] = useState<any>(null);
  const [tempQuizId, setTempQuizId] = useState<any>(null);
  const [searchCourses, setSearchCourses] = useState("");

  const router = useRouter();
  const { session } = useAuth();
  const { isD2LBrightspaceProvider } = useSessionProvider();

  const {
    search = "",
    course_id = null,
    course_search = "",
    quiz_id = null,
    start_date = getDefaultStartDate({ timezone: session?.timezone }),
    end_date = getDefaultEndDate({ timezone: session?.timezone }),
    sort = "DESC",
    sort_by = "",
    page = isD2LBrightspaceProvider ? "" : 1,
    prev_page = "",
    paginates_per = 10,
  } = router.query;

  useEffect(() => {
    setTempCourseId(Number(course_id) || null);
  }, [course_id]);

  useEffect(() => {
    setSearchCourses(course_search as string);
  }, [course_search]);

  useEffect(() => {
    setTempQuizId(Number(quiz_id) || null);
  }, [quiz_id]);

  const handlePageChange = (value: number, prevPage?: number | null) => {
    const routeParams = isD2LBrightspaceProvider
      ? {
          page: value == 0 ? "" : value,
          prev_page: value == 0 ? "" : prevPage,
        }
      : {
          page: value,
        };

    router.replace({
      query: {
        ...router.query,
        ...routeParams,
      },
    });
  };

  const handlePerPageChange = (value: number) => {
    router.replace({
      query: {
        ...router.query,
        paginates_per: value,
      },
    });
  };

  const handleSortChange = (value: string) => {
    onSort({ sort: String(sort), sortBy: String(sort_by), value, router });
  };

  const handleFilter = (filters: any) => {
    router.replace({
      query: {
        ...router?.query,
        page: 1,
        search: filters.search,
        course_search: searchCourses,
        course_id: filters.course_id || null,
        quiz_id: filters.quiz_id || null,
        start_date: filters.start_date || start_date,
        end_date: filters.end_date || end_date,
        sort,
        sort_by,
        paginates_per,
      },
    });
  };

  const handleUpdateFilter = (filter: TOptionItem) => {
    const { filterName } = filter;

    if (filterName === "course_id") {
      setTempCourseId(filter.id);
    } else if (filterName === "quiz_id") {
      setTempQuizId(filter.id);
    }
  };

  const handleClearFilter = (filter: TOptionItem) => {
    const { filterName } = filter;

    if (filterName === "course_id") {
      setTempCourseId(null);
      setSearchCourses("");
    } else if (filterName === "quiz_id") {
      setTempQuizId(null);
    }
  };

  const handleClearAllFilters = () => {
    setTempCourseId(null);
    setSearchCourses("");
    setTempQuizId(null);
    router.replace({
      query: {
        hash: router?.query?.hash,
      },
    });
  };

  const hasFiltersActive = () => {
    return !!search || !!course_id || quiz_id;
  };

  return {
    search: String(search),
    courseId: Number(course_id) || null,
    quizId: Number(quiz_id) || null,
    startDate: String(start_date) || "",
    endDate: String(end_date) || "",
    sort: String(sort),
    sortBy: String(sort_by),
    page: String(page || ""),
    perPage: String(paginates_per || ""),
    prevPage: String(prev_page || ""),
    handleSortChange,
    handlePageChange,
    handlePerPageChange,
    handleFilter,
    handleClearFilter,
    handleClearAllFilters,
    handleUpdateFilter,
    hasFiltersActive,
    tempCourseId,
    setTempCourseId,
    tempQuizId,
    setTempQuizId,
    searchCourses,
    setSearchCourses,
  };
}
