import { SUBMISSION_DETECTORS_BY_SUBMISSION_ID_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { toast } from "@/components/common/Toast";
import { useApiClient } from "../useApiClient";

interface GetSubmissionAnswersProps {
  submissionId: string;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sort?: string;
}

export type TDeviceInfo = {
  type: string;
  model: string;
  os: string;
  browser: string;
};

export type TTracking = {
  country: string;
  city: string;
};

export type SubmissionAnswer = {
  id: number;
  question_title: string;
  ai_score: number;
};

type GetSubmissionAnswersResponse = {
  submissionAnswers: SubmissionAnswer[];
  total: number;
};

interface UseSubmissionAnswersProps {
  submissionId: string;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sort?: string;
  enabled?: boolean;
}

export function useSubmissionAnswers({
  submissionId,
  page,
  perPage,
  sortBy,
  sort,
  enabled = true,
}: UseSubmissionAnswersProps) {
  const { api } = useApiClient();

  const getSubmissionAnswers = async ({
    submissionId,
    page,
    perPage,
    sortBy,
    sort,
  }: GetSubmissionAnswersProps): Promise<GetSubmissionAnswersResponse> => {
    const response = await api.get(SUBMISSION_DETECTORS_BY_SUBMISSION_ID_URL, {
      params: {
        submission_id: submissionId,
        page,
        paginates_per: perPage,
        sort: sort || undefined,
        sort_by: sortBy || undefined,
      },
    });

    const submissionAnswers =
      response.data.content.submission_detectors.map((answer: any) => ({
        ...answer,
      })) || [];

    return {
      submissionAnswers,
      total: response.data.content.total_items,
    };
  };

  return useQuery({
    queryKey: [
      "submissionAnswers",
      {
        submissionId,
        page,
        perPage,
        sortBy,
        sort,
      },
    ],
    queryFn: () =>
      getSubmissionAnswers({
        submissionId,
        page,
        perPage,
        sortBy,
        sort,
      }),
    staleTime: 1000 * 60 * 1,
    enabled,
    onError: (error: any) => {
      toast.error({ message: error?.message });
    },
  });
}
