import { Theme } from "@mui/material";

interface IGetColorByStatusLabel {
  status: string;
  theme: Theme;
}

export const getColorByStatusLabel = ({
  status,
  theme,
}: IGetColorByStatusLabel) => {
  const STATUS_COLORS = {
    done: theme.customColors.green[500],
    correct: theme.customColors.green[500],
    scheduled: theme.customColors.green[700],
    evaluated: theme.customColors.primary[400],
    pending: theme.customColors.gray[500],
    failed: theme.customColors.tertiary[500],
    completed: theme.customColors.primary[500],
    partial: theme.customColors.secondary[500],
    reviewed: theme.customColors.primary[600],
    canceled: theme.customColors.tertiary[700],
    incorrect: theme.customColors.tertiary[500],
    partial_evaluated: theme.customColors.orange[500],
    skipped: theme.customColors.orange[500],
    in_progress: theme.customColors.orange[500],
    missed: theme.customColors.orange[700],
  };

  return STATUS_COLORS[status as keyof typeof STATUS_COLORS];
};
