import { Icon } from "@/components/common";
import { Modal, Typography } from "@edusynch/edusynch-ui";
import { ColumnDefinitionType } from "@edusynch/edusynch-ui/dist/lib/components/Table/table.utils";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import React, { ReactNode } from "react";
import {
  ExtendedRowModalOptions,
  TExtendedRowModalOptions,
} from "./ExtendedRowModalOptions";

interface IExtendedRowModalProps<T> {
  title: string;
  data: T | null;
  columns: ColumnDefinitionType<T>[];
  isOpen: boolean;
  actions: object;
  extendedRowModalOptions: TExtendedRowModalOptions<T>[];
  onClose: () => void;
  beforeListSlot?: ReactNode;
}

export function ExtendedRowModal<T>({
  title,
  data,
  columns,
  isOpen,
  actions,
  extendedRowModalOptions,
  onClose,
  beforeListSlot,
}: IExtendedRowModalProps<T>) {
  const theme = useTheme();

  if (!data) return null;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeIcon={<Icon name="close" />}
      fullScreen
      sx={{
        ".MuiDialogContent-root.MuiDialogContent-root": {
          padding: 0,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        p={3}
        sx={{ borderBottom: `1px solid ${theme.customColors.gray[200]}` }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton onClick={onClose} data-testid="close-button">
            <Icon name="close" variant="gray" size="small" />
          </IconButton>
          <Typography color="text" style={{ fontWeight: "bold" }}>
            {title}
          </Typography>
        </Stack>

        {extendedRowModalOptions?.length ? (
          <ExtendedRowModalOptions
            item={data}
            options={extendedRowModalOptions}
          />
        ) : null}
      </Stack>

      {!!beforeListSlot && beforeListSlot}

      <List
        sx={{
          ".MuiListItem-root": {
            p: 2,
          },
        }}
      >
        {columns.map((row, index) => (
          <React.Fragment key={`extended-row-${index}`}>
            <ListItem>
              <ListItemText
                primary={row.header}
                primaryTypographyProps={{
                  color: theme.customColors.common.text,
                  fontSize: "0.875rem",
                  fontWeight: "bold",
                }}
              />
              {row.render
                ? row.render({ ...data, ...actions })
                : (data[row.key] as ReactNode)}
            </ListItem>
            <Divider sx={{ mx: 2, bgcolor: theme.customColors.gray[300] }} />
          </React.Fragment>
        ))}
      </List>
    </Modal>
  );
}
