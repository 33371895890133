import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getDefaultStartDate, getDefaultEndDate } from "@/utils";
import { useAuth } from "@/providers/AuthProvider";

const TEMP_INITIAL_STATE = {
  course_id: null,
  quiz_id: null,
  ai_score: null,
};

export function useSubmissionsFilters() {
  const [tempIds, setTempIds] = useState<any | null>(TEMP_INITIAL_STATE);

  const router = useRouter();
  const { session } = useAuth();

  const {
    search = "",
    course_id = null,
    quiz_id = null,
    ai_score = null,
    start_date = getDefaultStartDate({ timezone: session?.timezone }),
    end_date = getDefaultEndDate({ timezone: session?.timezone }),
    sort = "",
    sort_by = "",
    page = 1,
    paginates_per = 10,
  } = router.query;

  useEffect(() => {
    setTempIds({
      course_id: Number(course_id) || null,
      quiz_id: Number(quiz_id) || null,
      ai_score: Number(ai_score) || null,
    });
  }, [course_id, quiz_id, ai_score]);

  const handlePageChange = (value: number) => {
    router.replace({
      query: {
        ...router.query,
        page: value,
      },
    });
  };

  const handlePerPageChange = (value: number) => {
    router.replace({
      query: {
        ...router.query,
        paginates_per: value,
      },
    });
  };

  const handleFilter = (filters: any) => {
    router.replace({
      query: {
        hash: router.query.hash,
        page: 1,
        search: filters.search,
        course_id: filters.course_id || null,
        quiz_id: filters.quiz_id || null,
        ai_score: filters.ai_score || null,
        start_date: filters.start_date || start_date,
        end_date: filters.end_date || end_date,
        sort,
        sort_by,
        paginates_per,
      },
    });
  };

  const handleUpdateFilter = (filter: any) => {
    const { filterName } = filter;
    setTempIds((ids: any) => ({ ...ids, [filterName]: filter.id }));
  };

  const handleClearFilter = (filter: any) => {
    const { filterName } = filter;
    setTempIds((ids: any) => ({ ...ids, [filterName]: null }));
  };

  const handleClearAllFilters = () => {
    setTempIds(TEMP_INITIAL_STATE);
    router.replace({
      query: {
        hash: router.query.hash,
      },
    });
  };

  const hasFiltersActive = () => {
    return !!search || !!course_id || !!quiz_id || !!ai_score;
  };

  return {
    search: String(search),
    courseId: Number(course_id) || null,
    quizId: Number(quiz_id) || null,
    aiScore: Number(ai_score) || null,
    startDate: String(start_date) || "",
    endDate: String(end_date) || "",
    sort: String(sort),
    sortBy: String(sort_by),
    page: Number(page),
    perPage: Number(paginates_per),
    handlePageChange,
    handlePerPageChange,
    handleFilter,
    handleClearFilter,
    handleClearAllFilters,
    handleUpdateFilter,
    tempIds,
    setTempIds,
    hasFiltersActive,
  };
}
