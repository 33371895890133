import { MOBILE_SIZE } from "@/hooks";
import { styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

export const Container: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  min-width: 240px;
  position: absolute;
  top: 135%;
  right: -12px;
  background-color: ${(props) => props.theme.customColors.common.white};
  border-radius: 0.375rem;
  box-shadow: 0 0.375rem 0.75rem rgba(49, 49, 49, 0.15);
  z-index: 10;

  ::before {
    content: "";
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    background: #fff;
    position: absolute;
    top: -0.25rem;
    right: 0.7rem;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: -1;
  }

  @media (${MOBILE_SIZE}) {
    right: auto;

    ::before {
      left: 0.7rem;
    }
  }
`;

export const Options: ComponentType<HTMLAttributes<HTMLUListElement>> = styled(
  "ul"
)`
  border-radius: 0.375rem;
  max-height: 21.875rem;
  overflow-y: auto;

  scrollbar-color: ${(props) => props.theme.customColors.gray[200]};

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.customColors.gray[100]};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.customColors.gray[300]};
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.customColors.gray[500]};
  }
`;

export const Option: ComponentType<HTMLAttributes<HTMLLIElement>> = styled(
  "li"
)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.customTypography.default};
  color: ${(props) => props.theme.customColors.common.text};
  padding: 1rem;
  transition: background-color 0.2s;
  height: 4rem;
  gap: 0.5rem;

  & + li {
    border-top: 1px solid ${(props) => props.theme.customColors.gray[200]};
  }

  &:last-of-type {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media ${MOBILE_SIZE} {
    margin-right: 0.5rem;
    & + li {
      border-top-color: ${(props) => props.theme.customColors.primary[200]};
    }
  }
`;

export const OptionHeader: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  display: flex;
  align-items: center;
  padding: 1rem;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.customColors.gray[200]};

  button {
    position: absolute;
  }

  h6 {
    text-align: center;
    margin: 0 auto;
    ${(props) => props.theme.customTypography.default};
    font-weight: bold;
  }

  @media ${MOBILE_SIZE} {
    padding: 0;

    h6 {
      font-weight: normal;
    }
  }
`;

export const SearchContainer: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  border-bottom: 1px solid ${(props) => props.theme.customColors.gray[200]};
  position: relative;

  .MuiInputBase-root {
    height: auto;
    padding: 0;
  }

  input {
    width: 100%;
    border: none;
    min-height: 32px;
    padding: 8px 8px 6px 48px;
  }

  svg {
    position: absolute;
    top: 16px;
    left: 16px;

    path {
      fill: ${(props) => props.theme.customColors.gray[300]};
    }
  }
`;
