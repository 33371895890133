import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { Box, useTheme } from "@mui/material";

export function Droppable(props: any) {
  const theme = useTheme();

  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    data: props.data,
  });
  const style = {
    backgroundColor: isOver ? theme.customColors.primary[300] : undefined,
  };

  return (
    <Box ref={setNodeRef} style={style} {...props}>
      {props.children}
    </Box>
  );
}
