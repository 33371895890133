import { useTranslation } from "next-i18next";
import { Button } from "@edusynch/edusynch-ui";
import { EmptyData } from "../EmptyData";

import serverErrorSVG from "@/public/assets/svg/permission-denied-.svg";
import { Container, useTheme } from "@mui/material";
import { useRouter } from "next/router";

export function PermissionDeniedError() {
  const { t } = useTranslation("common");
  const theme = useTheme();

  const router = useRouter();

  const contactEmail = router.query["email"] || "";

  const contactLmsAdmin = () => {
    window.location.href = `mailto:${contactEmail}`;
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 20 }}>
      <EmptyData
        imageURL={serverErrorSVG}
        imageWidth={223}
        imageHeight={177.99}
        title={t("errors.permission-denied") as string}
        titleStyle={{
          marginBottom: "0.5rem",
          fontSize: "2.5rem",
          fontWeight: "bold",
          color: theme.customColors.secondary[500],
        }}
        subtitleStyle={{ color: theme.customColors.common.text }}
        subtitle={t("errors.please_contact_lms_admin") as string}
      />
      <Button
        size="large"
        style={{ margin: "2.5rem auto 0 auto" }}
        onClick={contactLmsAdmin}
      >
        {t("errors.contact_lms_admin")}
      </Button>
    </Container>
  );
}
