import { useState } from "react";
import { FormGroup, IconButton, useTheme } from "@mui/material";

import * as S from "./WidgetSettings.styles";
import { TWidget } from "@/components/widgets/interfaces";
import { Button, Card, RadioGroup, Typography } from "@edusynch/edusynch-ui";
import { Icon } from "../../Icon";
import { useTranslation } from "next-i18next";

export type TSaveWidgetSettings = {
  widgetId: number;
  size: "small" | "large";
};

interface IWidgetSettingsProps {
  widget: TWidget;
  onSave: (data: TSaveWidgetSettings) => void;
  onClose: () => void;
  onRemoveWidget: (id: number) => void;
}

export const WidgetSettings = ({
  widget,
  onSave,
  onClose,
  onRemoveWidget,
}: IWidgetSettingsProps) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [widgetSize, setWidgetSize] = useState(widget?.settings?.size);

  const theme = useTheme();
  const { t } = useTranslation("common");

  const handleSave = () => {
    onSave({
      widgetId: widget.id,
      size: widgetSize,
    });
    onClose();
  };

  return (
    <Card padding="0" style={{ height: "100%" }} data-testid="widget-settings">
      {isRemoving ? (
        <S.DangerZone>
          <S.CloseButton>
            <IconButton
              aria-label="close"
              id="close"
              onClick={() => setIsRemoving(false)}
              sx={{ marginLeft: "auto" }}
              data-testid="danger-zone-close-button"
            >
              <Icon name="close" width={16} height={16} />
            </IconButton>
          </S.CloseButton>
          <h2>{t("dashboard.widgets.settings.danger_zone.title")}</h2>
          <p>{t("dashboard.widgets.settings.danger_zone.description")}</p>
          <Button
            size="small"
            variant="tertiary"
            startIcon={<Icon name="delete" size="small" variant="white" />}
            onClick={() => onRemoveWidget(widget.id)}
            data-testid="danger-zone-remove-widget"
          >
            {t("dashboard.widgets.settings.danger_zone.remove_widget")}
          </Button>
        </S.DangerZone>
      ) : (
        <S.Container>
          <S.Header>
            <div>
              <h6>{widget?.name}</h6>
              <p>{t("dashboard.widgets.settings.title")}</p>
            </div>
            <IconButton
              aria-label="close"
              id="close"
              onClick={onClose}
              sx={{ marginLeft: "auto", alignSelf: "center" }}
              data-testid="close-button"
            >
              <Icon name="close" width={16} height={16} />
            </IconButton>
          </S.Header>
          <S.Content>
            <FormGroup>
              <Typography style={{ color: theme.customColors.gray[500] }}>
                {t("dashboard.widgets.settings.widget_size")}
              </Typography>
              <RadioGroup
                value={widgetSize}
                options={[
                  {
                    value: "small",
                    label: t("dashboard.widgets.settings.small") as string,
                  },
                  {
                    value: "large",
                    label: t("dashboard.widgets.settings.large") as string,
                  },
                ]}
                onChange={({ target }) => setWidgetSize(target.value as any)}
              />
            </FormGroup>
          </S.Content>
          <S.Footer>
            <Button
              variant="text"
              size="small"
              style={{
                padding: "0.5rem",
                color: theme.customColors.tertiary[400],
              }}
              startIcon={<Icon name="delete" size="small" variant="tertiary" />}
              onClick={() => setIsRemoving(true)}
              data-testid="remove-widget"
            >
              {t("dashboard.widgets.settings.remove")}
            </Button>
            <Button size="small" onClick={handleSave} data-testid="save-widget">
              {t("dashboard.widgets.settings.save")}
            </Button>
          </S.Footer>
        </S.Container>
      )}
    </Card>
  );
};
