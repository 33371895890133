import { useRouter } from "next/router";

export function useStudentAccommodationsFilters() {
  const router = useRouter();

  const {
    sort = "",
    sort_by = "",
    page = 1,
    paginates_per = 10,
  } = router.query;

  const handlePageChange = (value: number) => {
    router.replace({
      query: {
        ...router.query,
        page: value,
      },
    });
  };

  const handlePerPageChange = (value: number) => {
    router.replace({
      query: {
        ...router.query,
        paginates_per: value,
      },
    });
  };

  return {
    sort: String(sort),
    sortBy: String(sort_by),
    page: Number(page),
    perPage: Number(paginates_per),
    handlePageChange,
    handlePerPageChange,
  };
}
