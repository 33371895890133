import { MOBILE_SIZE, useMediaQuery } from "@/hooks";
import { Button, IconRoundBox, Modal, Typography } from "@edusynch/edusynch-ui";
import { Stack, useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import { Icon } from "../Icon";

interface IUnsavedChangesModalProps {
  isOpen: boolean;
  title?: string;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => void;
  onClose: () => void;
}

export function UnsavedChangesModal({
  isOpen,
  title,
  message,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  onConfirm,
  onClose,
}: IUnsavedChangesModalProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(MOBILE_SIZE);
  const { t } = useTranslation("common");

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeIcon=""
      maxWidth="xs"
      fullWidth
      sx={{
        ".MuiDialogContent-root.MuiDialogContent-root": {
          padding: isMobile ? 3 : 4,
        },
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <IconRoundBox
          icon={<Icon name="exclamation" variant="tertiary" size="medium" />}
          variant="tertiary"
          size="large"
        />
        <Typography variant="h3" color="text" as="h3">
          {title || t("unsaved_changes_modal.title")}
        </Typography>
        <Typography
          color="text"
          as="p"
          style={{
            fontSize: "1rem",
            padding: isMobile ? "0" : "0 3rem",
            textAlign: "center",
          }}
        >
          {message || t("unsaved_changes_modal.message")}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          pt={4}
          borderTop={`1px solid ${theme.customColors.gray[100]}`}
        >
          <Button
            size={isMobile ? "medium" : "large"}
            variant="outlined"
            style={{ width: isMobile ? "auto" : "9.5rem" }}
            onClick={onClose}
          >
            {cancelButtonText || t("unsaved_changes_modal.cancel_button_text")}
          </Button>
          <Button
            size={isMobile ? "medium" : "large"}
            style={{ width: isMobile ? "auto" : "9.5rem" }}
            onClick={onConfirm}
          >
            {confirmButtonText ||
              t("unsaved_changes_modal.confirm_button_text")}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}
