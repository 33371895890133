import { Stack } from "@mui/material";

import * as S from "./ButtonGroup.styles";
import { Typography } from "@edusynch/edusynch-ui";

interface ButtonGroupProps {
  leftLabel: string;
  rightLabel: string;
  active: "left" | "right";
  onClickLeft: () => void;
  onClickRight: () => void;
}

export function ButtonGroup({
  leftLabel,
  rightLabel,
  active,
  onClickLeft,
  onClickRight,
}: ButtonGroupProps) {
  return (
    <Stack direction="row">
      <S.Button side="left" active={active === "left"} onClick={onClickLeft}>
        <Typography
          variant="small"
          color={active === "left" ? "primary" : "gray"}
        >
          {leftLabel}
        </Typography>
      </S.Button>
      <S.Separator />
      <S.Button side="right" active={active === "right"} onClick={onClickRight}>
        <Typography
          variant="small"
          color={active === "right" ? "primary" : "gray"}
        >
          {rightLabel}
        </Typography>
      </S.Button>
    </Stack>
  );
}
