import { useTranslation } from "next-i18next";
import { Button } from "@edusynch/edusynch-ui";
import { Icon } from "../Icon";
import { EmptyData } from "../EmptyData";

import serverErrorSVG from "@/public/assets/svg/500-2.svg";
import { Container, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import { DASHBOARD_ROUTE } from "@/constants/app-routes";

export function ServerError() {
  const router = useRouter();
  const { t } = useTranslation("common");
  const theme = useTheme();
  const hash = router?.query?.hash;

  return (
    <Container maxWidth="sm" sx={{ mt: 20 }}>
      <EmptyData
        imageURL={serverErrorSVG}
        imageWidth={271}
        imageHeight={164}
        title={t(`errors.server-error`) as string}
        titleStyle={{
          marginBottom: "0.5rem",
          fontSize: "2.5rem",
          fontWeight: "bold",
          color: theme.customColors.secondary[500],
        }}
        subtitleStyle={{ color: theme.customColors.common.text }}
        subtitle={t(`errors.please_try_again`) as string}
      />
      {hash ? (
        <Button
          size="large"
          startIcon={<Icon name="chevron-left" variant="white" />}
          style={{ margin: "2.5rem auto 0 auto" }}
          onClick={() => router.push(`${hash}/${DASHBOARD_ROUTE}`)}
        >
          {t("errors.back_to_dashboard")}
        </Button>
      ) : null}
    </Container>
  );
}
