import { SxProps, Theme } from "@mui/material";

export const paginationButtonSx = (theme: Theme): SxProps => {
  return {
    width: "4rem",
    height: "2.5rem",
    border: `1px solid ${theme.customColors.gray[100]}`,
    color: theme.customColors.common.text,
    backgroundColor: theme.customColors.common.white,
    fontSize: "0.875rem",
    "&.Mui-disabled": {
      pointerEvents: "unset",
      cursor: "not-allowed",

      "i::before": {
        color: theme.customColors.gray[300],
      },
    },
    "&:hover": {
      backgroundColor: theme.customColors.gray[200],
    },
  };
};
