import { TokenService } from "@/services/TokenService";
import { IUserData } from "@/types/IUserData";
import decode from "jwt-decode";
import { NextRouter } from "next/router";

export const AuthService = {
  async getSession(ctx: any, router: NextRouter | null) {
    const hash = router?.query?.hash || ctx?.query?.params || "";
    const token = await TokenService.get(hash);
    if (!token) {
      return null;
    }
    return this.decode(token);
  },

  decode: (token: string) => {
    const decodedToken = decode(token);

    return {
      ...(decodedToken as IUserData),
    };
  },
  switchToken: async (token: string, hash: string) => {
    if (!token) {
      return new Error("Token inválido");
    }
    const currentToken = await TokenService.get(hash);
    const shouldSwitchToken = token !== currentToken;

    if (shouldSwitchToken) {
      return TokenService.save({ hash, token });
    }

    return false;
  },
  fetchToken: async (hash: string) => {
    const token = await TokenService.get(hash);
    return token;
  },
};
