import { SUBMISSION_BY_ID_URL } from "@/constants/api-routes";
import { useApiClient } from "../useApiClient";
import { Submission } from "./useSubmissions";

interface GetSubmissionProps {
  submissionId: number;
}

type GetSubmissionResponse = Submission;

export function useSubmission() {
  const { api } = useApiClient();

  const getSubmission = async ({
    submissionId,
  }: GetSubmissionProps): Promise<GetSubmissionResponse> => {
    const response = await api.get(SUBMISSION_BY_ID_URL, {
      params: {
        submission_id: submissionId,
      },
    });

    return response.data.content;
  };

  return {
    getSubmission,
  };
}
