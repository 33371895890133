import { useRouter } from "next/router";

export function useThirdPartyQuizzesFilters() {
  const router = useRouter();

  const {
    search = "",
    course_id = null,
    sort = "",
    sort_by = "",
    page = 1,
    paginates_per = 10,
  } = router.query;

  const handlePageChange = (value: number) => {
    router.replace({
      query: {
        ...router.query,
        page: value,
      },
    });
  };

  const handlePerPageChange = (value: number) => {
    router.replace({
      query: {
        ...router.query,
        paginates_per: value,
      },
    });
  };

  const handleFilter = (filters: any) => {
    router.replace({
      query: {
        hash: router.query.hash,
        page: 1,
        search: filters.search,
        course_id: filters.course_id || null,
        sort,
        sort_by,
        paginates_per,
      },
    });
  };

  return {
    search: String(search),
    courseId: Number(course_id) || null,
    sort: String(sort),
    sortBy: String(sort_by),
    page: Number(page),
    perPage: Number(paginates_per),
    handlePageChange,
    handlePerPageChange,
    handleFilter,
  };
}
