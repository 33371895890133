import { styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

export const Container: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)``;

export const Header: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.customColors.gray[200]};
  padding: 1rem 1.5rem;

  h6 {
    ${(props) => props.theme.customTypography.small};
    color: ${(props) => props.theme.customColors.gray[500]};
    margin-bottom: 0.25rem;
  }

  p {
    ${(props) => props.theme.customTypography.default};
    font-weight: bold;
    color: ${(props) => props.theme.customColors.common.text};
  }
`;

export const Content: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  padding: 1rem 1.5rem;
  min-height: 18.3rem;
`;

export const Footer: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  border-top: 1px solid ${(props) => props.theme.customColors.gray[200]};
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DangerZone: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 27rem;
  text-align: center;
  position: relative;

  h2 {
    margin-bottom: 0.5rem;
    ${(props) => props.theme.customTypography.h5};
    color: ${(props) => props.theme.customColors.common.text};
  }

  p {
    max-width: 14rem;
    ${(props) => props.theme.customTypography.default};
    margin-bottom: 2.5rem;
    color: ${(props) => props.theme.customColors.gray[500]};
  }

  button:not(.MuiButtonBase-root) {
    margin: 0 auto;
    background-color: ${(props) => props.theme.customColors.tertiary[400]};
  }
`;

export const CloseButton: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 1.1rem;
  right: 1.5rem;
`;
