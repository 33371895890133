import { Card } from "@edusynch/edusynch-ui";
import { ReactNode } from "react";

interface IWidgetCardProps {
  children: ReactNode;
}

export const WidgetCard = ({ children }: IWidgetCardProps) => {
  return (
    <Card padding="1.5rem" style={{ height: "100%" }} data-testid="widget-card">
      {children}
    </Card>
  );
};
