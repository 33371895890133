import { QUIZZES_SESSIONS_LIST_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useApiClient } from "./useApiClient";

interface GetQuizzesSessionsProps {
  page: number;
  perPage: number;
  search?: string;
  incidentLevel?: string;
  sortBy?: string;
  sort?: string;
  startDate: string;
  endDate: string;
}

export type TStudent = {
  id: number;
  name: string;
  email: string;
  avatar_url: string;
};

export type QuizSession = {
  id: number;
  description: string;
  student: TStudent;
  status: string;
  incident_level: string;
  reviewed: boolean;
  created_at: string;
};

type GetQuizzesSessionsResponse = {
  sessions: QuizSession[];
  total: number;
};

interface UseQuizzesSessionsProps {
  page: number;
  perPage: number;
  search: string;
  incidentLevel?: string;
  sortBy: string;
  sort: string;
  startDate: string;
  endDate: string;
}

export function useQuizzesSessions({
  page,
  perPage,
  search,
  incidentLevel,
  sortBy,
  sort,
  startDate,
  endDate,
}: UseQuizzesSessionsProps) {
  const router = useRouter();
  const { api } = useApiClient();

  const { course_id, quiz_type, quiz_id } = router.query;

  const getQuizzesSessions = async ({
    page,
    perPage,
    search,
    incidentLevel,
    sortBy,
    sort,
    startDate,
    endDate,
  }: GetQuizzesSessionsProps): Promise<GetQuizzesSessionsResponse> => {
    const response = await api.get(QUIZZES_SESSIONS_LIST_URL, {
      params: {
        quiz_id,
        course_id,
        quiz_type,
        incident_level: incidentLevel,
        search,
        page,
        paginates_per: perPage,
        sort,
        sort_by: sortBy,
        start_date: startDate,
        end_date: endDate,
      },
    });

    const sessions = response.data.content.sessions.map((session: any) => ({
      ...session,
      student: {
        ...session.student,
        avatar_url: session.student.avatar.thumb.url,
      },
    }));

    return {
      sessions,
      total: response.data.content.total_items,
    };
  };

  return useQuery({
    queryKey: [
      "quizzes_sessions",
      {
        quiz_id,
        course_id,
        quiz_type,
        page,
        perPage,
        search,
        incidentLevel,
        sortBy,
        sort,
        startDate,
        endDate,
      },
    ],
    queryFn: () =>
      getQuizzesSessions({
        page,
        perPage,
        search,
        incidentLevel,
        sortBy,
        sort,
        startDate,
        endDate,
      }),
    staleTime: 1000 * 60 * 1,
  });
}
