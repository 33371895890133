type FlattenMapType<T, K extends keyof T> = {
  arrayToFlatmap: T[];
  propName: K;
  identifier: keyof T;
};

const flattenMap = <T, K extends keyof T>({
  arrayToFlatmap,
  propName,
  identifier,
}: FlattenMapType<T, K>): T[] => {
  const flatMapRecursive = (item: T, allItems: Map<T[keyof T], T>): T[] => {
    if (!allItems.has(item[identifier])) {
      allItems.set(item[identifier], item);
      const propValue = item[propName];
      const nestedItems = Array.isArray(propValue) ? propValue : [];
      return [item].concat(
        ...nestedItems.flatMap((nested) => flatMapRecursive(nested, allItems))
      );
    }
    return [];
  };

  const seen = new Map<T[keyof T], T>();
  return arrayToFlatmap.flatMap((item) => flatMapRecursive(item, seen));
};

export default flattenMap;
