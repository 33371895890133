import { getBackgroundColorByIncidentLevel } from "@/utils/incident-level.utils";
import { styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

interface ISecondaryIncidentLevelProps {
  incidentLevel: string;
}

export const SecondaryIncidentLevel: ComponentType<
  HTMLAttributes<HTMLDivElement> & ISecondaryIncidentLevelProps
> = styled("div")<ISecondaryIncidentLevelProps>`
  font-size: 0.875rem;
  color: ${(props) => props.theme.customColors.common.text};

  &::before {
    content: "";
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    ${(props) =>
      getBackgroundColorByIncidentLevel({
        incidentLevel: props.incidentLevel,
        theme: props.theme,
      })};
  }
`;
