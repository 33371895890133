import { TIntervalOption } from "@/components/widgets/common/IntervalOptions/IntervalOptions.utils";
import { EVENTS_ANALYTICS_WIDGET_MOCKED_DATA } from "@/components/widgets/EventsAnalyticsWidget/EventsAnalyticsWidget.utils";
import { DASHBOARD_EVENTS_ANALYTICS_WIDGET_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "./useApiClient";

interface UseEventsAnalyticsWidgetProps {
  intervalOption: TIntervalOption;
  quizId?: number;
  isMock?: boolean;
}

export type TEventsAnalytics = {
  category: string;
  total: number;
  percentage: string;
};

export type TExamsAnalytics = {
  id: string | number;
  name: string;
};

type GetEventsAnalyticsWidgetResponse = {
  events: TEventsAnalytics[];
  exams: TExamsAnalytics[];
};

export function useEventsAnalyticsWidget({
  intervalOption,
  quizId,
  isMock,
}: UseEventsAnalyticsWidgetProps) {
  const { api } = useApiClient();

  const getEventsAnalyticsWidget =
    async (): Promise<GetEventsAnalyticsWidgetResponse> => {
      const response = await api.get(DASHBOARD_EVENTS_ANALYTICS_WIDGET_URL, {
        params: {
          days: intervalOption,
          quiz_id: quizId && quizId > 0 ? quizId : undefined,
        },
      });

      return response.data.content;
    };

  const getEventsAnalyticsWidgetMockData = async () => {
    return EVENTS_ANALYTICS_WIDGET_MOCKED_DATA;
  };

  return useQuery({
    queryKey: ["events_analytics_widget", { intervalOption, quizId, isMock }],
    queryFn: isMock
      ? getEventsAnalyticsWidgetMockData
      : getEventsAnalyticsWidget,
    staleTime: 1000 * 60 * 1,
  });
}
