import { KJUR } from "jsrsasign";

const ALGORITHM = "HS256";

/**
 * Generate a jwt token with payload data.
 * @param {Object} payload
 * @returns {String}
 */
export const encode = (payload: Object) => {
  const oHeader = { alg: ALGORITHM };
  const sHeader = JSON.stringify(oHeader);
  const sPayload = JSON.stringify(payload);
  const encodedValue = KJUR.jws.JWS.sign(ALGORITHM, sHeader, sPayload, {
    b64u: process.env.NEXT_PUBLIC_JWT_SECRET || "",
  });

  return encodedValue;
};

export const decode = (token: string): any | undefined => {
  const decode = KJUR.jws.JWS.parse(token);
  return decode.payloadObj;
};
