import { Skeleton } from "@edusynch/edusynch-ui";
import * as S from "./Filters.styles";

interface IFiltersPlaceholderProps {
  filters?: number;
}

export function FiltersPlaceholder({ filters = 1 }: IFiltersPlaceholderProps) {
  return (
    <S.SkeletonContainer>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "0.8rem" }}>
        {[...Array(filters + 1).keys()].map((n) => (
          <Skeleton
            key={n}
            width="6.875rem"
            height="1.75rem"
            secondary
            data-testid="placeholder"
          />
        ))}
      </div>
    </S.SkeletonContainer>
  );
}
