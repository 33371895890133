import { CourseLms, TExamsAnalytics } from "@/hooks";
import { ChartOptions } from "chart.js";
import * as EventMessages from "src/components/Quizzes/SessionLog/utils/events";
const MAX_LABEL_WORDS = 6;

export const getEventLogMessage = (type: keyof typeof EventMessages) => {
  const message = EventMessages[type];
  return message || "-";
};

export const formatChartLabels = (data: any) => {
  return (
    data?.events?.map((item: any) => getEventLogMessage(item.category)) || []
  );
};

export const formatChartValues = (
  data: any,
  viewMode: "events" | "percent"
) => {
  if (viewMode === "percent") return formatChartPercentages(data);

  return data?.events?.map((item: any) => item.total) || [];
};

export const formatChartPercentages = (data: any) => {
  return (
    data?.events?.map((item: any) =>
      Number(item.percentage?.replace("%", "") || "")
    ) || []
  );
};

export const formatChartData = (
  formattedChartLabels: string[],
  formattedChartData: number[],
  backgroundColor: string[],
  label: string
) => {
  return {
    labels: formattedChartLabels,
    datasets: [
      {
        label,
        data: formattedChartData,
        backgroundColor: backgroundColor,
        barThickness: 10,
        borderRadius: 10,
      },
    ],
  };
};

export const formatChartOptions = (
  viewMode: "events" | "percent"
): ChartOptions => {
  return {
    indexAxis: "y" as const,
    maintainAspectRatio: false,
    scales: {
      x: {
        min: 0,
        max: viewMode === "percent" ? 100 : undefined,
        ticks: {
          callback(tickValue) {
            const currentLabel = this.getLabelForValue(Number(tickValue));

            return viewMode === "percent" ? `${currentLabel}%` : currentLabel;
          },
        },
      },
      y: {
        ticks: {
          crossAlign: "far" as const,
          callback(tickValue) {
            const currentLabel = this.getLabelForValue(Number(tickValue));
            const splittedLabel = currentLabel.split(" ");
            const isLongLabel = splittedLabel?.length > MAX_LABEL_WORDS;

            if (isLongLabel) {
              return splittedLabel.slice(0, MAX_LABEL_WORDS).join(" ") + "...";
            }

            return currentLabel;
          },
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  };
};

export const formatCoursesToSelect = (courses: CourseLms[] = [], t: any) => {
  const defaultOption = {
    id: -1,
    name: "All Courses",
    label: t("dashboard.widgets.events_analytics.all_courses"),
    value: -1,
  };

  if (!courses?.length) {
    return [defaultOption];
  }

  const formattedCourses = [
    defaultOption,
    ...courses?.map((quiz) => ({
      id: quiz.id,
      name: quiz.name,
      label: quiz.name,
      value: quiz.id,
    })),
  ];

  return formattedCourses;
};

export const formatQuizzesToSelect = (
  quizzes: TExamsAnalytics[] = [],
  t: any
) => {
  const defaultOption = {
    id: -1,
    name: "All Exams",
    label: t("dashboard.widgets.events_analytics.all_exams"),
    value: -1,
  };

  if (!quizzes?.length) {
    return [defaultOption];
  }

  const formattedQuizzes = [
    defaultOption,
    ...quizzes?.map((quiz) => ({
      id: quiz.id,
      name: quiz.name,
      label: quiz.name,
      value: quiz.id,
    })),
  ];

  return formattedQuizzes;
};

export const EVENTS_ANALYTICS_WIDGET_MOCKED_DATA = {
  events: [
    {
      category: "MOVE_FOCUS",
      total: 199,
      percentage: "60%",
    },
    {
      category: "MULTIPLE_CAMS",
      total: 105,
      percentage: "50%",
    },
    {
      category: "MULTIPLE_DISPLAYS",
      total: 84,
      percentage: "45%",
    },
    {
      category: "MULTIPLE_FACES",
      total: 62,
      percentage: "40%",
    },
    {
      category: "NEW_TAB",
      total: 49,
      percentage: "35%",
    },
    {
      category: "MULTIPLE_FACES_DETECTED",
      total: 32,
      percentage: "30%",
    },
    {
      category: "NO_FACE_DETECTED",
      total: 22,
      percentage: "25%",
    },
    {
      category: "NEW_TAB_WINDOW",
      total: 18,
      percentage: "20%",
    },
    {
      category: "START_STREAMING",
      total: 11,
      percentage: "5%",
    },
    {
      category: "STOP_STREAMING",
      total: 3,
      percentage: "2%",
    },
  ],
  exams: [
    { id: 1, name: "Bell Ringer: Quiz" },
    { id: 2, name: "Course Final Exam" },
  ],
};
