import { AuthContextData } from "@/providers/AuthProvider/index.d";
import { IUserData } from "@/types/IUserData";
import { createContext } from "react";

export const AuthContext = createContext<AuthContextData>({
  session: {} as IUserData,
  setSession: async () => {},
  logout: () => {},
  loading: true,
  error: undefined,
});
