import { Typography } from "@edusynch/edusynch-ui";
import { Box, BoxProps, useTheme } from "@mui/material";
import Image from "next/image";
import React from "react";

interface IEmptyDataProps {
  imageURL?: string;
  imageWidth?: number;
  imageHeight?: number;
  title?: string;
  subtitle?: string;
  boxProps?: BoxProps;
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
}

export function EmptyData({
  imageURL = "/assets/svg/oops-icon.svg",
  imageWidth = 100,
  imageHeight = 100,
  title = "No results.",
  subtitle = "Nothing here yet...",
  boxProps,
  titleStyle,
  subtitleStyle,
}: IEmptyDataProps) {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...boxProps}
    >
      <Box mb={2}>
        <Image
          src={imageURL}
          width={imageWidth}
          height={imageHeight}
          alt="Empty data icon"
        />
      </Box>
      <Typography
        variant="h1"
        style={
          titleStyle || {
            marginBottom: "0.25rem",
            fontSize: "1rem",
            lineHeight: "1rem",
            fontWeight: "bold",
            color: theme.customColors.gray[400],
          }
        }
      >
        {title}
      </Typography>
      <Typography
        color="gray"
        style={subtitleStyle || { color: theme.customColors.gray[400] }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
}
