import { toast } from "@/components/common/Toast";
import { useState, useCallback } from "react";

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const resetCopyState = useCallback(() => {
    setIsCopied(false);
  }, []);

  const copyToClipboard = useCallback(
    async (text: string) => {
      if (isCopied) return;
      try {
        await navigator.clipboard.writeText(text);
        // TODO Add i18n here
        toast.success({ message: "Copied to clipboard!" });
        toast.onChange((e) => e.status === "removed" && resetCopyState());
        setIsCopied(true);
      } catch (err) {
        // TODO Add i18n here
        toast.error({ message: "Failed to copy the error" });
        setIsCopied(false);
      }
    },
    [isCopied, resetCopyState]
  );

  return { isCopied, setIsCopied, copyToClipboard, resetCopyState };
};

export default useCopyToClipboard;
