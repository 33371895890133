import { Icon } from "@/components";
import { Input } from "@edusynch/edusynch-ui";
import { IInputProps } from "@edusynch/edusynch-ui/dist/lib/components/Input/Input";
import { Divider, IconButton, Stack, useTheme } from "@mui/material";
import { ReactNode, useState } from "react";

type InputPasswordProps = {
  otherEndIcon?: ReactNode;
} & IInputProps;

export function InputPassword({ otherEndIcon, ...props }: InputPasswordProps) {
  const [showPassword, setShowPassword] = useState(false);

  const theme = useTheme();

  const handleShowPassword = () => setShowPassword((show) => !show);

  const inputIcon = showPassword ? (
    <Icon name="eye-not" width={16} height={16} />
  ) : (
    <Icon name="eye" width={16} height={16} />
  );

  return (
    <Input
      type={showPassword ? "text" : "password"}
      endIcon={
        otherEndIcon ? (
          <Stack direction="row" alignItems="center">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleShowPassword}
              edge="end"
            >
              {inputIcon}
            </IconButton>
            <Divider
              orientation="vertical"
              sx={{
                ml: 2,
                mr: 1.5,
                height: "1rem",
                borderColor: theme.customColors.gray[300],
              }}
            />
            {otherEndIcon}
          </Stack>
        ) : (
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleShowPassword}
            edge="end"
          >
            {inputIcon}
          </IconButton>
        )
      }
      data-testid="input-password"
      {...props}
    />
  );
}
