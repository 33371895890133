import { styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

interface ISubHeaderProps {
  justifyContent?: "space-between" | "flex-end";
}

export const SubHeader: ComponentType<
  HTMLAttributes<HTMLDivElement> & ISubHeaderProps
> = styled("div")<ISubHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || "space-between"};
`;
