import { useAuth } from "@/providers/AuthProvider";
import { formatDate, formatTime } from "@/utils";
import { Typography } from "@edusynch/edusynch-ui";
import { Stack } from "@mui/material";

interface IDateTimeProps {
  date: string;
}

export function DateTime({ date }: IDateTimeProps) {
  const { session } = useAuth();

  return (
    <Stack>
      <Typography color="text">
        {formatDate({ date, timezone: session?.timezone })}
      </Typography>
      <Typography variant="small" color="gray">
        {formatTime({ date, timezone: session?.timezone })}
      </Typography>
    </Stack>
  );
}
