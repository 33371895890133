import { ReactNode } from "react";
import {
  getUTCLast30Days,
  getUTCLast7Days,
  getUTCLastYear,
} from "@edusynch/edusynch-ui";

export type TOptionItem = {
  id: string | number;
  label: string;
  type: string;
  filterName: string;
  required: boolean;
  render?: ReactNode;
};

export type TFilterOption = {
  id: string | number;
  icon: string;
  type: string;
  items: TOptionItem[];
};

export type TDefaultFilters = {
  type: string;
  value: string | number | null;
  required: boolean;
};

export const DATE_FILTER = {
  LAST_7_DAYS: "last_7_days",
  LAST_30_DAYS: "last_30_days",
  LAST_YEAR: "last_year",
  CUSTOM: "custom",
};

const TRANSLATION_PREFIX = "dashboard.widgets.interval_options";

const datesOptions = (title: string, type: string, t: any) => ({
  icon: "calendar",
  type: t(`components.filters.${title}`),
  items: [
    {
      id: 1,
      label: t(`${TRANSLATION_PREFIX}.last_7_days`),
      filterName: "last_7_days",
      type,
    },
    {
      id: 2,
      label: t(`${TRANSLATION_PREFIX}.last_30_days`),
      filterName: "last_30_days",
      type,
    },
    {
      id: 3,
      label: t(`${TRANSLATION_PREFIX}.last_year`),
      filterName: "last_year",
      type,
    },
    {
      id: 4,
      label: t(`${TRANSLATION_PREFIX}.custom`),
      filterName: "custom",
      type,
    },
  ],
});

export const formatOptions = (...options: any[]) => {
  if (!options || !options?.length) return [];

  return options
    .filter((option) => !!option.items?.length)
    .map((option, index) => ({
      id: index + 1,
      icon: option.icon,
      type: option.type,
      items: option.items,
    }));
};

interface IFormatOptionsWithDatesProps {
  hasDate: boolean;
  hasEvaluateDate: boolean;
  hasScheduleDate: boolean;
  hasSubmissionDate: boolean;
  options: any[];
  t: any;
}

export const formatOptionsWithDates = ({
  hasDate,
  hasEvaluateDate,
  hasScheduleDate,
  hasSubmissionDate,
  options = [],
  t,
}: IFormatOptionsWithDatesProps) => {
  let optionsToMap = [...options];

  if (hasDate) {
    optionsToMap = [
      ...optionsToMap,
      datesOptions("date_interval", "Date Interval", t),
    ];
  }

  if (hasEvaluateDate) {
    optionsToMap = [
      ...optionsToMap,
      datesOptions("evaluated_date", "Evaluated Date", t),
    ];
  }

  if (hasScheduleDate) {
    optionsToMap = [
      ...optionsToMap,
      datesOptions("scheduled_date", "Scheduled Date", t),
    ];
  }

  if (hasSubmissionDate) {
    optionsToMap = [
      ...optionsToMap,
      datesOptions("submission_date", "Submission Date", t),
    ];
  }

  return optionsToMap.map((option, index) => ({
    id: index + 1,
    icon: option.icon,
    type: option.type,
    items: option.items,
  }));
};

export const formatCurrentFilters = (
  options: any[],
  currentFilters: any[] | undefined
) => {
  if (
    !options ||
    !options?.length ||
    !currentFilters ||
    !currentFilters?.length
  ) {
    return [];
  }

  const validFilters = currentFilters.filter((filter) => filter.value);

  if (!validFilters?.length) return [];

  return validFilters.map((filter, index) => {
    const optionItems = options.find((option) => option.type === filter.type);

    const currentFilter = optionItems?.items?.find(
      (item: any) => item.id === filter.value
    );

    return {
      id: index + 1,
      label: currentFilter?.name || currentFilter?.label,
      type: filter.type,
      value: filter.value,
      filterName: currentFilter?.filterName,
      required: currentFilter?.required,
    };
  });
};

export const getDatesObjectByFilterName = (filterName: string) => {
  switch (filterName) {
    case DATE_FILTER.LAST_7_DAYS:
      return getUTCLast7Days();
    case DATE_FILTER.LAST_30_DAYS:
      return getUTCLast30Days();
    case DATE_FILTER.LAST_YEAR:
      return getUTCLastYear();
    case DATE_FILTER.CUSTOM:
      return "custom";
    default:
      return null;
  }
};
