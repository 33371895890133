import { ACCOUNTS_LIST_URL } from "@/constants/api-routes";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "../useApiClient";
import { toast } from "@/components/common/Toast";

export type AccountType = "account" | "subaccount";

export type EnableAccount = {
  id: number;
  type: AccountType;
  active: boolean;
  account_id: number | null;
};

export type Account = {
  id: number;
  name: string;
  active: boolean;
  parent_account_id: number | null;
  root_account_id: number | null;
  sub_account?: Account[];
};

interface GetAccountsProps {
  page?: number;
  perPage?: number;
  search?: string;
  sortBy?: string;
  sort?: string;
}

type GetAccountsResponse = {
  accounts: Account[];
  total: number;
  isAllAccountsEnabled: boolean;
};

interface UseAccountsProps {
  page?: number;
  perPage?: number;
  search?: string;
  sortBy?: string;
  sort?: string;
  enabled?: boolean;
  onSuccess?: (data: GetAccountsResponse) => void;
}

export function useAccounts({
  page,
  perPage,
  search,
  sortBy,
  sort,
  enabled = true,
  onSuccess,
}: UseAccountsProps) {
  const { api } = useApiClient();

  const getAccounts = async ({
    page,
    perPage,
    search,
    sortBy,
    sort,
  }: GetAccountsProps): Promise<GetAccountsResponse> => {
    const response = await api.get(ACCOUNTS_LIST_URL, {
      params: {
        search: search || undefined,
        page: page || undefined,
        paginates_per: perPage || undefined,
        sort: sort || undefined,
        sort_by: sortBy || undefined,
      },
    });

    const accounts = response.data.content.accounts;

    return {
      accounts,
      total: response.data.content.total_items || accounts.length,
      isAllAccountsEnabled: !!response.data.content.all_accounts_enabled,
    };
  };

  return useQuery({
    queryKey: [
      "accounts",
      {
        page,
        perPage,
        search,
        sortBy,
        sort,
      },
    ],
    queryFn: () =>
      getAccounts({
        page,
        perPage,
        search,
        sortBy,
        sort,
      }),
    staleTime: 1000 * 60 * 1,
    enabled,
    onError: (error: any) => {
      toast.error({ message: error?.message });
    },
    onSuccess,
  });
}
