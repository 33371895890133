import { TSize } from "@edusynch/edusynch-ui";

export function getLoadingSize(size?: TSize) {
  switch (size) {
    case "tiny":
      return 8;
    case "extra-small":
      return 12;
    case "small":
      return 14;
    case "medium":
      return 24;
    case "large":
      return 32;
    default:
      return 16;
  }
}
