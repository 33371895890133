import {
  add,
  format,
  parseISO,
  addMonths,
  differenceInDays,
  isValid,
} from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

type TFormatDate = {
  date: number | string | Date;
  dateFormat?: string;
  timezone?: string;
};

type TTimezone = {
  timezone?: string;
};

const DATE_FORMAT = "yyyy/MM/dd";
const DATE_TIME_FORMAT = "yyyy/MM/dd HH:mm";
const TIME_FORMAT = "HH:mm";
const DATE_FILTER_FORMAT = "yyyy-MM-dd";

export const getDefaultStartDate = ({ timezone }: TTimezone) => {
  const last30Days = addMonths(new Date(), -1);

  if (timezone) {
    return formatInTimeZone(last30Days, timezone, DATE_FILTER_FORMAT);
  }

  return format(last30Days, DATE_FILTER_FORMAT);
};

export const getDefaultEndDate = ({ timezone }: TTimezone) => {
  const today = new Date();

  if (timezone) {
    return formatInTimeZone(today, timezone, DATE_FILTER_FORMAT);
  }

  return format(today, DATE_FILTER_FORMAT);
};

export const formatDate = ({ date, dateFormat, timezone }: TFormatDate) => {
  if (timezone) {
    return formatInTimeZone(date, timezone, dateFormat || DATE_FORMAT);
  }

  if (typeof date === "string") {
    if (!isValid(parseISO(date))) return "";
    return format(parseISO(date), dateFormat || DATE_FORMAT);
  }

  return format(date, dateFormat || DATE_FORMAT);
};

export const formatTime = ({ date, dateFormat, timezone }: TFormatDate) => {
  if (timezone) {
    return formatInTimeZone(date, timezone, dateFormat || TIME_FORMAT);
  }

  if (typeof date === "string") {
    if (!isValid(parseISO(date))) return "";
    return format(parseISO(date), dateFormat || TIME_FORMAT);
  }

  return format(date, dateFormat || TIME_FORMAT);
};

export const formatDateTime = ({ date, dateFormat, timezone }: TFormatDate) => {
  if (timezone) {
    return formatInTimeZone(date, timezone, dateFormat || TIME_FORMAT);
  }

  if (typeof date === "string") {
    if (!isValid(parseISO(date))) return "";
    return format(parseISO(date), dateFormat || DATE_TIME_FORMAT);
  }

  return format(date, dateFormat || DATE_TIME_FORMAT);
};

export const durationToHuman = (duration: number) => {
  const date = add(new Date(1970, 0, 1), { seconds: duration });
  return format(date, "HH:mm:ss");
};

export const getDifferenceInDays = (dateLeft: Date, dateRight: Date) => {
  return differenceInDays(dateLeft, dateRight);
};
