import { IconButton, tooltipClasses, useTheme } from "@mui/material";
import { Icon } from "../Icon";
import useCopyToClipboard from "@/hooks/useCopyToClipboard";
import { Tooltip } from "../Tooltip";
import { useTranslation } from "next-i18next";
import { TVariant } from "@edusynch/edusynch-ui";

interface CopyIconProps {
  text: string;
  icon: string;
  iconVariant?: TVariant;
  tooltipTitle?: string;
}

export function CopyIcon({
  text,
  icon,
  iconVariant = "primary",
  tooltipTitle,
}: CopyIconProps) {
  const { copyToClipboard } = useCopyToClipboard();
  const { t } = useTranslation("common");
  const theme = useTheme();

  return (
    <Tooltip
      arrow
      title={`${t("common_words.copy")} ${tooltipTitle || ""}`}
      sx={{
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.customColors.primary[500],
        },
        [`& .${tooltipClasses.tooltip}`]: {
          padding: "1rem",
          fontSize: theme.typography.pxToRem(14),
        },
      }}
    >
      <IconButton size="small" onClick={() => copyToClipboard(text)}>
        <Icon variant={iconVariant} name={icon} size="small" />
      </IconButton>
    </Tooltip>
  );
}
