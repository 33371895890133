import { Box, useTheme } from "@mui/material";
import { ReactNode } from "react";

export interface BadgeProps {
  label: ReactNode;
  secondary?: boolean;
}

export function Badge({ label, secondary }: BadgeProps) {
  const theme = useTheme();

  return (
    <Box
      width={20}
      height={20}
      textAlign="center"
      borderRadius="50%"
      color={theme.customColors.primary[500]}
      bgcolor={theme.customColors.primary[secondary ? 200 : 100]}
      flexShrink={0}
    >
      {label}
    </Box>
  );
}
