import {
  Button,
  convertUTCDateToUserTimezone,
  formatDate,
  Input,
  Modal,
  Typography,
} from "@edusynch/edusynch-ui";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import Image from "next/image";
import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { ConfirmTooltip } from "../ConfirmTooltip";
import {
  TDefaultFilters,
  TFilterOption,
  TOptionItem,
} from "../Filters/Filters.utils";
import { Icon } from "../Icon";

import * as S from "./ResponsiveFiltersModal.styles";

type CurrentDates = {
  start_date: string;
  end_date: string;
};

interface IResponsiveFiltersModalProps {
  isOpen: boolean;
  options: TFilterOption[];
  defaultFilters?: TDefaultFilters[];
  currentDates?: CurrentDates;
  timezone?: string;
  dateFormat?: string;
  timeFormat?: string;
  isDateTime?: boolean;
  defaultSearch: string;
  search: string;
  hasSelectedFilters?: boolean;
  setSearch: (term: string) => void;
  onFilterSelected: (filter: TOptionItem) => void;
  onClearFilter: (filter: TOptionItem) => void;
  onClearSearch: () => void;
  onClearAll: () => void;
  onSave: () => void;
  onClose: () => void;
}

export function ResponsiveFiltersModal({
  isOpen,
  options,
  defaultFilters,
  defaultSearch,
  currentDates,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  dateFormat = "MM/dd/yyyy",
  timeFormat = "24",
  isDateTime,
  search,
  setSearch,
  hasSelectedFilters,
  onFilterSelected,
  onClearFilter,
  onClearSearch,
  onClearAll,
  onSave,
  onClose,
}: IResponsiveFiltersModalProps) {
  const [searchOption, setSearchOption] = useState("");
  const [selectedOption, setSelectedOption] = useState<TFilterOption | null>(
    null
  );
  const theme = useTheme();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (!isOpen) {
      setSearchOption("");
      setSelectedOption(null);
    }
  }, [isOpen]);

  const handleSave = () => {
    onSave();
    onClose();
  };

  const handleClose = () => {
    if (selectedOption) {
      setSelectedOption(null);
    } else {
      onClose();
    }
  };

  const handleSelectFilter = (filter: TOptionItem) => {
    onFilterSelected(filter);
    setSelectedOption(null);
  };

  const getFilter = (option: TFilterOption): TOptionItem | undefined => {
    if (option.type === "Date Interval") {
      return {
        id: "",
        type: "Date",
        label: `${formatDate({
          date: convertUTCDateToUserTimezone({
            date: currentDates?.start_date,
            timezone,
          }),
          format: `${dateFormat} ${!isDateTime ? "" : timeFormat}`,
        })} to ${formatDate({
          date: convertUTCDateToUserTimezone({
            date: currentDates?.end_date,
            timezone,
          }),
          format: `${dateFormat} ${!isDateTime ? "" : timeFormat}`,
        })}`,
        filterName: "date",
        required: true,
      };
    }

    const selectedFilter = defaultFilters?.find(
      (defaultFilter) =>
        defaultFilter.value && defaultFilter.type === option.type
    );

    if (selectedFilter) {
      const optionItems =
        options.find((option) => option.type === selectedFilter.type)?.items ||
        [];
      return optionItems.find((item) => item.id === selectedFilter.value);
    }

    return undefined;
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeIcon={<Icon name="close" />}
      fullScreen
      sx={{
        ".MuiDialogContent-root.MuiDialogContent-root": {
          padding: 0,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        p={3}
        sx={{ borderBottom: `1px solid ${theme.customColors.gray[200]}` }}
        data-testid="responsive-filters-modal"
      >
        <Stack
          direction="row"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", left: "1rem" }}
          >
            <Icon
              name={selectedOption ? "chevron-left" : "close"}
              variant="gray"
              size="small"
            />
          </IconButton>
          <Typography
            color="text"
            style={{ fontWeight: "bold", lineHeight: "0.875rem" }}
          >
            {selectedOption ? selectedOption.type : "Filters"}
          </Typography>
          <Button
            onClick={handleSave}
            variant="text"
            style={{
              position: "absolute",
              padding: "2rem 1.5rem 2rem",
              right: 0,
              top: 0,
            }}
            data-testid="filters-save"
          >
            Save
          </Button>
        </Stack>
      </Stack>

      <Stack direction="column" mt={3} px={2}>
        {selectedOption ? (
          <Box
            pb={3}
            borderBottom={`1px solid ${theme.customColors.gray[200]}`}
          >
            <Input
              rounded
              size="small"
              fullWidth
              placeholder="Search"
              startIcon={<Icon name="search" width={16} height={16} />}
              value={searchOption}
              onChange={({ target }) => setSearchOption(target.value)}
            />
          </Box>
        ) : (
          <Box
            pb={3}
            borderBottom={`1px solid ${theme.customColors.gray[200]}`}
          >
            <Input
              rounded
              size="small"
              fullWidth
              placeholder="Search"
              startIcon={<Icon name="search" width={16} height={16} />}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
            {!!defaultSearch && (
              <S.SearchValue style={{ marginTop: "1rem" }}>
                {defaultSearch}
                <ConfirmTooltip
                  subtitle="This action will clear the filter."
                  onConfirm={() => {
                    onClearSearch();
                    onClose();
                  }}
                >
                  <Icon name="close" variant="white" />
                </ConfirmTooltip>
              </S.SearchValue>
            )}
          </Box>
        )}
        {selectedOption ? (
          selectedOption.items?.filter((option) =>
            option.label.toLowerCase().includes(searchOption.toLowerCase())
          ).length ? (
            <List disablePadding>
              {selectedOption.items
                ?.filter((option) =>
                  option.label
                    .toLowerCase()
                    .includes(searchOption.toLowerCase())
                )
                ?.map((item) => (
                  <ListItemButton
                    key={item.id}
                    sx={{
                      p: 2,
                      borderBottom: `1px solid ${theme.customColors.gray[200]}`,
                    }}
                    onClick={() => handleSelectFilter(item)}
                  >
                    <ListItemText
                      primary={
                        item.render ? (
                          item.render
                        ) : (
                          <Typography color="text">{item.label}</Typography>
                        )
                      }
                    />
                  </ListItemButton>
                ))}
            </List>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mt={3}
            >
              <Box mb={2}>
                <Image
                  src="/assets/svg/oops-icon.svg"
                  width={72}
                  height={72}
                  alt="Empty data icon"
                />
              </Box>
              <Typography
                style={{
                  marginBottom: "0.25rem",
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  fontWeight: "normal",
                  color: theme.customColors.gray[400],
                }}
              >
                <span style={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                  {t("filters.empty_data.title")}
                </span>{" "}
                {t("filters.empty_data.description")}
              </Typography>
            </Box>
          )
        ) : (
          <List disablePadding sx={{ maxHeight: 450, overflow: "auto" }}>
            {options?.map((option) => (
              <ListItem
                key={option.id}
                sx={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  borderBottom: `1px solid ${theme.customColors.gray[200]}`,
                }}
              >
                <ListItemButton
                  sx={{
                    py: "1rem",
                    px: 0,
                    width: "100%",
                  }}
                  onClick={() => setSelectedOption(option)}
                >
                  <ListItemIcon sx={{ minWidth: "2rem" }}>
                    <Icon
                      name={option.icon}
                      variant="primary"
                      width={16}
                      height={16}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography color="text">{option.type}</Typography>
                    }
                  />
                  <Icon name="arrow-right" />
                </ListItemButton>
                {!!getFilter(option) && (
                  <S.FilterValue style={{ marginBottom: "1rem" }}>
                    <span>{option.type}:</span> {getFilter(option)?.label}
                    {!getFilter(option)?.required && (
                      <ConfirmTooltip
                        subtitle="This action will clear the filter."
                        onConfirm={() =>
                          onClearFilter(getFilter(option) as TOptionItem)
                        }
                      >
                        <Icon name="close" size="tiny" variant="white" />
                      </ConfirmTooltip>
                    )}
                  </S.FilterValue>
                )}
              </ListItem>
            ))}
          </List>
        )}
        {!!hasSelectedFilters && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translate(-50%, -50%)",
              "button svg path": {
                fill: theme.customColors.gray[500],
              },
            }}
          >
            <Button
              onClick={onClearAll}
              variant="text"
              startIcon={<Icon name="delete" size="small" />}
              style={{
                color: theme.customColors.common.text,
              }}
              data-testid="filters-clear-all"
            >
              {t("filters.clear_all")}
            </Button>
          </Box>
        )}
      </Stack>
    </Modal>
  );
}
