import { useRouter } from "next/router";
import { useUserLocation } from "@/hooks/useUserLocation";

export function useQuizzesUtils(course_id?: number | null) {
  const router = useRouter();
  const { baseRoute } = useUserLocation();
  const currentPath = router.asPath;

  const pageChecks = {
    isSessionsPage: currentPath?.includes("sessions"),
    isSessionEventLogPage: currentPath?.includes("session-event-log"),
    isThirdPartyQuizzes: currentPath?.includes("third-party-quizzes"),
    isSessionReportPreview: currentPath?.includes("report-preview"),
    isEditQuizPage: currentPath?.includes("edit"),
    isQuizzesListPage: currentPath?.includes("quizzes"),
  };

  const quizzesRoute = `${baseRoute}/courses/${router.query.course_id}/quizzes/${router.query.quiz_type}`;
  const thirdPartyQuizzesRoute = `/courses/${router.query.course_id}/third-party-quizzes`;

  const quizzesListPageRoute = pageChecks.isThirdPartyQuizzes
    ? thirdPartyQuizzesRoute
    : baseRoute.includes("/admin")
    ? quizzesRoute
    : `${baseRoute}/courses/${course_id || router.query.course_id}/quizzes/${
        router.query.quiz_type
      }`;

  const routeByQuizzesType = pageChecks.isThirdPartyQuizzes
    ? thirdPartyQuizzesRoute
    : quizzesRoute;

  return {
    ...pageChecks,
    quizzesRoute,
    thirdPartyQuizzesRoute,
    routeByQuizzesType,
    quizzesListPageRoute,
  };
}
