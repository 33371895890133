import { useState } from "react";
import { Icon } from "@/components/common";
import { useMaintenance } from "@/hooks/maintenance/useMaintenance";
import { Box, Stack } from "@mui/material";
import { IS_MAINTENANCE } from "@/utils/maintenance";
import { componentStyles } from "./MaintenanceTopBar.styles";
import { MaintenanceWarningIcon } from "@/components/common/Icons";

export function MaintenanceTopBar() {
  const [closedByUser, setClosedByUser] = useState(false);

  const { data } = useMaintenance({
    enabled: !IS_MAINTENANCE && !closedByUser,
  });

  const shouldHideTopBar =
    IS_MAINTENANCE ||
    !data?.scheduledMaintenance ||
    !data?.maintenanceDescription ||
    closedByUser;

  if (shouldHideTopBar) return null;

  return (
    <Stack sx={componentStyles.container}>
      <Box sx={componentStyles.content}>
        <Box sx={componentStyles.message}>
          <MaintenanceWarningIcon />
          <span
            dangerouslySetInnerHTML={{
              __html: data?.maintenanceDescription,
            }}
          />
        </Box>
        <Box sx={componentStyles.close} onClick={() => setClosedByUser(true)}>
          <Icon name="close" size="small" />
        </Box>
      </Box>
    </Stack>
  );
}
