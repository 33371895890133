"use client";

import Image from "next/image";
import { Button, Card, Typography } from "@edusynch/edusynch-ui";

import { Icon } from "@/components/common";
import { MOBILE_SIZE, useMediaQuery, useQuizzesUtils } from "@/hooks";

import * as S from "./ThirdPartyHeader.styles";
import { Stack, useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

type ThirdPartyHeaderType = {
  isStudent?: boolean;
};

function ThirdPartyHeader({ isStudent }: ThirdPartyHeaderType) {
  const isMobile = useMediaQuery(MOBILE_SIZE);
  const theme = useTheme();
  const { t } = useTranslation("common");
  const router = useRouter();
  const { quizzesRoute } = useQuizzesUtils();

  const isRegisterPage =
    router.asPath.includes("create") || router.asPath.includes("edit");

  return (
    <S.Container>
      <Card
        padding={isMobile ? "1rem" : "1.5rem"}
        borderRadius="0.5rem"
        variant="primary"
      >
        <S.Header>
          <Image
            src="/logo-eproctoring.svg"
            width={130.56}
            height={32}
            alt="logo eproctoring"
          />
          <Stack
            width="100%"
            spacing={{
              xs: 1,
              md: 0,
            }}
            direction={{
              xs: "column",
              sm: "row",
            }}
            alignItems="center"
            justifyContent="space-between"
            paddingLeft={{
              xs: 0,
              md: 3,
            }}
            borderLeft={{
              xs: "none",
              md: `1px solid ${theme.customColors.primary[200]}`,
            }}
          >
            <Typography
              variant="h4"
              color="secondary"
              style={{
                fontSize: "1rem",
                color: theme.customColors.secondary[300],
              }}
            >
              {t(
                `third_party_quizzes.header.${
                  isRegisterPage ? "register_third_party_quiz" : "title"
                }`
              )}
            </Typography>
            {!isStudent && (
              <>
                {isRegisterPage ? (
                  <Button
                    variant="outlined"
                    style={{ padding: "1rem" }}
                    fullWidth={isMobile}
                    onClick={() => router.push(quizzesRoute)}
                  >
                    <Icon name="chevron-left" variant="white" />
                    {t("third_party_quizzes.header.back_to_exams")}
                  </Button>
                ) : (
                  <Button
                    style={{ padding: "1rem" }}
                    fullWidth={isMobile}
                    onClick={() => router.push(`${quizzesRoute}/create`)}
                  >
                    <Icon name="plus" variant="white" />
                    {t("third_party_quizzes.header.register_third_party_quiz")}
                  </Button>
                )}
              </>
            )}
          </Stack>
        </S.Header>
      </Card>
    </S.Container>
  );
}

export default ThirdPartyHeader;
