import { MOBILE_SIZE } from "@/hooks";
import { styled } from "@mui/material";
import { ComponentType, HTMLAttributes } from "react";

export const Container: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  background-color: ${(props) => props.theme.customColors.primary[100]};
  padding: 1rem;
  border-radius: 0.375rem;
`;

export const Separator: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  height: 0.0625rem;
  background-color: ${(props) => props.theme.customColors.gray[300]};
  margin: 1rem 0;
`;

export const Label: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "span"
)`
  ${(props) => props.theme.customTypography.small};
  color: ${(props) => props.theme.customColors.gray[400]};
  padding-right: 1rem;
  line-height: unset;
`;

export const SearchValue: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("span")`
  display: inline-flex;
  align-items: center;
  ${(props) => props.theme.customTypography.small};
  line-height: unset;
  background-color: ${(props) => props.theme.customColors.primary[400]};
  color: ${(props) => props.theme.customColors.common.white};
  border-radius: 0.25rem;
  padding: 0.5rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.customColors.primary[500]};
  }

  svg:not(.edu-icon-exclamation) {
    width: 0.5rem;
    height: 0.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }
`;

export const Filters: ComponentType<HTMLAttributes<HTMLDivElement>> = styled(
  "div"
)`
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem 0.25rem;
`;

export const FilterValue: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("span")`
  display: inline-flex;
  align-items: center;
  ${(props) => props.theme.customTypography.small};
  line-height: unset;
  background-color: ${(props) => props.theme.customColors.secondary[200]};
  color: ${(props) => props.theme.customColors.common.white};
  border-radius: 0.25rem;
  padding: 0.5rem;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.customColors.secondary[300]};
  }

  span {
    font-weight: bold;
    padding-right: 0.25rem;
  }

  svg:not(.edu-icon-exclamation) {
    width: 0.5rem;
    height: 0.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }
`;

export const FilterValueContent: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  position: relative;
`;

export const SelectedFilters: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 2.5rem;
`;

export const SearchContainer: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")``;
export const FiltersContainer: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")``;

export const FilterButtonContainer: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  position: relative;
  width: 5.25rem;
`;

export const RangeDatePickerContainer: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  width: 4.6875rem;
  background-color: ${(props) => props.theme.customColors.common.white};
  border-radius: 0.375rem;
  padding: 1rem 2rem;
  margin: 0 auto;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  right: 0;
  top: 3rem;
  z-index: 10;

  @media ${MOBILE_SIZE} {
    width: 96vw;
    transform: translateX(0);
    left: -1.5rem;
    padding: 1rem 0.5rem;
  }
`;

export const SkeletonContainer: ComponentType<
  HTMLAttributes<HTMLDivElement>
> = styled("div")`
  display: inline-flex;

  .skeleton-element {
    background: ${(props) => props.theme.customColors.primary[200]};
  }
`;
